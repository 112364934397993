import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import * as config from "../../utils/apiVersion";
import { startLoading, stopLoading } from "../feature";
import { errorToastSelector, successToastSelector, toastSelector } from "../selector";
import { buildQueryString } from "../../helpers";
import { getMembersData, getUser } from "./user";
import { fetchLogout } from "./logout";

export const getBalance = createAsyncThunk(
  "commonServices/balances",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      if(!payload?.limited){
        dispatch(startLoading());
      }
      let url;
      if(payload?.limited){
        url = `account/balances?limited=true`;
      }else{
        url = `account/balances`;
      }
      let res = await API.get(config.peatio)(url);
      dispatch(getMembersData());
      dispatch(stopLoading());
      return { list: res, status: true, limited: payload?.limited };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getCurrencies = createAsyncThunk(
  "commonServices/getCurrencies",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      if (!payload?.limited) {
        dispatch(startLoading());
      }
      let url = ``;
      if (payload?.id) {
        url = `public/currencies/${payload.id}`;
      } else if (payload?.type) {
        url = `public/currencies?type=${payload?.type}`;
      } else if(payload?.graph_req){
        url = `public/currencies?graph_req=${payload?.graph_req}`;
      } else if(payload?.limited){
        url = `public/currencies?limited=true`;
      } else {
        url = `public/currencies`;
      }
      let res = await API.get(config.peatio)(url);
      dispatch(stopLoading());
      return { list: res, status: true, graph_req: payload?.graph_req, limited: payload?.limited};
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getAnnouncementBanner = createAsyncThunk(
  "commonServices/getAnnouncementBanner",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `public/announcement`; 
      let res = await API.get(config.peatio)(url);
      dispatch(stopLoading());
      return { list: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getCurrencyDetails = createAsyncThunk(
  "commonServices/getCurrencyDetails",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = "";
      if (payload?.id) {
        url = `home/overview/${payload.id}`;
      } else {
        url = `home/list`;
      }
      let res = await API.get(config.peatio)(url);
      dispatch(stopLoading());
      return { status: true, list: res };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getGraphData = createAsyncThunk(
  "commonServices/getGraphData",
  async ({ id, ...payload }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `home/overview/graph/${id}?${buildQueryString(payload)}`;
      let res = await API.get(config.peatio)(url);
      dispatch(stopLoading());
      return { status: true, list: res };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getGainersLosers = createAsyncThunk(
  "commonServices/getGainersLosers",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `home/list/movers`;
      let res = await API.get(config.peatio)(url);
      dispatch(stopLoading());
      return { status: true, data: res };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getSingleCurrencyDetails = createAsyncThunk(
  "commonServices/getSingleCurrencyDetails",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      let id = payload.id;
      delete payload.id;
      dispatch(startLoading());
      let url = `home/overview/${id}?${buildQueryString(payload)}`;
      let res = await API.get(config.peatio)(url);
      dispatch(stopLoading());
      return res;
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getBalanceHistory = createAsyncThunk(
  "commonServices/getBalanceHistory",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/account/BalanceHistory?${buildQueryString(payload)}`;
      let res = await API.get(config.peatioWithHeader)(url);
      dispatch(stopLoading());
      return {
        data: res.data,
        total: res.headers.total,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const generateQrCode = createAsyncThunk(
  "commonServices/generateQrCode",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `resource/otp/generate_qrcode`;
      let res = await API.post(config.barong)(url);
      dispatch(stopLoading());
      return { qrCode: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const enableTwoFactorAuth = createAsyncThunk(
  "commonServices/enableTwoFactorAuth",
  async (payload, { rejectWithValue, dispatch }) => {
    const { t } = payload;
    delete payload.t; 
    try {
      dispatch(startLoading());
      let url = `resource/otp/enable`;
      let res = await API.post(config.barong)(url, payload); 
     await dispatch(fetchLogout());
     await dispatch(getUser());
      dispatch(stopLoading());
      dispatch(successToastSelector({ message: t("services.2fa_enable") }));
      return { code: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const disableTwoFactorAuth = createAsyncThunk(
  "commonServices/disableTwoFactorAuth",
  async (payload, { rejectWithValue, dispatch }) => {
    const { t } = payload;
    delete payload.t;
    try {
      dispatch(startLoading());
      let url = `resource/otp/disable`;
      let res = await API.post(config.barong)(url, payload);
      dispatch(getUser());
      dispatch(stopLoading());
      dispatch(successToastSelector({ message: t("services.2fa_disable") }));
      return { code: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const getPortfolioPrice = createAsyncThunk(
  "commonServices/getPortfolioPrice",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/account/portfolio_price`;
      let res = await API.get(config.peatio)(url);
      dispatch(stopLoading());
      return res;
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getUserSettings = createAsyncThunk(
  "commonServices/getUserSettings",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/account/${payload.uid}/settings`;
      let res = await API.get(config.copy)(url);
      dispatch(stopLoading());
      return res;
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getUserSettingsPetio = createAsyncThunk(
  "commonServices/getUserSettingsPetio",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/account/${payload.uid}/settings`;
      let res = await API.get(config.peatio)(url);
      dispatch(stopLoading());
      return res;
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const hideZeroSpot = createAsyncThunk(
  "commonServices/hideZeroSpot",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `account/hide_zero`;
      let res = await API.post(config.peatio)(url, payload);
      dispatch(stopLoading());
      return { status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);
export const initContact = createAsyncThunk(
  "commonServices/initContact",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      // dispatch(startLoading());
      // let url = `/identity/inquiry`;
      let url = `/resource/inquiry`;
      await API.post(config.barong)(url, payload);
      // dispatch(stopLoading());
      dispatch(
        successToastSelector({
          message: ['contact.success_contact'],
        })
      );
      return { status: true };
    } catch (e) {
      // dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const deleteAccount = createAsyncThunk(
  "commonServices/deleteAccount",
  async (payload, { rejectWithValue, dispatch }) => {
    const t = payload.t
    delete payload.t
    try {
      dispatch(startLoading());
      let url = `identity/users`;
      let res = await API.delete(config.barong)(url, payload);
      dispatch(successToastSelector({ message: t("services.account_deleted") }));

      dispatch(stopLoading());
      return { status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getHideZero = createAsyncThunk(
  "commonServices/getHideZero",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `account/hide_zero`;
      let res = await API.get(config.peatio)(url, payload);
      dispatch(stopLoading());
      return { data: res };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getGridBalance = createAsyncThunk(
  "commonServices/getGridBalance",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `account/balances`;
      let res = await API.get(config.grid)(url);
      dispatch(stopLoading());
      return { list: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }

);

export const getCopyBalance = createAsyncThunk(
  "commonServices/getCopyBalance",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `account/balances`;
      let res = await API.get(config.copy)(url);
      dispatch(stopLoading());
      return { list: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getTotalUsdtBal = createAsyncThunk(
  "commonServices/getTotalUsdtBal",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `account/total_balance`;
      let res = await API.get(config.peatio)(url);
      dispatch(stopLoading());
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getCurrenciesTicker = createAsyncThunk(
  "commonServices/getCurrenciesTicker",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `market/currency_prices`;
      let res = await API.get(config.peatio)(url);
      dispatch(stopLoading());
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const deactivateAccount = createAsyncThunk(
  "commonServices/deactivateAccount",
  async (payload, { rejectWithValue, dispatch }) => {
    const t = payload.t
    delete payload.t
    try {
      dispatch(startLoading());
      let url = `identity/users/deactivate`;
      let res = await API.post(config.barong)(url, payload);
      dispatch(successToastSelector({ message: t("services.account_disable") }));

      dispatch(stopLoading());
      return { status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getSingleCurrBalance = createAsyncThunk(
  "commonServices/getSingleCurrBalance",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      let url = `account/balances/${payload?.currency}`;
      let res = await API.get(config.peatio)(url);
      return { list: res, status: true };
    } catch (e) {
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getGridCurrBalance = createAsyncThunk(
  "commonServices/getGridCurrBalance",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `account/balances/${payload?.currency}`;
      let res = await API.get(config.grid)(url);
      dispatch(stopLoading());
      return { list: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getCopyCurrBalance = createAsyncThunk(
  "commonServices/getCopyCurrBalance",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `account/balances/${payload?.currency}`;
      let res = await API.get(config.copy)(url);
      dispatch(stopLoading());
      return { list: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getSelectedFiatCurr = createAsyncThunk(
  "commonApiData/getSelectedFiatCurr",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      let url = `/market/filter_currency`;
      const res = await API.get(config.p2p)(url);
      return res;
    } catch (e) {
      dispatch(toastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getAvailableSwapPairs = createAsyncThunk(
  "commonApiData/getAvailableSwapPairs",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      let url = `account/available_swap_pairs?${buildQueryString(payload)}`;
      const res = await API.get(config.peatio)(url);
      return res;
    } catch (e) {
      dispatch(toastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);
export const getSwapSetting = createAsyncThunk(
  "transHistories/getSwapSetting",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      let url = `account/trading_rules?${buildQueryString(payload)}`;
      let res = await API.get(config.peatio)(url);
      return { data: res, status: true };
    } catch (e) {
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getPublicSetting = createAsyncThunk(
  "transHistories/getPublicSetting",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      let url = `public/settings`;
      let res = await API.get(config.peatio)(url);
      return { data: res, status: true };
    } catch (e) {
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);


export const getFeeRebateSetting = createAsyncThunk(
  "commonServices/getFeeRebateSetting",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      let url = `account/fee_rebate_settings`;
      let res = await API.get(config.peatio)(url);
      return { data: res, status: true };
    } catch (e) {
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);


export const updateFeeRebate = createAsyncThunk(
  "commonServices/updateFeeRebate",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `account/fee_rebate_settings `;
      let res = await API.post(config.peatio)(url, payload);
      dispatch(stopLoading());
      dispatch(successToastSelector({ message: "Fee rebate updated successfully" }));
      return { status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);


export const getUserRebertData = createAsyncThunk(
  "commonServices/getUserRebertData",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      let url = `account/member_fee_rebate_setting`;
      let res = await API.get(config.peatio)(url);
      return { data: res, status: true };
    } catch (e) {
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);


export const deleteUserRebateData = createAsyncThunk(
  "commonServices/deleteUserRebateData",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      let url = `account/fee_rebate_settings`;
      let res = await API.delete(config.peatio)(url);
      dispatch(successToastSelector({ message: "Fee rebate updated successfully" }));
      return {  status: true };
    } catch (e) {
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);


export const getPnlTimePeriod = createAsyncThunk(
  "commonServices/getPnlTimePeriod",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      let url = `account/pnl?${buildQueryString(payload)}`;
      let res = await API.get(config.peatio)(url);
      return { data: res, status: true };
    } catch (e) {
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const fetchPublicSubscriber = createAsyncThunk(
  "commonServices/fetchPublicSubscriber",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `public/subscriber`;
      let res = await API.post(config.barong)(url, payload);
      dispatch(stopLoading());
      return { qrCode: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);