import { createSlice } from "@reduxjs/toolkit";
import {
  fetchAllBeneficiary,
  fetchBeneficiary,
  fetchIsInternalAddress,
  genrateAddress,
  getAddress,
} from "../../services";

export const sendReceiveSlice = createSlice({
  name: "sendReceiveSlice",
  initialState: {
    error: false,
    loading: false,
    qrCodeAddress: {},
    beneficiaryList: [],
    allBeneficiaryList: [],
    beneficiaryTotal: 0,
    isItInternalAddress: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAddress.pending, (state) => {
        state.qrCodeAddress = {};
        state.loading = true
      })
      .addCase(getAddress.fulfilled, (state, action) => {
        state.qrCodeAddress = action.payload.data;
        state.loading = false

      })
      .addCase(getAddress.rejected, (state) => {
        state.qrCodeAddress = {};
        state.loading = false

      })

      .addCase(genrateAddress.pending, (state) => {
        state.qrCodeAddress = "";
      })
      .addCase(genrateAddress.fulfilled, (state, action) => {
        state.qrCodeAddress = action.payload.data;

      })
      .addCase(genrateAddress.rejected, (state) => {
        state.qrCodeAddress = "";

      })
      .addCase(fetchIsInternalAddress.pending, (state) => { })
      .addCase(fetchIsInternalAddress.fulfilled, (state, action) => {
        state.isItInternalAddress = action?.payload?.data;
      })
      .addCase(fetchIsInternalAddress.rejected, (state) => { })
      .addCase(fetchBeneficiary.pending, (state) => {})
      .addCase(fetchBeneficiary.fulfilled, (state, action) => {
        state.beneficiaryList = action.payload.data;
      })
      .addCase(fetchBeneficiary.rejected, (state) => {})

      .addCase(fetchAllBeneficiary.pending, (state) => {})
      .addCase(fetchAllBeneficiary.fulfilled, (state, action) => {
        state.allBeneficiaryList = action.payload.data;
        state.beneficiaryTotal = action.payload.total;
      })
      .addCase(fetchAllBeneficiary.rejected, (state) => {});
  },
});
export default sendReceiveSlice.reducer;
export const {} = sendReceiveSlice.actions;
