/* eslint-disable */
import * as yup from "yup";
import { changePasswordFields } from "../staticObjects";

export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const passwordRegex =
  // /^(?!.*[<>])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
  /^(?!.*[<>])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~!@#$%^&*()_\-+=\{\}\[\]\\|:;,.?\/<>\\])(?=.{8,})/

export const password = (isNew, t) =>
  yup
    .string()
    .required(isNew ? t("schemas.utils.enter_new") : t("schemas.utils.pass_req"))
    .matches(
      passwordRegex,
      t("schemas.utils.match")
    );

export const passwordForLogin = (t) =>
  yup
    .string()
    .required(t("schemas.utils.pass_req"));

export const captcha = (t) => yup.string().required(t("schemas.utils.captcha"));

export const email = (t) => yup
  .string()
  .transform((value, originalValue) => (typeof originalValue === 'string' ? originalValue.trim() : originalValue))
  .matches(emailRegex, t("schemas.utils.valid_email"))
  .required(t("schemas.utils.email_req"));

export const otp = (t) => yup
  .string()
  .required(t("schemas.utils.otp"))
  .matches(/^[0-9]+$/, t("schemas.utils.must_digits"))
  .max(5, t("schemas.utils.max_otp"));

export const otpCode = (t) => yup
  .string()
  .required(t("schemas.utils.code"))
  .matches(/^[0-9]+$/, t("schemas.utils.must_digits"))
  .max(6, t("schemas.utils.max6"));