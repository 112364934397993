import React from "react";
import OnboardBanner from "../Assets/Images/OnboardBanner.png";
import BannerThemeLight from "../Assets/Images/BannerThemeLight.png";
import useCurrentWidth from "../../customHooks/useCurrentWidth";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import RingDarkVideo from "../Assets/RingDarkVideo.mp4";
import RingLightVideo from "../Assets/RingLightVideo.mp4";
import { StoreImages } from "../Storeimgaes/StoreImages";
import { useTranslation } from "react-i18next";

function CommonBannerLayout({ children }) {
  const width = useCurrentWidth();
  const { themeMode } = useSelector((state) => state.theme);
  const location = useLocation().pathname;
  const { OnboardLogo, OnboardLogoLight } = StoreImages;
  const { t } = useTranslation();

  return (
    <div
      className={`${location !== "/" &&
        location !== "/privacy" &&
        location !== "/term-condition" &&
        location !== "/contact-us" &&
        width > 991
        ? "bannerStyle"
        : ""
        }`}
    >
      {location !== "/" &&
        location !== "/privacy" &&
        location !== "/term-condition" &&
        location !== "/contact-us" &&
        width > 991 && (
          <div className="bannerStyle_banner">
            <div className="bannerStyle_banner_videos">
              {themeMode === "theme-light" ? (
                <video width="640" controls={false} autoPlay loop>
                  <source src={RingLightVideo} type="video/mp4" />
                </video>
              ) : (
                <video width="640" controls={false} autoPlay loop>
                  <source src={RingDarkVideo} type="video/mp4" />
                </video>
              )}
            </div>
            {/* {themeMode === "theme-light" ? (
              <img src={BannerThemeLight} alt="Banner" />
            ) : (
              <img src={OnboardBanner} alt="Banner" />
            )} */}
            <div className="bannerVideoText">
              {themeMode === "theme-light" ? (
                <img
                  src={OnboardLogoLight}
                  alt="img"
                  className="curserPointer cc"
                />
              ) : (
                <img src={OnboardLogo} alt="img" className="curserPointer cc" />
              )}
              <h2 className="headingCommon">{t("bannerTexts.heading_text")}</h2>
              <p className={"subTextBanner"}>{t("bannerTexts.sub_text")}</p>
            </div>
          </div>
        )}
      {children}
    </div>
  );
}

export default CommonBannerLayout;
