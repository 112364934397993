export const rootName = '/'
export const p2pRootName = '/p2p'
export const fixedCurr = "shib"
export const baseUrl = process.env.REACT_APP_BASE_URL
  
export const API_HOST_BASE_URL= process.env.REACT_APP_BASE_URL;

export const APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

export const TRANSAKSETTING_API_KEY = process.env.REACT_APP_TRANSAKSETTING;

export const CAPTCHA_SITE_KEY = process.env.REACT_APP_CAPTCHA;

export const CLOUDCAPTCHA_SITE_KEY = process.env.REACT_APP_CLOUDCAPTCHA

export const ALIASURL = process.env.REACT_APP_ALIASURL

export const JUMIOURL = process.env.REACT_APP_JUMIOURL

export const REFID = process.env.REACT_APP_REFID

export const REACT_APP_WSS_BASE_URL = process.env.REACT_APP_WSS_BASE_URL