import { createSlice } from "@reduxjs/toolkit";
import { fetchMarketGainerLooser, fetchMarketGasPrice, fetchMarketGreedData, fetchMarketListData, fetchNewlyListedData } from "../../services/market";

export const marketSlice = createSlice({
  name: "market",
  initialState: {
    error: false,
    marketListTotal: 0,
    marketGainerLooser: [],
    marketListData : [],
    newlyListedData : [],
    marketGreedData: [],
    marketGasPrice: {}
  },
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMarketGainerLooser.pending, (state) => {
        state.error = false;
      })
      .addCase(fetchMarketGainerLooser.fulfilled, (state, action) => {
        state.marketGainerLooser = action.payload.list;
      })
      .addCase(fetchMarketGainerLooser.rejected, (state) => {
        state.error = true;
      })

      .addCase(fetchMarketListData.pending, (state) => {
        state.error = false;
      })
      .addCase(fetchMarketListData.fulfilled, (state, action) => {
        state.marketListData = action.payload?.list;
        state.marketListTotal = action.payload?.total;
      })
      .addCase(fetchMarketListData.rejected, (state) => {
        state.error = true;
      })
      .addCase(fetchNewlyListedData.pending, (state) => {
        state.error = false;
      })
      .addCase(fetchNewlyListedData.fulfilled, (state, action) => {
        state.newlyListedData = action.payload?.list;
      })
      .addCase(fetchNewlyListedData.rejected, (state) => {
        state.error = true;
      })

      .addCase(fetchMarketGreedData.pending, (state) => {
        state.error = false;
      })
      .addCase(fetchMarketGreedData.fulfilled, (state, action) => {
        state.marketGreedData = action.payload?.list;
      })
      .addCase(fetchMarketGreedData.rejected, (state) => {
        state.error = true;
      })

      .addCase(fetchMarketGasPrice.pending, (state) => {
        state.error = false;
      })
      .addCase(fetchMarketGasPrice.fulfilled, (state, action) => {
        state.marketGasPrice = action.payload?.list;
      })
      .addCase(fetchMarketGasPrice.rejected, (state) => {
        state.error = true;
      });
      
  },
});
export default marketSlice.reducer;
export const {} = marketSlice.actions;
