export const preciseData = (data, precision = 2) => {
  return data ? Number(data).toFixed(precision) : data;
};

export const percentWidth = (maxVal, val, pre) => { 
  return Number((val * 100.0) / maxVal).toFixed(pre);
};

export const maxData = (data) => {
  let tot_arr = data.map(bigData);
  
  return Math.max(...tot_arr);
};


function bigData(data){
  return parseFloat(Number( parseFloat(data[1])).toFixed(8));
};
export const convertToThousandDigit = (num) => {
  const formatted = new Intl.NumberFormat("en", {
    maximumFractionDigits: 20,
  }).format(num);
  return formatted;
};

export const convertToThousandDigit2 = (num, precision = 2) => {
  if (num === null || num === undefined || isNaN(num)) return "-"; // Handle invalid values
  const fixedNum = Number(num).toFixed(precision); // Ensure correct decimal places
  return new Intl.NumberFormat("en-US", { minimumFractionDigits: precision }).format(fixedNum);
};

