import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Tabs, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { StoreImages } from "../../Storeimgaes/StoreImages";
import Points from "./Points";
import { getCurrencies, getCurrencyDetails, getGraphData, getMarketTickers, getSingleMarketTickers } from "../../../redux/services";
import PrimaryBalanceTab from "./PrimaryBalanceTab";
import { formatNumber } from "../../../helpers/formatNumber";
import style from "./style.module.scss";
import {
  coinDetailTabs,
  graphDurationTimeObj,
  upDownPriceColor,
} from "../../../staticObjects";
import TradeLineGraph from "../../Common/TradeLineGraph";
import { truncateData, truncateData2 } from "../../../helpers/truncate.js";
import i18next from "i18next";
import { graphTimeFrame } from "../../../redux/feature/index.js";
import { uppercase } from "../../../helpers/uppercase.js";
import { convertToThousandDigit, preciseData } from "../../../helpers/preciseData.js";

const CoinDetail = () => {
  const gutter = {
    xxl: 20,
    lg: 10,
    md: 10,
  };
  const { TabPane } = Tabs;
  const { t } = useTranslation();
  const { doc, web } = StoreImages;
  const dispatch = useDispatch();
  const { id } = useParams();
  const currDetail = useSelector(
    (state) => state.commonApiData?.currencyDetails
  );
  const { currencyGraphData, currenciesList, tradingType } = useSelector(
    (state) => state.commonApiData
  );
  const { singleMarketTickers, marketTickers } = useSelector(
    (state) => state?.exchange
  );
  const { overview, primaryBalance } = coinDetailTabs;
  const [activeTab, setActiveTab] = useState(overview);
  const [time, setTime] = useState(graphDurationTimeObj[0].value);
  const { timeFrame } = useSelector((state) => state.commonApiData);
  let tickerData = singleMarketTickers?.ticker;
  useEffect(() => {
    if (id) {
      // dispatch(getMarketTickers({ tradeType: tradingType }));
      dispatch(getCurrencies());
      // dispatch(getCurrencyDetails({ id }));
      dispatch(getSingleMarketTickers({ currency_id: id }));
      graphApi(time);
    }
    return () => {
      dispatch(graphTimeFrame("hr"));
    };
  }, []);

  const graphApi = (time) => {
    setTime(time);
    dispatch(getGraphData({ id: id, time: time }));
    // dispatch(graphTimeFrame(time));
  };
  const getMarketDataList =
    currenciesList?.length > 0
      ? currenciesList?.filter((item) => item.market_data)
      : [];

  const getMarketDataList2 =
    getMarketDataList?.length > 0
      ? getMarketDataList.filter((item) => item.id === id)
      : [];
  const getQuoteCurrPrice = (quoteUnit, currencies, price) => {
    let res =
      currencies.length > 0 &&
      currencies
        .filter((data) => data.id === quoteUnit)
        .map((items) => {
          return {
            price: items?.price,
          };
        });
    return res ? Number(price * res[0]?.price) : 0;
  };

  const marketDataKey = getMarketDataList2[0]?.market_data;
  const marketCap = marketTickers[marketDataKey]?.total_volume
    ? marketTickers[marketDataKey]?.total_volume
    : marketTickers[marketDataKey]?.ticker?.total_volume;
  const twenty24Hour = marketTickers[marketDataKey]?.price_change_percent
    ? marketTickers[marketDataKey]?.price_change_percent
    : marketTickers[marketDataKey]?.ticker?.price_change_percent;
  const twenty24HourHigh = marketTickers[marketDataKey]?.high
    ? marketTickers[marketDataKey]?.high
    : marketTickers[marketDataKey]?.ticker?.high;
  const price = marketTickers[marketDataKey]?.last
    ? marketTickers[marketDataKey]?.last
    : marketTickers[marketDataKey]?.ticker?.last;
  const quoteCurrPrice = getQuoteCurrPrice(
    getMarketDataList2[0]?.market_quote_unit,
    currenciesList,
    price
  ); 

  // if (currDetail?.length === 0 || Object.keys(currDetail).length === 0) return;

  const {
    market_cap,
    volume_24h,
    volume_change_24h,
    circulating_supply,
    max_supply,
    cmc_rank,
  } = currDetail;

  // const getkey = (time) => {
  //   let res = graphDurationTimeObj.find((item, idx) => item.value === time).key;
  //   return res ?? "";
  // };
  // const percentVal = currDetail[getkey(time)];
  const { up, down } = upDownPriceColor;
  let crncy =
    currenciesList.length > 0 && currenciesList.find((data) => data.id === id);
  let market_qute =
    currenciesList.length > 0 &&
    currenciesList.find((data) => data?.id === crncy?.market_quote_unit);

  let currPrice =
    crncy.market_quote_unit === "usdt"
      ? tickerData?.last
      : tickerData?.last * market_qute?.price;

  const {
    icon_url,
    name,
    symbol,
    description,
    whitepaper,
    official_link,
    color,
  } = crncy; 

  return (
    <>
      <div
        className={`blueesback ${style.cointdetailPage}
        `}
      >
        <div className={style.cointdetailPage_div}>
          {/* <Row gutter={[40, 40]}> */}
          {/* <Col xs={24} sm={24} md={24} xl={16}> */}
          <div className="overlapTabs">
            <Tabs
              className="line-none blueTabs"
              defaultActiveKey={activeTab}
              onChange={(key) => setActiveTab(key)}
            >
              <TabPane
                tab={t("coinDetail.overview")}
                key={overview}
              // className={style.paddingLeft}
              >
                {activeTab === overview && (
                  <>
                    <div className={style.cointdetailPage_tabs}>
                      <div className={style.cointdetailPage_headBar}>
                        <h3
                          className={`text-black-36 ${style.heading} ${icon_url ? "hasIcon" : "NoIcon"
                            }`}
                        >
                          {icon_url ? (
                            <>
                              <img
                                className="coin_icon arbicIcon"
                                src={icon_url}
                                alt="icon"
                              />
                              <span>{name ? uppercase(name) : ""}</span>
                              {/* <span>{symbol}</span> */}
                            </>
                          ) : (
                            <>
                              <div
                                className="coin_icon_placeholder"
                                style={{
                                  backgroundColor: color || "#ccc",
                                  borderRadius: "50%",
                                  width: "44px",
                                  height: "44px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <span
                                  style={{
                                    color: "#fff", // Text color (adjust as needed)
                                    fontWeight: "bold",
                                  }}
                                >
                                  {name?.charAt(0).toUpperCase()}
                                </span>
                              </div>
                              <span>{name ? uppercase(name) : ""}</span>
                              {/* <span>{symbol}</span> */}
                            </>
                          )}
                        </h3>
                      </div>
                    </div>
                    <div
                      className={`${style.cointdetailPage_tabs_overView} ${style.mt}`}
                    >
                      <div
                        className={`${style.cointdetailPage_tabs_overView_graphsec} card`}
                      >
                        <section>
                          <Row
                            className={
                              style.cointdetailPage_tabs_overView_graphsec_duration
                            }
                          >
                            <Col>
                              <h3>
                                $
                                {quoteCurrPrice && !isNaN(quoteCurrPrice) ? (
                                  <>
                                    {convertToThousandDigit(preciseData(name ==="USDT" ? 1 : quoteCurrPrice, 2))} 
                                  </>
                                ) : (
                                  Number(0).toFixed(2)
                                )}
                                {twenty24Hour &&
                                   <sup
                                   style={{  
                                     top: "-1em",
                                   }} 
                                   className={twenty24Hour && twenty24Hour.replace("%", "") > 0 ? "green" : "red"}
                                 >
                                   {name ==="USDT" ? "0.0" : `${twenty24Hour.replace("%", "") ?? 0} `}%
                                 </sup>
                                }
                              </h3>

                            </Col>
                            <Col>
                              <div className={style.btngraph}>
                                {graphDurationTimeObj?.map((item, idx) => {
                                  return (
                                    <div>
                                      <button
                                        key={item.key}
                                        className={`${style.timeBtn} ${time === item.value
                                          ? style.timeActive
                                          : ""
                                          }`}
                                        onClick={() => graphApi(item?.value)}
                                      >
                                        {item.label}
                                      </button>
                                    </div>
                                  );
                                })}
                              </div>
                            </Col>
                          </Row>
                        </section>
                        <div className="scrollportfolio">
                          <section
                            className={style.graph__sevolume_change_24hction}
                          >
                            <TradeLineGraph
                              data={currencyGraphData}
                              color={color}
                              graphTime={time}
                              name={name}
                            />
                          </section>
                          <section
                            className={
                              style.cointdetailPage_tabs_overView_graphsec_points
                            }
                          >
                            <h4>{t("coinDetail.market_Stats")}</h4>
                            <Row gutter={[16, { xs: 8, md: 20, lg: 24 }]}>
                              <Col xxl={6} lg={8} md={12} xs={24}>
                                <Points
                                  Heading={t("coindetails.heading.cap")}
                                  infoIcon={true}
                                  Content={`$${name === "USDT" ? convertToThousandDigit(truncateData2(1,2)) : (Number(twenty24HourHigh) ? convertToThousandDigit(truncateData2(twenty24HourHigh,2)) : Number(0).toFixed(2))} `}
                                  tooltip={t("overivew.Market_cap")}
                                />
                              </Col>
                              <Col xxl={6} lg={8} md={12} xs={24}>
                                <Points
                                  Heading={t("coindetails.heading.volume")}
                                  Content={`$${formatNumber(marketCap || 0)}`}
                                  detail={` ${twenty24Hour ?? 0} `}
                                  infoIcon={true}
                                  inPercent={true}
                                  color={twenty24Hour && twenty24Hour.replace("%", "") > 0 ? "green" : "red"}

                                  tooltip={t("overivew.Volume_(24h)")}
                                />
                              </Col>
                              {/* <Col xxl={6} lg={8} md={12} xs={24}>
                                <Points
                                  Heading={t("coindetails.heading.supply")}
                                  Content={`${circulating_supply
                                    ? formatNumber(circulating_supply)
                                    : "-"
                                    } ${name ? name : ""}`}
                                  detail={
                                    max_supply
                                      ? `${(
                                        (Number(circulating_supply) /
                                          Number(max_supply)) *
                                        100
                                      ).toFixed()}% ${t(
                                        "coindetails.ofsupply"
                                      )}`
                                      : ""
                                  }
                                  color="grey"
                                  tooltip={t("overivew.Circulating_supply")}
                                />
                              </Col> */}
                              <Col xxl={6} lg={8} md={12} xs={24}>
                                <Points
                                  Heading="Popularity"
                                  Content={`# ${crncy.position}`}
                                  infoIcon={false}
                                  // tooltip={t("overivew.Popularity")}
                                  // tooltipHide={true}
                                />
                              </Col>
                            </Row>
                          </section>
                          <section
                            className={
                              style.cointdetailPage_tabs_overView_graphsec_textline
                            }
                          >
                            <h4>{t("coinDetail.overview")}</h4>
                            <p>{description}</p>
                            {/* {i18next.exists("data." + id + ".desc") && (
                              <p>{t("data." + id + ".desc")}</p>
                            )} */}
                            <h5>{t("coinDetail.resources")}</h5>
                            {whitepaper?.url && (
                              <a target="_blank" href={whitepaper?.url}>
                                <img src={doc} alt="icon" />{" "}
                                <span>{t("coinDetail.whitepaper")}</span>
                              </a>
                            )}
                            {official_link && (
                              <a target="_blank" href={official_link}>
                                <img src={web} alt="icon" />
                                <span>{t("coinDetail.official_Website")}</span>
                              </a>
                            )}
                            <br />
                            {[...Array(8).keys()].map((index) => {
                              const questionKey = `qs${index + 1}`;
                              const answerKey = `an${index + 1}`;
                              if (i18next.exists(`data.${id}.${questionKey}`)) {
                                return (
                                  <div
                                    key={index}
                                    style={{
                                      whiteSpace: "pre-line",
                                    }}
                                  >
                                    <h4>{t(`data.${id}.${questionKey}`)}</h4>
                                    <p>{t(`data.${id}.${answerKey}`)}</p>
                                  </div>
                                );
                              }
                              return null;
                            })}
                          </section>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </TabPane>
              <TabPane
                tab={t("coinDetail.primary_alance")}
                key={primaryBalance}
                className={style.paddingLeft}
              >
                {activeTab === primaryBalance && (
                  <>
                    <div className={style.cointdetailPage_tabs}>
                      <div className={style.cointdetailPage_headBar}>
                        <h3
                          className={`text-black-36 ${style.heading} ${icon_url ? "hasIcon" : "NoIcon"
                            }`}
                        >
                          {icon_url ? (
                            <>
                              <img
                                className="coin_icon arbicIcon"
                                src={icon_url}
                                alt="icon"
                              />
                              <span>{name ? uppercase(name) : ""}</span>
                              {/* <span>{symbol}</span> */}
                            </>
                          ) : (
                            <>
                              <div
                                className="coin_icon_placeholder"
                                style={{
                                  backgroundColor: color || "#ccc",
                                  borderRadius: "50%",
                                  width: "44px",
                                  height: "44px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <span
                                  style={{
                                    color: "#fff", // Text color (adjust as needed)
                                    fontWeight: "bold",
                                  }}
                                >
                                  {name?.charAt(0).toUpperCase()}
                                </span>
                              </div>
                              <span>{name ? uppercase(name) : ""}</span>
                              {/* <span>{symbol}</span> */}
                            </>
                          )}
                        </h3>
                      </div>
                    </div>
                    <PrimaryBalanceTab />
                  </>
                )}
              </TabPane>
            </Tabs>
          </div>
          {/* </Col> */}
          {/* <Col xs={24} sm={24} md={24} xl={8} className="buySellInnerMargin">
                <div className={`${style.cointdetailPage_tabs_cardtab} card`}>
                  <div className="swapPage">
                    <BuySellTab fromWhere="overview" />
                  </div>
                </div>
              </Col>
            </Row> */}
        </div>
      </div>
    </>
  );
};

export default CoinDetail;
