import { capitalize } from "../helpers";

export const loginActiveScreen = {
  phoneOrEmail: "phoneOrEmail",
  verificationOtp: "verificationOtp",
  verification2FA: "verification2FA",
  device: "device",
};

export const kxRightObj = {
  rewards: "rewards",
  topMovers: "topMovers",
};

export const openCloseDivs = {
  convert: "convert",
  coin: "coin",
  pay: "pay",
  fiat: "fiat",
  network: "network",
  address: "address",
};

export const settingTabsKey = {
  profile: "profile",
  security: "security",
  transaction: "transaction",
  notification: "notification",
  announcement: "Announcement"
};

export const settingHisotryTabsKey = {
  buy: "buy",
  sell: "sell",
  convert: "convert",
  send: "send",
  receive: "receive",
  orders: "orders",
  trades: "trades",
  transfer: "transfer",
  wallet: "wallet"
};

export const buySellTabsKey = {
  buy: "Buy",
  sell: "Sell",
};

export const portfolioTabsKey = {
  overview: "overview",
  spot: "spot",
  funding: "funding",
  tradingBots: "tradingBots",
  connectMetamask: "connectMetamask",
  copy: "copy",
  grid: "Grid",
};

export const portfolioOverviewSubTabsKey = {
  wallet: "wallet",
  coin: "coin",
};

export const totalUSDType = {
  all: "all",
  spot: "spot",
  funding: "funding",
  grid: "grid",
  copy: "copy",
};

export const coinDetailTabs = {
  overview: "overview",
  primaryBalance: "primaryBalance",
};

export const loginTabsKey = {
  email: "email",
  phone: "phone",
};

export const loginFieldName = {
  email: "email",
  password: "password",
  captchaResponse: "captchaResponse",
};

export const fieldType = {
  normalInput: "normalInput",
  passwordInput: "pass",
  concentInput: "concent",
  captchaInput: "captcha",
  dateInput: "dateInput",
  selectInput: "selectInput",
  dateInputOnly: "dateInputOnly",
};

export const createAccountFieldName = {
  firstName: "firstName",
  lastName: "lastName",
  middleName: "middleName",
  dob: "dob",
  email: "email",
  password: "password",
  confirmPassword: "confirmPassword",
  captchaResponse: "captchaResponse",
  concent: "concent",
  referal: "referal",
};

export const initPhone = {
  areaCode: null,
  countryCode: 91,
  isoCode: "in",
  phoneNumber: null,
  valid: false,
};
export const signupVerifyIdentity = {
  firstName: "firstName",
  lastName: "lastName",
  question1: "question1",
  dateOfBirth: "dateOfBirth",
  question2: "question2",
  employmentStatus: "employmentStatus",
  panNumber: "panNumber",
  panName: "panName",
  streetAddress: "streetAddress",
  locality: "locality",
  stateInput: "stateInput",
  pinCode: "pinCode",
};
const {
  firstName,
  stateInput,
  lastName,
  question1,
  dateOfBirth,
  question2,
  employmentStatus,
  panNumber,
  panName,
  streetAddress,
  locality,
  pinCode,
} = signupVerifyIdentity;

export const defaultSignupVerifyIdentity = {
  [firstName]: " ",
  [lastName]: " ",
  [question1]: "",
  [dateOfBirth]: "",
  [question2]: "",
  [employmentStatus]: "",
  [panNumber]: "",
  [panName]: "",
  [streetAddress]: "",
  [locality]: "",
  [stateInput]: "",
  [pinCode]: "",
};

export const sendReceiveType = {
  deposit: "Deposit",
  withdraw: "Withdraw",
  transfer: "Transfer",
};

export const depositWithdrawDropdownType = {
  asset: "asset",
  network: "network",
  transfer: "transfers",
};

export const convertType = {
  from: "from",
  to: "to",
};

export const relevantProps = [
  "percent_change_1h",
  "percent_change_24h",
  "percent_change_7d",
  "percent_change_30d",
  "percent_change_60d",
  "percent_change_90d",
];

export const graphDurationTimeObj = [
  { label: "1H", value: "hr", key: "percent_change_1h" },
  { label: "1D", value: "day", key: "percent_change_24h" },
  { label: "1W", value: "week", key: "percent_change_7d" },
  { label: "1M", value: "month", key: "percent_change_30d" },
  { label: "1Y", value: "year", key: "percent_change_90d" },
];

export const upDownPriceColor = { up: "#329879", down: "#FC4F52" };

// export const upDownPriceColor = (theme) => {
//   if (theme === "light") {
//     return { up: "#329879", down: "#FC4F52" };
//   } else if (theme === "dark") {
//     return { up: "#30F1B6", down: "#FC4F52" };
//   }
// };


export const addBeneficiaryName = {
  addressLabel: "addressLabel",
  address: "address",
  code: "code",
};

export const changePasswordFields = {
  existing: "existing",
  changepass: "changepass",
  confirmpass: "confirmpass",
  otpField: "otpField",
  custom_otp_field: "newPassword"
};

export const contactUsFields = {
  email: "email",
  name: "name",
  subject: "subject",
  message: "message",
  captchaResponse: "captchaResponse",
};


export const forgotPasswordFields = {
  email: "email",
  captchaResponse: "captchaResponse",
};

export const referalTabsKey = {
  overview: "overview",
  rewards: "rewards",
  referal: "referal",
};

export const referalType = {
  member: "Member",
  bonus: "Bonus",
  trade: "Trade",
};

export const listingAgreementForm = {
  title: "title",
  signature: "signature",
};

export const AssetForm = {
  network: { name: "network", label: "Network" },
  coin_name: { name: "coin_name", label: "Coin Name" },
  contract_address: { name: "contract_address", label: "Contract Address" },
  symbol: { name: "symbol", label: "Symbol" },
  explorer_link: { name: "explorer_link", label: "Explorer Link" },
  issue_price: { name: "issue_price", label: "Issue Price" },
  maximum_supply: { name: "maximum_supply", label: "Maximum Supply" },
  coin_icon: { name: "coin_icon", label: "Coin Icon" },
};

export const complianceForm = {
  company_name: { name: "company_name", label: "Company Name" },
  company_address: { name: "company_address", label: "Company Address" },
  whitepaper_link: { name: "whitepaper_link", label: "White Paper Link" },
  website_link: { name: "website_link", label: "Website Link" },
};

export const ordersType = {
  fullOrder: "Full",
  partialOrder: "Partial",
  newOrder: "New",
};

export const sideColor = {
  sell: "#b42420",
  buy: "#389694",
  wait: "#bac265",
  pending: "#bac265",
  done: "green",
  cancel: "red",
  reject: "red",
};

export const TradesTableDataIndex = {
  date: "Date",
  pair: "Pair",
  side: "Side",
  avg_price: "avg_price",
  price: "Price",
  amount: "Amount",
  total: "Total",
  fee: "Fee",
};

export const ConvertTableDataIndex = {
  date: "Date",
  you_swap: "You Give",
  you_get: "Received Amount",
  status: "Status",
  currency_name: "Currency",
  price: "Amount(USDT)"
};

export const ListedCoinsDataIndex = {
  listed_coins: "listed_coins",
  total_supply: "total_supply",
  submit_date: "submit_date",
  action: "action",
};

export const coinListingSubmissionState = {
  pending: "pending",
  submitted: "submitted",
};

export const coinListingSubmissionStatus = {
  active: "active",
};

export const buySellFilterOpt = [
  { label: "All", value: "" },
  { label: "Completed", value: "completed" },
  { label: "Pending", value: "pending" },
  { label: "Failed", value: "failed" },
  { label: "Cancelled", value: "canceled" },
  // { label: "Complete", value: "Complete" },
];

// export const exportTypeOpt = [
//   { label: "Select Export Type", value: "" },
//   { label: "csv", value: "csv" },
//   { label: "xlsx", value: "xlsx" },
//   { label: "pdf", value: "pdf" },
// ]

export const sendFilterOpt = [
  { label: "All", value: "" },
  { label: "Confirming", value: "confirming" },
  { label: "Succeed", value: "succeed" },
  { label: "Processing", value: "processing" },
  { label: "Rejected", value: "rejected" },
  { label: "Failed", value: "failed" },
  { label: "Cancelled", value: "canceled" },
  { label: "Accepted", value: "accepted" },
];

export const receiveFilterOpt = [
  { label: "All", value: "" },
  { label: "Collected", value: "collected,collecting,fee_processing,fee_processed,fee_collecting,fee_collected,failed,accepted,skipped,processing" },
  { label: "Submitted", value: "submitted" },
  { label: "Cancelled", value: "canceled,errored,cancel" },
  { label: "Rejected", value: "rejected,reject" },
];

export const convertFilterOpt = [
  { label: "All", value: "" },
  { label: "Pending", value: "pending" },
  { label: "Collected", value: "collected" },
  { label: "Cancelled", value: "canceled" },
  { label: "Rejected", value: "rejected" },
];

export const walletFilterOpt = [
  { label: "All", value: "" },
  { label: "Pending", value: "pending" },
  { label: "Collected", value: "collected" },
  { label: "Submitted", value: "submitted" },
  { label: "Cancelled", value: "canceled" },
  { label: "Rejected", value: "rejected" },
];

export const orderFilterOpt = [
  { label: "All", value: "" },
  { label: "Pending", value: "pending" },
  { label: "New", value: "wait" },
  { label: "Done", value: "done" },
  { label: "Cancelled", value: "cancel" },
  { label: "Rejected", value: "reject" },
];

export const assetformValMessage = {
  uploadSize: "Please upload an icon less than or equal to 500KB",
  uploadType: "Please upload a file type of JPEG, PNG, JPG",
  uploadRequired: "Upload an icon",
  selectRequired: "Please select a network",
  maxSupplyRange: "Max supply should be between 1 to 10000000000",
  maxSupplyRequired: "Max supply is required",
  issuePriceRange:
    "Issue price should be greater than 0 and less than or equal to 10000",
  issuePriceRequired: "Issue price is required",
};

export const downloadDateName = {
  startDate: "startDate",
  endDate: "endDate",
};

export const exportObj = [
  // { label: "PDF", value: "pdf" },
  // { label: "Excel", value: "xlsx" },
  { label: "CSV", value: "csv" },
];

const { buy, sell, convert, send, receive, orders, trades, transfer, wallet } =
  settingHisotryTabsKey;

export const exportTypeObj = [
  // {
  //   label: capitalize(buy),
  //   value: buy,
  // },
  // {
  //   label: capitalize(sell),
  //   value: sell,
  // },
  {
    label: capitalize(send),
    value: send,
  },
  {
    label: capitalize(receive),
    value: receive,
  },
  {
    label: capitalize(orders),
    value: orders,
  },
  {
    label: capitalize(trades),
    value: trades,
  },
];

export const sendExportType = {
  [buy]: "TransakPayment",
  [sell]: "TransakPayment",
  [send]: "withdraw",
  [receive]: "deposit",
  [orders]: "order",
  [trades]: "trade",
  [transfer]: "BalanceHistory",
  [convert]: "swap",
  [wallet]: "Defiwallet"
};

export const exchangeOrdersTabs = {
  openOrder: "Open Orders",
  allOrder: "All Orders",
  tradeHistory: "Trade History",
  funds: "Funds",
};

export const gridHistoryTabs = {
  running: "Running",
  history: "History",
};

export const buySellConvertTabsKey = {
  buy: "buy",
  sell: "sell",
  convert: "convert",
};


export const copyDetailTabs = {
  current: "Current",
  history: "History",
};

export const proTraderCopyTabs = {
  current_position: "Current Positions",
  position_history: "Positions History",
  current_orders: "Current orders",
  order_history: "Orders History"
};


export const copygraphDurationTimeObj = [
  { label: "All", value: "all" },
  { label: "Last 7 Days", value: "last_seven_day" },
  { label: "Last 30 Days", value: "last_thirty_day" },
];

export const deleteAccountFields = {
  reason: "reason",
  code: "code",
};

export const gridDetailTabs = {
  working: "Working",
  completed: "Completed",
};