import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import * as config from "../../utils/apiVersion";
import { startLoading, stopLoading } from "../feature";
import { errorToastSelector, successToastSelector } from "../selector";
import { getUser } from "./user";
import { fetchLogout } from "./logout";

export const createResetPass = createAsyncThunk(
  "setting/createResetPass",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `identity/users/password/generate_code`;
      await API.post(config.barong)(url, payload);
      dispatch(stopLoading());
      dispatch(
        successToastSelector({
          message: "Email sent successfully",
        })
      );
      return { status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const changePass = createAsyncThunk(
  "setting/createResetPass",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `identity/users/password/confirm_code`;
      await API.post(config.barong)(url, payload);
      dispatch(stopLoading());
      dispatch(
        successToastSelector({
          message: "Password changed successfully",
        })
      );
      return { status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const verifyToken = createAsyncThunk(
  "setting/verifyToken",
  async (payload, { dispatch }) => {
    try {
      let url = `identity/users/password/link_expire`;
      await API.post(config.barong)(url, payload);
      return { status: true };
    } catch (e) {
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const changePassword = createAsyncThunk(
  "setting/changePassword",
  async (payload, { dispatch }) => {
        try {
      dispatch(startLoading());
      let url = `resource/users/password`;
      const res = await API.put(config.barong)(url, payload);
      dispatch(
        successToastSelector({
          message: `Password changed successfully`,
        })
      );
      await dispatch(
        fetchLogout({
          payload:{uid: payload?.data?.uid},
          isMessageVisible: false,
          noErrorDisplay:true
        })
      );
      dispatch(stopLoading());
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const getOtpCode = createAsyncThunk(
  "setting/getOtpCode",
  async ({ otpClicked, ...rest }, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `resource/users/password/otp_confirmation`;
      const res = await API.get(config.barong)(url, rest);
      dispatch(
        successToastSelector({
          message: `OTP ${otpClicked ? "resent" : "sent"} successfully`,
        })
      );
      dispatch(stopLoading());
      return { status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const getProfileData = createAsyncThunk(
  "setting/getProfileData",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `resource/profiles/me`;
      const res = await API.get(config.barong)(url);
      dispatch(stopLoading());
      return { status: true, data: res };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const kycUpdate = createAsyncThunk(
  "setting/kycUpdate",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `resource/profiles`;
      await API.put(config.barong)(url, payload);
      dispatch(stopLoading());
      return { status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const documentUpload = createAsyncThunk(
  "setting/documentUpload",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `resource/documents`;
      await API.post(config.barong)(url, payload);
      dispatch(stopLoading());
      dispatch(getUser());
      dispatch(getProfileData());
      return { status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const profileUpdateKyc = createAsyncThunk(
  "setting/profileUpdateKyc",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `resource/profiles`;
      await API.put(config.barong)(url, payload);
      const user = API.get(config.barong)("/resource/users/me");
      dispatch(stopLoading());
      dispatch(getUser({ isNull: true }));
      dispatch(
        successToastSelector({
          message: "Profile Updated Successfully",
        })
      );
      return { status: true, data: user };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const resetProfile = createAsyncThunk(
  "setting/resetProfile",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `resource/sumsub/reset`;
      let res = await API.get(config.barong)(url, payload);
      dispatch(stopLoading());
      return { status: true ,data: res,};
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);
export const getSumsubToken = createAsyncThunk(
  "setting/getSumsubToken",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/resource/sumsub/token`;
      const res = await API.get(config.barong)(url, payload);
      dispatch(stopLoading());
      return { status: true, data: res };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const saveSumsubApplicant = createAsyncThunk(
  "setting/saveSumsubApplicant",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/resource/sumsub/applicant_id`;
      const res = await API.post(config.barong)(url, payload);
      dispatch(stopLoading());
      return { status: true, data: res };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);
