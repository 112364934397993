import { createSlice } from "@reduxjs/toolkit";
import { getMembersData, getUser } from "../../services";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    data: {},
    error: false,
    userIsLoading: null,
    require_kyc: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state, { meta }) => {
        state.userIsLoading = meta?.arg?.isNull ? null : false;
        state.error = false;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.data = { ...action.payload };
        state.userIsLoading = false;
      })
      .addCase(getUser.rejected, (state) => {
        state.data = {};
        state.error = true;
        state.userIsLoading = false;
      })

      .addCase(getMembersData.pending, (state) => { })
      .addCase(getMembersData.fulfilled, (state, action) => {
        state.require_kyc = action.payload;
      })
      .addCase(getMembersData.rejected, (state) => { })
  },
});

export default userSlice.reducer;
