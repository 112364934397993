import React, { useEffect, useMemo, useState } from "react";
import {
  Drawer,
  Button,
  Dropdown,
  Avatar,
  Grid,
  Menu,
  Tooltip,
  Switch,
  message,
} from "antd";
import { Link, Link as RouterLink } from "react-router-dom";
import style from "./style.module.scss";
import "../../Layout/Header/headerStyle.scss";
import { rootName } from "../../../utils/constant";
import {
  publicRouteObj,
  sidebarRouteObj,
  primaryRoutes,
} from "../../../staticObjects";
import { useNavigate } from "react-router-dom";
import CommonButton from "../../Ui/button/CommonButton";
import { StoreImages } from "../../Storeimgaes/StoreImages";
import landinglogo from "../../Assets/Images/logo.png";
import landinglogoLight from "../../Assets/Images/logoDark.png";
import { useDispatch, useSelector } from "react-redux";
import { isAuthenticated } from "../../../redux/selector";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import LanguageLight from "../../Assets/Images/globalNewLight.svg";
import { capitalize, generateUserIcon } from "../../../helpers";
import { fetchLogout } from "../../../redux/services/logout.js";
import { clearCryptoAddress, setTheme } from "../../../redux/feature/index.js";
// import { useDisconnect } from "wagmi";
// import { useWeb3Modal } from "@web3modal/wagmi/react";
import { updateUserExtraData } from "../../../redux/services";
import DarkToggel from "../../Assets/Images/darkToggel.svg";
import LightToggel from "../../Assets/Images/lightToggle.png";
import logoDark from "../../Assets/Images/logoDark.png";
import { ToggleIcons } from "../../Assets/Images/SvgImgs.jsx";
import Language from "../../Assets/Images/Language.svg";
import { MoonIcon, SunIcon } from "../../Assets/Svg/Svg.jsx";
import "./Landing.scss";

const Header = () => {
  const { ToggleIcon } = StoreImages;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { useBreakpoint } = Grid;
  const { lg, md, sm } = useBreakpoint();
  const { t } = useTranslation();
  const { data } = useSelector((state) => state?.user);
  const isLoged = useSelector((state) => isAuthenticated(state));
  const { isLight } = useSelector((state) => state.theme);
  const [visible, setVisible] = useState(false);
  const [language, setLanguage] = useState(null);
  const [color, setColor] = useState(false);
  const [arrow, setArrow] = useState("Show");
  const prfileData = isLoged && data?.profiles && data?.profiles[0];
  let { shortName } = generateUserIcon(
    prfileData?.first_name,
    prfileData?.last_name
  );
  const { themeMode } = useSelector((state) => state.theme);
  const [checkMode, setCheckMode] = useState(false);
  // const { disconnect } = useDisconnect();
  // const { close } = useWeb3Modal();
  useEffect(() => {
    if (data) {
      if (data?.mode === "dark") {
        setCheckMode(false);
        dispatch(setTheme("theme-dark"));
        localStorage.setItem("theme", "theme-dark");
      } else {
        setCheckMode(true);
        dispatch(setTheme("theme-light"));
        localStorage.setItem("theme", "theme-light");
      }
    }
  }, [data]);
  const colorChange = () => {
    if (window.scrollY >= 10) {
      setColor(true);
    } else setColor(false);
  };
  window.addEventListener("scroll", colorChange);
  let loginLink = `${rootName}${publicRouteObj.login}`;
  let signupLink = `${rootName}${publicRouteObj.createAccount}`;

  const handleLanguageClick = (item) => {
    setLanguage(item);
    i18next.changeLanguage(item);
    // localStorage.setItem("language", item);
  };
  const languageList = [
    { code: "en", label: "English" },
    // { code: "ar", label: "Arabic" },
  ];

  const mergedArrow = useMemo(() => {
    if (arrow === "Hide") {
      return false;
    }
    if (arrow === "Show") {
      return true;
    }
    return {
      pointAtCenter: true,
    };
  }, [arrow]);
  const { logo, logoWhite } = StoreImages;
  const is2FA = data?.otp;

  const logoutAction = async (e) => {
    e.preventDefault();
    let res = await dispatch(
      fetchLogout({
        uid: data?.uid,
      })
    );
    if (res.payload) {
      // disconnect();
      // close(); // Close the Web3Modal connection
      dispatch(clearCryptoAddress());
      navigate(`${rootName}${publicRouteObj.login}`);
    }
  };

  const widgetMenu = () => {
    return (
      <div className={`setting ${style.SettingDrop}`} style={{ zIndex: "1" }}>
        <Menu
          items={[
            {
              key: "avtar",
              label: (
                <>
                  <Avatar size={md ? 50 : 40}>{shortName}</Avatar>
                  <br />
                  <span>
                    {capitalize(prfileData?.first_name)}{" "}
                    {capitalize(prfileData?.last_name)}
                  </span>
                </>
              ),
            },
            // {
            //   key: "email",
            //   label: (
            //     <Tooltip placement="top" title={data.email} arrow={mergedArrow}>
            //       <Link
            //         to={`${rootName}${primaryRoutes.setting}`}
            //         style={{ fontSize: "14px" }}
            //       >
            //         {data.email}
            //       </Link>
            //     </Tooltip>
            //   ),
            // },
            {
              key: "settings",
              label: (
                <Link to={`${rootName}${primaryRoutes.setting}`}>
                  Profile Settings
                </Link>
              ),
            },
            {
              key: "transaction",
              label: (
                <Link to={`${rootName}${sidebarRouteObj.transactions}`}>
                  Transaction History
                </Link>
              ),
            },
            is2FA && {
              key: "address_managment",
              label: (
                <Link to={`${rootName}${primaryRoutes.addressManagement}`}>
                  Address Managment
                </Link>
              ),
            },
            {
              key: "support",
              label: (
                <Link to={`${rootName}${primaryRoutes.contactPrivate}`}>
                  Get Support
                </Link>
              ),
            },
            {
              key: "signout",
              label: (
                <Link to="#" onClick={logoutAction}>
                  Sign Out
                </Link>
              ),
            },
          ]}
        />
      </div>
    );
  };
  const addThemeColor = (val) => {
    if (val === "dark") {
      dispatch(setTheme("theme-dark"));
      localStorage.setItem("theme", "theme-dark");
    } else {
      dispatch(setTheme("theme-light"));
      localStorage.setItem("theme", "theme-light");
    }
  };
  useEffect(() => {
    // if (!isLoged) {
    if (themeMode === "theme-dark") {
      setCheckMode(false);
      dispatch(setTheme(themeMode));
      localStorage.setItem("theme", themeMode);
    } else {
      setCheckMode(true);
      dispatch(setTheme(themeMode));
      localStorage.setItem("theme", themeMode);
      setCheckMode(true);
    }
    // }
  }, [themeMode]);

  const [messageApi, contextHolder] = message.useMessage(true);
  const info = () => {
    messageApi.info("Coming Soon");
  };
  return (
    <>
      <header className={` ${color ? "bg-white" : ""}`}>
        <div className="container">
          <div className={style.header}>
            <a
              onClick={(e) => {
                e.preventDefault();
                navigate(
                  `${
                    isLoged
                      ? `${rootName}${primaryRoutes.authLanding}`
                      : rootName
                  }`
                );
              }}
            >
              {/* <h2 className="logo-white"> */}
              {themeMode === "theme-light" ? (
                <img
                  src={landinglogoLight}
                  alt="imgs"
                  className={style.landingImgLogo}
                />
              ) : (
                <img
                  src={landinglogo}
                  alt="imgs"
                  className={style.landingImgLogo}
                />
              )}
              {/* </h2> */}
            </a>
            <div
              className={`navbarStyle ${style.header_links} ${style.navbarfull}
            ${isLoged ? "landingPage" : ""}
            `}
            >
              {isLoged && (
                <>
                  <div className="landingHeaderLeft LinksHeader">
                    {/* <RouterLink to={`${rootName}listing`}>
                        {t("sidebar.listing")}
                      </RouterLink> */}
                    <RouterLink to={`${rootName}${sidebarRouteObj.market}`}>
                      Market News
                    </RouterLink>
                  </div>
                </>
              )}
              <Button
                type={"text"}
                className={`textBtn ${isLoged ? "" : "signInBtnExes"}`}
                onClick={() => {
                  navigate(`${rootName}${sidebarRouteObj.advTrade}`);
                }}
              >
                Trade
              </Button>
              {contextHolder}
              {/* <Tooltip placement="bottom" title="Coming Soon"> */}
              <Button
                type={"text"}
                className={`textBtn ${isLoged ? "" : "signInBtnExes"}`}
                onClick={() => window.open("https://gems.vip", "_blank")}
              >
                Launchpad
              </Button>
              {/* </Tooltip> */}
              {/* <Tooltip placement="bottom" title="Coming Soon"> */}
              {/* <Button
                type={"text"}
                className={`textBtn ${isLoged ? "" : "signInBtnExes"}`}
              >
                Earn
              </Button> */}
              {/* </Tooltip> */}

              {/* <Tooltip placement="bottom" title="Coming Soon"> */}
              {/* <Button
                type={"text"}
                className={`textBtn ${isLoged ? "" : "signInBtnExes"}`}
              >
                Market News
              </Button> */}
              {/* </Tooltip> */}
              {/* <Tooltip placement="bottom" title="Coming Soon">
                <Button
                  type={"text"}
                  className={`textBtn ${isLoged ? "" : "signInBtnExes"}`}
                >
                  Support Center
                </Button>
              </Tooltip> */}

              {isLoged ? (
                <>
                  {/* <div className="landingHeaderLeft LinksHeader"> */}
                  {/* <RouterLink to={`${rootName}listing`}>
                      {t("sidebar.listing")}
                    </RouterLink> */}
                  {/* <RouterLink to={`${rootName}${sidebarRouteObj.home}`}>
                      {t("sidebar.home")}
                    </RouterLink>
                    <RouterLink to={`${rootName}${sidebarRouteObj.advTrade}`}>
                      Trading
                    </RouterLink> */}
                  {/* </div> */}
                  <div className="landingExtraTabs">
                    <ul>
                      {/* <li className="notificationStyle">
                        <NotificationIcon />
                      </li> */}
                      <li>
                        <Dropdown
                          overlayClassName="languageDrop"
                          overlay={
                            <div className="languageMenu">
                              <ul>
                                {languageList.map((languageOption) => (
                                  <li
                                    key={languageOption.code}
                                    onClick={() =>
                                      handleLanguageClick(languageOption.code)
                                    }
                                    className={
                                      language === languageOption.code
                                        ? "selectedLanguage"
                                        : ""
                                    }
                                  >
                                    {languageOption.label}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          }
                          trigger={["hover"]}
                          className="langaugeDrop"
                        >
                          {checkMode ? (
                            <img src={LanguageLight} alt="Language" />
                          ) : (
                            <img
                              src={Language}
                              // style={{ width: "40px", height: "40px" }}
                              alt="Language"
                            />
                          )}
                        </Dropdown>
                      </li>

                      <li>
                        <Dropdown
                          overlayClassName="languageDrop"
                          overlay={widgetMenu}
                          className="settingDropDown"
                          trigger={["click"]}
                        >
                          <Avatar size={md ? 40 : 30}>{shortName}</Avatar>
                        </Dropdown>
                      </li>

                      <div className="container--toggle">
                        {/* <Switch
                          defaultChecked
                          onClick={() => {
                            if (isLoged) {
                              dispatch(
                                updateUserExtraData({
                                  mode: "dark",
                                })
                              );
                            } else {
                              addThemeColor("dark");
                            }
                          }}
                        /> */}

                        <div className="toggle-container">
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={checkMode}
                              onChange={() =>
                                addThemeColor(checkMode ? "dark" : "light")
                              }
                            />
                            <span className="slider">
                              {/* <span className="icon">{checkMode ? <MoonIcon /> : <SunIcon />}</span> */}
                              <span className="icon toogleSwitchIcons">
                                <MoonIcon /> <SunIcon />
                              </span>
                            </span>
                          </label>
                        </div>

                        {/* {checkMode ? (
                          <img
                            src={LightToggel}
                            onClick={() => {
                              dispatch(
                                updateUserExtraData({
                                  mode: "dark",
                                })
                              );
                            }}
                            style={{ cursor: "pointer" }}
                          />
                        ) : (
                          <img
                            src={DarkToggel}
                            onClick={() => {
                              dispatch(
                                updateUserExtraData({
                                  mode: "light",
                                })
                              );
                            }}
                            style={{ cursor: "pointer" }}
                          />
                        )} */}
                      </div>
                    </ul>
                  </div>
                </>
              ) : (
                <>
                  {/* <RouterLink to={loginLink}>Why Gems</RouterLink>
                  <RouterLink to={loginLink}>Our Token</RouterLink>
                  <RouterLink to={loginLink}>Ecosystem</RouterLink>
                  <RouterLink to={loginLink}>Market Update</RouterLink>
                  <RouterLink to={loginLink}>Exchange</RouterLink>
                  <RouterLink to={loginLink}>Swap</RouterLink> */}
                  {/* <RouterLink to={`${rootName}${sidebarRouteObj.advTrade}`}>
                    Exchange
                  </RouterLink> */}
                  {/* <Button
                    type={"text"}
                    className={`textBtn ${
                      isLoged ? "" : "signInBtnExes lists"
                    }`}
                    onClick={() => {
                      navigate(`${rootName}listing`);
                    }}
                  >
                    {t("listing.header_title")}
                  </Button>

                  <Button
                    type={"text"}
                    className={`textBtn ${isLoged ? "" : "signInBtnExes"}`}
                    onClick={() => {
                      navigate(`${rootName}${sidebarRouteObj.advTrade}`);
                    }}
                  >
                    Exchange
                  </Button>

                  <Button
                    type={"text"}
                    className={`textBtn ${isLoged ? "" : "signInBtnExes"}`}
                    onClick={() => {
                      navigate(`${rootName}${publicRouteObj.contactPublic}`);
                    }}
                  >
                    Contact us
                  </Button> */}

                  {/* <span className="container--toggle rgToggleTheme">
                    {checkMode ? (
                      <img
                        src={LightToggel}
                        onClick={() => {
                          addThemeColor("dark");
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      <img
                        src={DarkToggel}
                        onClick={() => {
                          addThemeColor("light");
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </span> */}
                  <div className="toggle-container">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={checkMode}
                        onChange={() =>
                          addThemeColor(checkMode ? "dark" : "light")
                        }
                      />
                      <span className="slider">
                        {/* <span className="icon">{checkMode ? <MoonIcon /> : <SunIcon />}</span> */}
                        <span className="icon toogleSwitchIcons">
                          <MoonIcon /> <SunIcon />
                        </span>
                      </span>
                    </label>
                  </div>
                  <CommonButton
                    title="Login"
                    className={`loginBtn ${isLoged ? "" : "signInBtns"}`}
                    onClick={() => {
                      navigate(loginLink);
                    }}
                  />
                  <CommonButton
                    title="Register"
                    className={`${isLoged ? "" : "signInBtns"}`}
                    onClick={() => {
                      navigate(`${rootName}${publicRouteObj.createAccount}`);
                    }}
                  />
                  {/* <button
                    className={`textBtn ${isLoged ? "" : "regBtnExes"}`}
                    onClick={() => {
                      navigate(`${rootName}${publicRouteObj.createAccount}`);
                    }}
                  >
                    Register
                  </button> */}
                </>
              )}
              {/* <span className="themeIcon"><img src={themeIcon} alt="" /></span> */}
            </div>
            <div className={`${style.responsiveSidebar}`}>
              <div className="landingPage responsive">
                <div className="landingExtraTabs">
                  <ul>
                    {isLoged && (
                      <>
                        <li>
                          <Dropdown
                            overlayClassName="languageDrop"
                            overlay={
                              <div className="languageMenu">
                                <ul>
                                  {languageList.map((languageOption) => (
                                    <li
                                      key={languageOption.code}
                                      onClick={() =>
                                        handleLanguageClick(languageOption.code)
                                      }
                                      className={
                                        language === languageOption.code
                                          ? "selectedLanguage"
                                          : ""
                                      }
                                    >
                                      {languageOption.label}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            }
                            trigger={["hover"]}
                            className="langaugeDrop"
                          >
                            <img
                              src={LanguageLight}
                              style={{ width: "40px", height: "40px" }}
                              alt="Language"
                            />
                          </Dropdown>
                        </li>
                        {/* <li>
                          <NotificationIcon />
                        </li> */}
                        <li>
                          <Dropdown
                            overlayClassName="languageDrop"
                            overlay={widgetMenu}
                            className="settingDropDown"
                            trigger={["click"]}
                          >
                            <Avatar size={md ? 40 : 30}>{shortName}</Avatar>
                          </Dropdown>
                        </li>
                      </>
                    )}

                    {/* <div className="container--toggle"> */}
                    <div className="toggle-container">
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={checkMode}
                          onChange={() =>
                            addThemeColor(checkMode ? "dark" : "light")
                          }
                        />
                        <span className="slider">
                          {/* <span className="icon">{checkMode ? <MoonIcon /> : <SunIcon />}</span> */}
                          <span className="icon toogleSwitchIcons">
                            <MoonIcon /> <SunIcon />
                          </span>
                        </span>
                      </label>
                    </div>
                    {/* </div> */}
                  </ul>
                </div>
                <div className="landingHeaderLeft">
                  {/* <MenuFoldOutlined /> */}
                  {/* <img
                    src={ToggleIcon}
                    alt="toggleIcon"
                    onClick={() => {
                      setVisible(true);
                    }}
                  /> */}
                  <span
                    className="toggleIcon"
                    onClick={() => {
                      setVisible(true);
                    }}
                  >
                    <ToggleIcons />
                  </span>
                </div>
                <Drawer
                  className="drawerLanding"
                  title={
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(
                          `${
                            isLoged
                              ? `${rootName}${primaryRoutes.authLanding}`
                              : rootName
                          }`
                        );
                      }}
                    >
                      <h2 className="logo-white drawerLogo">
                        <img
                          className="convenience-dark"
                          src={logo}
                          alt="logo"
                        />
                        <img
                          className="convenience-light"
                          src={logoDark}
                          alt="logo"
                        />
                      </h2>
                    </a>
                  }
                  placement="left"
                  // closable={false}
                  visible={visible}
                  onClose={() => {
                    setVisible(false);
                  }}
                >
                  <div className="sidebarHeader">
                    {isLoged ? (
                      <>
                        {/* <RouterLink to={`${rootName}${sidebarRouteObj.home}`}>
                          {t("sidebar.home")}
                        </RouterLink>
                        <RouterLink
                          to={`${rootName}${sidebarRouteObj.advTrade}`}
                        >
                          Exchange
                        </RouterLink> */}

                        <Button
                          type={"text"}
                          className={`textBtn ${
                            isLoged ? "" : "signInBtnExes"
                          }`}
                          onClick={() => {
                            navigate(`${rootName}${sidebarRouteObj.advTrade}`);
                          }}
                        >
                          Trade
                        </Button>
                        {/* <Tooltip placement="bottom" title="Coming Soon">
                          <Button
                            type={"text"}
                            className={`textBtn ${
                              isLoged ? "" : "signInBtnExes"
                            }`}
                          >
                            Gems Token
                          </Button>
                        </Tooltip> */}
                        {/* <Tooltip placement="bottom" title="Coming Soon">
                          <Button
                            type={"text"}
                            className={`textBtn ${
                              isLoged ? "" : "signInBtnExes"
                            }`}
                          >
                            Earn
                          </Button>
                        </Tooltip> */}
                        {/* <Tooltip placement="bottom" title="Coming Soon">
                          <Button
                            type={"text"}
                            className={`textBtn ${
                              isLoged ? "" : "signInBtnExes"
                            }`}
                          >
                            Market News
                          </Button>
                        </Tooltip> */}
                        {/* <Tooltip placement="bottom" title="Coming Soon">
                          <Button
                            type={"text"}
                            className={`textBtn ${
                              isLoged ? "" : "signInBtnExes"
                            }`}
                          >
                            Support Center
                          </Button>
                        </Tooltip> */}
                      </>
                    ) : (
                      <>
                        {/* <RouterLink to={loginLink}>Why Gems</RouterLink>{" "}
                        <RouterLink to={loginLink}>Our Token</RouterLink>{" "}
                        <RouterLink to={loginLink}>Ecosystem</RouterLink>{" "}
                        <RouterLink to={loginLink}>Market Update</RouterLink>{" "}
                        <RouterLink to={loginLink}>Exchange</RouterLink>
                        <RouterLink to={loginLink}>Swap</RouterLink> */}
                        {/* <Button
                          type={"text"}
                          className={`cstmbg ${
                            isLoged ? "" : "nologinBtn-exe"
                          }`}
                          onClick={() => {
                            navigate(`${rootName}${sidebarRouteObj.advTrade}`);
                          }}
                        >
                          Exchange
                        </Button> */}
                        {/* <CommonButton
                          title="Sign In"
                          className={`${isLoged ? "" : "signInBtn"}`}
                          onClick={() => {
                            navigate(loginLink);
                          }}
                        />
                        <RouterLink
                          to={signupLink}
                          className={`${isLoged ? "" : "regBtn"}`}
                          onClick={() => {
                            navigate(
                              `${rootName}${publicRouteObj.createAccount}`
                            );
                          }}
                        >
                          Register
                        </RouterLink> */}
                        <Button
                          type={"text"}
                          className={`textBtn ${
                            isLoged ? "" : "signInBtnExes"
                          }`}
                        >
                          Trade
                        </Button>
                        {/* <Button
                          type={"text"}
                          className={`textBtn ${
                            isLoged ? "" : "signInBtnExes"
                          }`}
                        >
                          Gems Token
                        </Button> */}
                        {/* <Button
                          type={"text"}
                          className={`textBtn ${
                            isLoged ? "" : "signInBtnExes"
                          }`}
                        >
                          Earn
                        </Button> */}
                        {/* <Button
                          type={"text"}
                          className={`textBtn ${
                            isLoged ? "" : "signInBtnExes"
                          }`}
                        >
                          Market News
                        </Button> */}
                        {/* <Button
                          type={"text"}
                          className={`textBtn ${
                            isLoged ? "" : "signInBtnExes"
                          }`}
                        >
                          Support Center
                        </Button> */}
                        <Button
                          type={"text"}
                          className={`textBtn ${
                            isLoged ? "" : "signInBtnExes"
                          }`}
                          onClick={() =>
                            window.open("https://gems.vip", "_blank")
                          }
                        >
                          Launchpad
                        </Button>
                        <CommonButton
                          title="Login"
                          className={`loginBtn ${isLoged ? "" : ""}`}
                          onClick={() => {
                            navigate(loginLink);
                          }}
                        />
                        <CommonButton
                          title="Register"
                          className={`registerBtn ${
                            isLoged ? "" : "signInBtns"
                          }`}
                          // onClick={() => {
                          //   navigate(
                          //     `${rootName}${publicRouteObj.createAccount}`
                          //   );
                          // }}
                        />
                      </>
                    )}
                  </div>
                </Drawer>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
