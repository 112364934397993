export function GrayEye() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13.842"
        height="9.228"
        viewBox="0 0 13.842 9.228"
      >
        <path
          id="Icon_awesome-eye"
          data-name="Icon awesome-eye"
          d="M13.758,8.763A7.708,7.708,0,0,0,6.921,4.5,7.709,7.709,0,0,0,.084,8.763a.777.777,0,0,0,0,.7,7.708,7.708,0,0,0,6.837,4.263,7.709,7.709,0,0,0,6.837-4.263A.777.777,0,0,0,13.758,8.763ZM6.921,12.574a3.46,3.46,0,1,1,3.46-3.46A3.46,3.46,0,0,1,6.921,12.574Zm0-5.767a2.29,2.29,0,0,0-.608.091A1.15,1.15,0,0,1,4.705,8.506a2.3,2.3,0,1,0,2.216-1.7Z"
          transform="translate(0 -4.5)"
          fill="#80838e"
        />
      </svg>
    </>
  );
}
export function SkypeIcon() {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_179_5147)">
        <path
          d="M5.49335 9.53179L5.26177 12.7891C5.5931 12.7891 5.7366 12.6468 5.90869 12.4759L7.4621 10.9913L10.6809 13.3485C11.2713 13.6775 11.6872 13.5043 11.8464 12.8055L13.9593 2.90512L13.9599 2.90454C14.1471 2.03187 13.6443 1.69062 13.0691 1.90471L0.649937 6.65946C-0.197646 6.98846 -0.184813 7.46096 0.505854 7.67504L3.68094 8.66262L11.056 4.04787C11.4031 3.81804 11.7187 3.94521 11.4591 4.17504L5.49335 9.53179Z"
          fill="#02171D"
        />
      </g>
      <defs>
        <clipPath id="clip0_179_5147">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0 0.676208)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
export function LinkedInIcon() {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.02234 1.38712C1.05992 1.38712 0.430664 2.0191 0.430664 2.84975C0.430664 3.66205 1.04117 4.31205 1.98541 4.31205H2.00368C2.98493 4.31205 3.5956 3.66205 3.5956 2.84975C3.57725 2.0191 2.98493 1.38712 2.02234 1.38712Z"
        fill="#02171D"
      />
      <path
        d="M0.596924 5.46777H3.4105V13.9325H0.596924V5.46777Z"
        fill="#02171D"
      />
      <path
        d="M10.3165 5.2691C8.79872 5.2691 7.78094 6.69536 7.78094 6.69536V5.46777H4.96729V13.9325H7.78078V9.20548C7.78078 8.95242 7.79913 8.69976 7.87347 8.51879C8.07686 8.01347 8.53973 7.48996 9.31702 7.48996C10.3351 7.48996 10.7423 8.26621 10.7423 9.40415V13.9325H13.5557V9.07899C13.5557 6.47898 12.1675 5.2691 10.3165 5.2691Z"
        fill="#02171D"
      />
    </svg>
  );
}
export function CoinMasterIcon() {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_179_5155)">
        <path
          d="M12.0972 9.04179C11.8528 9.19637 11.5652 9.21562 11.347 9.09254C11.0694 8.93504 10.9165 8.56871 10.9165 8.05771V6.53054C10.9165 5.79321 10.6249 5.26821 10.1372 5.12646C9.31119 4.88554 8.68935 5.89646 8.45602 6.27679L6.99885 8.63929V5.75179C6.98252 5.08679 6.76669 4.68954 6.35719 4.56937C6.08535 4.49062 5.67994 4.52213 5.2856 5.12529L2.02069 10.3677C1.58361 9.53796 1.35574 8.61404 1.35685 7.67621C1.35685 4.51804 3.88794 1.94904 6.99885 1.94904C10.1092 1.94904 12.6397 4.51804 12.6397 7.67621C12.642 7.68671 12.6409 7.69604 12.6414 7.70713C12.6706 8.31904 12.4723 8.80554 12.0972 9.04296V9.04179ZM13.8939 7.67679L13.8933 7.64471C13.8758 3.79879 10.7899 0.676208 6.99827 0.676208C3.19669 0.676208 0.103271 3.81629 0.103271 7.67621C0.103271 11.5355 3.19669 14.6762 6.99827 14.6762C8.74302 14.6762 10.4084 14.013 11.6865 12.8095C11.9403 12.5704 11.9549 12.1679 11.7198 11.9106C11.6643 11.8494 11.5972 11.7999 11.5224 11.765C11.4475 11.7301 11.3665 11.7104 11.284 11.7072C11.2014 11.704 11.1191 11.7173 11.0418 11.7463C10.9645 11.7753 10.8937 11.8195 10.8337 11.8762C9.79779 12.857 8.42541 13.4035 6.99885 13.4034C5.33285 13.4034 3.83427 12.6666 2.8006 11.497L5.74527 6.76912V8.94904C5.74527 9.99554 6.15127 10.3339 6.49194 10.433C6.8326 10.5322 7.35294 10.4645 7.89952 9.57671C8.48169 8.63521 9.08077 7.65346 9.66294 6.73004V8.05771C9.66294 9.03654 10.0549 9.81938 10.738 10.2044C11.354 10.5526 12.1287 10.5211 12.7593 10.1227C13.5246 9.63971 13.9364 8.74954 13.8933 7.67679H13.8939Z"
          fill="#02171D"
        />
      </g>
      <defs>
        <clipPath id="clip0_179_5155">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0 0.676208)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
export function TwitterIcon() {
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="" maskUnits="userSpaceOnUse" x="0" y="0" width="14" height="15">
        <path d="M0 0.676208H14V14.6762H0V0.676208Z" fill="white" />
      </mask>
      <g mask="url(#mask0_179_5141)">
        <path d="M11.025 1.32823H13.172L8.482 6.70223L14 14.0162H9.68L6.294 9.58123L2.424 14.0162H0.275L5.291 8.26623L0 1.32923H4.43L7.486 5.38223L11.025 1.32823ZM10.27 12.7282H11.46L3.78 2.54923H2.504L10.27 12.7282Z" fill="#02171D" />
      </g>
    </svg>

  );
}
export function WhiteIcon() {
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_179_5157)">
        <mask id="" maskUnits="userSpaceOnUse" x="0" y="0" width="14" height="15">
          <path d="M14 0.676208H0V14.6762H14V0.676208Z" fill="white" />
        </mask>
        <g mask="url(#mask0_179_5157)">
          <path d="M7.01762 0.676278C3.17916 0.69384 0.0817498 3.84173 0.0987021 7.70768C0.116138 11.5736 3.24211 14.6932 7.08008 14.6761C10.9185 14.6586 14.016 11.5107 13.999 7.64523C13.9816 3.7788 10.8561 0.659205 7.01762 0.676278ZM6.13755 7.33303C5.45122 7.33303 4.89471 6.77302 4.89471 6.08178C4.89471 5.39055 5.45122 4.83053 6.13755 4.83053C6.82387 4.83053 7.37991 5.39103 7.37991 6.08178C7.37991 6.77252 6.82338 7.33303 6.13755 7.33303ZM7.12853 8.68574L7.13483 8.67794L7.14159 8.67014C7.49614 8.90039 7.90106 8.98185 8.30549 8.99356C8.71041 9.00428 9.12307 8.97356 9.52896 8.89112C9.93435 8.80624 10.333 8.68087 10.718 8.52086C10.934 8.4311 11.1491 8.33306 11.354 8.21794C11.3554 8.21696 11.3564 8.21646 11.3579 8.21549C11.4852 8.13841 11.6097 8.05695 11.7318 7.97305C11.7584 7.95402 11.7841 7.93401 11.8093 7.91354L11.8122 7.91695L11.8228 7.92962C11.5104 8.21598 11.1384 8.42866 10.7597 8.61647C10.3771 8.79794 9.97795 8.94672 9.56577 9.05404C9.15504 9.16088 8.72639 9.24186 8.29241 9.20186C7.86522 9.16381 7.41526 9.01308 7.12756 8.68624L7.12853 8.68574ZM10.3175 7.33303C10.317 7.18278 10.4371 7.0618 10.5853 7.06034C10.7345 7.05986 10.8546 7.18035 10.8561 7.3301C10.8566 7.47986 10.7364 7.6023 10.5882 7.6028C10.4395 7.60328 10.3189 7.48231 10.3175 7.33303ZM8.18876 14.0469C7.92043 12.1527 9.56286 10.2975 10.4889 9.3482C10.6991 9.13307 11.0241 8.83649 11.3264 8.52136C12.5285 7.38914 12.7678 6.03641 10.3073 5.3603C9.84088 5.2242 9.3575 5.03151 8.86782 4.83686C8.85232 4.79151 8.83488 4.74857 8.81453 4.70809C8.75836 4.61053 8.66004 4.49832 8.51133 4.371C8.19167 4.09148 7.5906 4.0988 7.07186 4.22271C6.4984 4.08661 5.93266 4.03831 5.38875 4.16953C3.00914 4.83004 2.62652 5.99885 2.534 7.40718C2.40808 8.92916 2.33446 10.0633 1.76244 11.359C1.04851 10.3219 0.627612 9.0638 0.6218 7.70621C0.605817 4.13148 3.47024 1.22068 7.01955 1.20458C10.5688 1.18849 13.4589 4.07344 13.4749 7.64816C13.4895 10.8409 11.2053 13.5049 8.18828 14.0478L8.18876 14.0469Z" fill="#02171D" />
          <path d="M7.0112 6.09301C7.0112 6.57937 6.61984 6.97303 6.13744 6.97303C5.65502 6.97303 5.26318 6.57937 5.26318 6.09301C5.26318 5.60666 5.65454 5.213 6.13744 5.213C6.62032 5.213 7.0112 5.60666 7.0112 6.09301Z" fill="#02171D" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_179_5157">
          <rect width="14" height="14" fill="white" transform="translate(0 0.676208)" />
        </clipPath>
      </defs>
    </svg>

  );
}
export function CallPhoneIcon() {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1309 11.8323L12.3839 10.0853C11.7599 9.46133 10.6992 9.71093 10.4496 10.522C10.2624 11.0836 9.63848 11.3956 9.07692 11.2708C7.82902 10.9588 6.14436 9.33654 5.83238 8.02624C5.6452 7.46466 6.01957 6.84071 6.58112 6.65355C7.39226 6.40397 7.64184 5.34325 7.01789 4.7193L5.27083 2.97224C4.77167 2.53548 4.02293 2.53548 3.58616 2.97224L2.40066 4.15775C1.21515 5.40565 2.52545 8.71259 5.45801 11.6452C8.39058 14.5777 11.6975 15.9504 12.9454 14.7025L14.1309 13.517C14.5677 13.0178 14.5677 12.2691 14.1309 11.8323Z"
        fill="#02171D"
      />
    </svg>
  );
}
export function WorldIcon() {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9042 10.6085C10.9999 9.92868 11.0474 9.24292 11.0463 8.55637C11.0474 7.86982 10.9999 7.18406 10.9042 6.50421H13.592C14.067 7.83109 14.067 9.28165 13.592 10.6085H10.9042ZM7.88231 14.6214C7.29002 14.6214 6.68542 14.0135 6.22177 12.9537C6.00997 12.4697 5.8375 11.918 5.70838 11.319H10.0562C9.92712 11.9177 9.75465 12.4695 9.54285 12.9537C9.0792 14.0135 8.4746 14.6214 7.88231 14.6214ZM5.57855 10.6085C5.37891 9.24771 5.37891 7.86504 5.57855 6.50421L10.1861 6.50421C10.287 7.18358 10.3371 7.86954 10.3358 8.55637C10.3371 9.2432 10.287 9.92916 10.1861 10.6085H5.57855ZM2.17266 10.6085C1.69765 9.28165 1.69765 7.83109 2.17266 6.50421L4.86046 6.50421C4.67061 7.86573 4.67061 9.24701 4.86046 10.6085L2.17266 10.6085ZM7.88231 2.49135C8.4746 2.49135 9.0792 3.09904 9.54285 4.15899C9.75465 4.64301 9.92712 5.19478 10.0562 5.7937H5.70838C5.8375 5.19502 6.00997 4.64325 6.22177 4.15899C6.68542 3.09904 7.29002 2.49135 7.88231 2.49135ZM13.284 5.79347H10.7817C10.6395 5.08983 10.4419 4.44045 10.1941 3.87446C9.99346 3.41532 9.76602 3.02654 9.51702 2.71334C11.1495 3.16543 12.5166 4.28328 13.284 5.79347ZM6.2476 2.7131C5.99931 3.02654 5.77211 3.41508 5.5712 3.87398C5.32339 4.44045 5.12604 5.08983 4.98366 5.79299H2.48065C3.24815 4.28298 4.6152 3.16527 6.2476 2.7131ZM2.48065 11.3193H4.98294C5.12509 12.0229 5.32268 12.6723 5.57049 13.2383C5.7714 13.6972 5.9986 14.0857 6.24689 14.3992C4.61469 13.9469 3.24788 12.8292 2.48065 11.3193ZM9.51702 14.3992C9.76531 14.0857 9.99275 13.6972 10.1934 13.2383C10.4412 12.6718 10.6386 12.0224 10.781 11.3193H13.284C12.5166 12.8295 11.1495 13.947 9.51702 14.3992Z"
        fill="#02171D"
      />
    </svg>
  );
}

export function GreenEye() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13.842"
        height="9.228"
        viewBox="0 0 13.842 9.228"
      >
        <path
          id="Icon_awesome-eye"
          data-name="Icon awesome-eye"
          d="M13.758,8.763A7.708,7.708,0,0,0,6.921,4.5,7.709,7.709,0,0,0,.084,8.763a.777.777,0,0,0,0,.7,7.708,7.708,0,0,0,6.837,4.263,7.709,7.709,0,0,0,6.837-4.263A.777.777,0,0,0,13.758,8.763ZM6.921,12.574a3.46,3.46,0,1,1,3.46-3.46A3.46,3.46,0,0,1,6.921,12.574Zm0-5.767a2.29,2.29,0,0,0-.608.091A1.15,1.15,0,0,1,4.705,8.506a2.3,2.3,0,1,0,2.216-1.7Z"
          transform="translate(0 -4.5)"
          fill="#00a79e"
        />
      </svg>
    </>
  );
}

export function DownArrow() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="9.717"
        height="5.555"
        viewBox="0 0 9.717 5.555"
      >
        <path
          id="Icon_ionic-ios-arrow-back"
          data-name="Icon ionic-ios-arrow-back"
          d="M15.131,11.051,11.455,7.377a.694.694,0,0,1,.984-.981L16.6,10.559a.693.693,0,0,1,.02.958l-4.183,4.192a.694.694,0,0,1-.984-.981Z"
          transform="translate(15.911 -11.251) rotate(90)"
          fill="#80838e"
        />
      </svg>
    </>
  );
}

export function RightArrow() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="5.555"
        height="9.717"
        viewBox="0 0 5.555 9.717"
      >
        <path
          id="Icon_ionic-ios-arrow-back"
          data-name="Icon ionic-ios-arrow-back"
          d="M15.131,11.051,11.455,7.377a.694.694,0,0,1,.984-.981L16.6,10.559a.693.693,0,0,1,.02.958l-4.183,4.192a.694.694,0,0,1-.984-.981Z"
          transform="translate(-11.251 -6.194)"
          fill="#80838e"
        />
      </svg>
    </>
  );
}
export function Btc() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 23 23"
      >
        <g
          id="Group_106717"
          data-name="Group 106717"
          transform="translate(3841 3861)"
        >
          <path
            id="Path_49323"
            data-name="Path 49323"
            d="M11.5,0A11.5,11.5,0,1,1,0,11.5,11.5,11.5,0,0,1,11.5,0Z"
            transform="translate(-3841 -3861)"
            fill="#f7931a"
            fillRule="evenodd"
          />
          <path
            id="Path_49324"
            data-name="Path 49324"
            d="M1006.1,718.424c.222-1.483-.907-2.28-2.451-2.812l.5-2.009-1.223-.3-.488,1.956c-.322-.08-.652-.156-.98-.231l.491-1.969-1.222-.3-.5,2.008c-.266-.061-.527-.12-.781-.183v-.006l-1.686-.421-.325,1.306s.907.208.888.221a.651.651,0,0,1,.57.711l-.57,2.289a.994.994,0,0,1,.127.041l-.129-.032-.8,3.206a.445.445,0,0,1-.56.29c.012.018-.889-.222-.889-.222l-.607,1.4,1.591.4c.3.074.586.152.872.225l-.506,2.032,1.221.3.5-2.01c.334.091.657.174.975.253l-.5,2,1.223.3.506-2.028c2.085.395,3.653.236,4.313-1.651a2.146,2.146,0,0,0-1.123-2.966,1.95,1.95,0,0,0,1.561-1.8h0Zm-2.794,3.918c-.378,1.519-2.935.7-3.763.492l.671-2.692c.829.207,3.487.616,3.092,2.2Zm.378-3.94c-.345,1.381-2.473.68-3.163.507l.609-2.441c.69.172,2.913.493,2.554,1.934Z"
            transform="translate(-4830.197 -4569.046)"
            fill="#fff"
          />
        </g>
      </svg>
    </>
  );
}
export function BackIcon() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="8"
        height="14"
        viewBox="0 0 8 14"
        fill="none"
      >
        <path
          d="M7.63268 11.7857L7.63265 11.7856L3.3459 7.49963C3.3459 7.49962 3.34589 7.49962 3.34589 7.49961C3.22797 7.38165 3.16173 7.22169 3.16173 7.0549C3.16173 6.8881 3.22798 6.72813 3.3459 6.61016L7.63134 2.32473C7.71021 2.24838 7.77315 2.15713 7.81647 2.05626C7.85992 1.95511 7.88279 1.84632 7.88375 1.73625C7.88471 1.62617 7.86373 1.517 7.82205 1.41511C7.78036 1.31323 7.7188 1.22066 7.64096 1.14282C7.56312 1.06498 7.47056 1.00342 7.36867 0.961736C7.26678 0.920051 7.15762 0.899075 7.04754 0.900031C6.93746 0.900988 6.82867 0.923858 6.72752 0.967307C6.62665 1.01064 6.53539 1.07357 6.45904 1.15246L1.14274 6.46875L1.14273 6.46876C0.987311 6.62423 0.9 6.83506 0.9 7.0549C0.9 7.27473 0.987311 7.48556 1.14273 7.64103L1.14274 7.64104L6.45961 12.9579C6.45962 12.9579 6.45963 12.9579 6.45964 12.9579C6.61509 13.1135 6.82595 13.2009 7.04585 13.201C7.26577 13.2011 7.47671 13.1138 7.63226 12.9583C7.78782 12.8029 7.87526 12.592 7.87534 12.3721C7.87541 12.1522 7.78813 11.9412 7.63268 11.7857Z"
          fill="#00A79E"
          stroke="#00A79E"
          stroke-width="0.2"
        />
      </svg>
    </>
  );
}
export function SyncIcon() {
  return (
    <>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="12.000000pt"
        height="12.000000pt"
        viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill="#00a79e;"
          stroke="00a79e"
        >
          <path
            d="M2358 4949 c-740 -61 -1431 -480 -1825 -1108 l-53 -84 0 241 0 242
-160 0 -160 0 0 -520 0 -520 520 0 520 0 0 160 0 160 -240 0 c-132 0 -240 4
-240 8 0 17 125 212 193 302 383 504 1011 811 1654 810 547 -2 1081 -225 1468
-615 281 -282 480 -653 560 -1043 25 -121 45 -288 45 -373 l0 -49 161 0 162 0
-7 118 c-21 347 -94 628 -246 937 -237 484 -603 852 -1085 1090 -177 87 -298
133 -461 174 -284 72 -531 93 -806 70z"
          />
          <path
            d="M164 2445 c19 -343 98 -645 247 -945 314 -633 874 -1088 1550 -1259
234 -59 360 -75 599 -75 207 1 294 9 475 45 639 128 1208 520 1552 1068 l53
84 0 -241 0 -242 160 0 160 0 0 520 0 520 -520 0 -520 0 0 -160 0 -160 240 0
c132 0 240 -4 240 -8 0 -17 -124 -211 -192 -300 -427 -562 -1155 -876 -1856
-802 -824 88 -1509 639 -1766 1420 -63 190 -106 437 -106 601 l0 49 -162 0
-161 0 7 -115z"
          />
        </g>
      </svg>
    </>
  );
}
export function AheadIcon() {
  return (
    <>
      <svg
        width="8"
        height="14"
        viewBox="0 0 8 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.15052 11.7857L1.15055 11.7856L5.4373 7.49963C5.43731 7.49962 5.43731 7.49962 5.43732 7.49961C5.55523 7.38165 5.62148 7.22169 5.62148 7.0549C5.62148 6.8881 5.55523 6.72813 5.4373 6.61016L1.15187 2.32473C1.07299 2.24838 1.01006 2.15713 0.966728 2.05626C0.923278 1.95511 0.90041 1.84632 0.899453 1.73625C0.898496 1.62617 0.919472 1.517 0.961157 1.41511C1.00284 1.31323 1.0644 1.22066 1.14224 1.14282C1.22008 1.06498 1.31265 1.00342 1.41453 0.961736C1.51642 0.920051 1.62559 0.899075 1.73567 0.900031C1.84575 0.900988 1.95453 0.923858 2.05568 0.967307C2.15655 1.01064 2.24781 1.07357 2.32416 1.15246L7.64046 6.46875L7.64047 6.46876C7.79589 6.62423 7.8832 6.83506 7.8832 7.0549C7.8832 7.27473 7.79589 7.48556 7.64047 7.64103L7.64046 7.64104L2.32359 12.9579C2.32358 12.9579 2.32357 12.9579 2.32356 12.9579C2.16812 13.1135 1.95725 13.2009 1.73735 13.201C1.51744 13.2011 1.3065 13.1138 1.15094 12.9583C0.995381 12.8029 0.907945 12.592 0.907867 12.3721C0.90779 12.1522 0.995075 11.9412 1.15052 11.7857Z"
          fill="#00A79E"
          stroke="#00A79E"
          stroke-width="0.2"
        />
      </svg>
    </>
  );
}
export function FinishIcon() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="108"
        height="95"
        viewBox="0 0 108 95"
        fill="none"
      >
        <path
          d="M81.7037 29.2815C79.745 28.1623 77.2268 28.1623 74.9884 29.2815L59.3195 38.515L48.687 44.3909L33.2979 53.6243C31.3393 54.7435 28.8211 54.7435 26.5826 53.6243L14.5511 46.3495C12.5925 45.2303 11.1935 42.9918 11.1935 40.4736V26.4835C11.1935 24.2451 12.3127 22.0067 14.5511 20.6077L26.5826 13.6126C28.5412 12.4934 31.0595 12.4934 33.2979 13.6126L45.3294 20.8875C47.288 22.0067 48.687 24.2451 48.687 26.7633V35.9968L59.3195 29.8411V20.3279C59.3195 18.0894 58.2003 15.851 55.9619 14.452L33.5777 1.30132C31.6191 0.182112 29.1009 0.182112 26.8624 1.30132L3.91866 14.7318C1.68024 15.851 0.561035 18.0894 0.561035 20.3279V46.6293C0.561035 48.8677 1.68024 51.1061 3.91866 52.5051L26.5826 65.6558C28.5412 66.775 31.0595 66.775 33.2979 65.6558L48.687 56.7021L59.3195 50.5465L74.7086 41.5928C76.6672 40.4736 79.1854 40.4736 81.4239 41.5928L93.4553 48.5879C95.414 49.7071 96.813 51.9455 96.813 54.4637V68.4538C96.813 70.6923 95.6938 72.9307 93.4553 74.3297L81.7037 81.3247C79.745 82.4439 77.2268 82.4439 74.9884 81.3247L62.9569 74.3297C60.9983 73.2105 59.5993 70.9721 59.5993 68.4538V59.5002L48.9668 65.6558V74.8893C48.9668 77.1277 50.086 79.3661 52.3244 80.7651L74.9884 93.9158C76.947 95.035 79.4652 95.035 81.7037 93.9158L104.368 80.7651C106.326 79.6459 107.725 77.4075 107.725 74.8893V48.3081C107.725 46.0697 106.606 43.8312 104.368 42.4322L81.7037 29.2815Z"
          fill="white"
        />
      </svg>
    </>
  );
}
export function TokenIcon() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M9.99976 0.000488281C4.47806 0.000488281 -0.00702108 4.48467 8.25135e-06 10.0013C0.00139282 11.105 0.177392 12.1667 0.507717 13.1598H3.49987V4.74661L9.99976 11.2465L16.4993 4.74661V13.16H19.4921C19.8229 12.1669 19.998 11.1052 20 10.0015C20.0094 4.47907 15.522 0.00181959 9.99976 0.00181959V0.000488281Z"
          fill="url(#paint0_linear_77_488)"
        />
        <path
          d="M8.50487 12.7408L5.66827 9.90405V15.1981H3.49956L1.45312 15.1985C3.20849 18.0784 6.38084 20.0048 9.99967 20.0048C13.6185 20.0048 16.791 18.0779 18.5467 15.198H14.3304V9.90405L11.4937 12.7408L9.99935 14.2351L8.50498 12.7408H8.50487Z"
          fill="url(#paint1_linear_77_488)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_77_488"
            x1="10"
            y1="0.000488281"
            x2="10"
            y2="13.16"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#E7804D" />
            <stop offset="1" stop-color="#514E4D" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_77_488"
            x1="9.99991"
            y1="9.90405"
            x2="9.99991"
            y2="20.0048"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#E7804D" />
            <stop offset="1" stop-color="#514E4D" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
}
export function Trc() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 44 44"
      >
        <g
          id="Group_106681"
          data-name="Group 106681"
          transform="translate(-22 -668)"
        >
          <path
            id="Path_49474"
            data-name="Path 49474"
            d="M22,0A22,22,0,1,1,0,22,22,22,0,0,1,22,0Z"
            transform="translate(22 668)"
            fill="#ff060a"
            fillRule="evenodd"
          />
          <g id="tron" transform="translate(31.35 680)">
            <path
              id="Path_49472"
              data-name="Path 49472"
              d="M24.05,7.23C22.925,6.191,21.369,4.6,20.1,3.48l-.075-.053a1.433,1.433,0,0,0-.416-.233h0C16.554,2.625,2.33-.034,2.053,0a.525.525,0,0,0-.218.083L1.764.138a.836.836,0,0,0-.2.315L1.55.5V.809c1.6,4.459,7.924,19.065,9.169,22.493.075.233.218.675.484.7h.06c.143,0,.75-.8.75-.8s10.86-13.17,11.959-14.573a3.547,3.547,0,0,0,.375-.555A.9.9,0,0,0,24.05,7.23ZM14.8,8.763,19.434,4.92l2.719,2.505ZM13,8.512l-7.98-6.54L17.93,4.353Zm.72,1.714L21.887,8.91,12.549,20.16Zm-9.784-7.6,8.4,7.125L11.116,20.167Z"
              transform="translate(0 0)"
              fill="#fff"
            />
          </g>
        </g>
      </svg>
    </>
  );
}
export function Swap() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="52.492"
        height="54.07"
        viewBox="0 0 52.492 54.07"
      >
        <g
          id="Group_150028"
          data-name="Group 150028"
          transform="translate(-1.374)"
        >
          <path
            id="Path_102617"
            data-name="Path 102617"
            d="M53.156,3.156a1.147,1.147,0,0,0-1.253.249l-4.78,4.78A26.7,26.7,0,0,0,27.828,0,26.969,26.969,0,0,0,1.7,20.258a1.15,1.15,0,0,0,1.113,1.436h7.322a1.149,1.149,0,0,0,1.079-.755,17.642,17.642,0,0,1,16.6-11.651,17.5,17.5,0,0,1,12.742,5.46L35.706,19.6a1.15,1.15,0,0,0,.813,1.962h16.2a1.15,1.15,0,0,0,1.15-1.15V4.218a1.151,1.151,0,0,0-.71-1.062Zm-.732,29.22H45.1a1.149,1.149,0,0,0-1.079.754,17.642,17.642,0,0,1-16.6,11.651,17.5,17.5,0,0,1-12.742-5.46l4.853-4.853A1.15,1.15,0,0,0,18.72,32.5H2.524a1.15,1.15,0,0,0-1.15,1.15v16.2a1.15,1.15,0,0,0,1.962.812l4.78-4.779a26.7,26.7,0,0,0,19.295,8.185A26.969,26.969,0,0,0,53.538,33.813a1.148,1.148,0,0,0-1.113-1.437Z"
            transform="translate(0)"
            fill="#00a79e"
          />
        </g>
      </svg>
    </>
  );
}
export function Rewarded() {
  return (
    <>
      <svg
        id="Group_152564"
        data-name="Group 152564"
        xmlns="http://www.w3.org/2000/svg"
        width="24.552"
        height="28.244"
        viewBox="0 0 24.552 28.244"
      >
        <path
          id="Path_103187"
          data-name="Path 103187"
          d="M11.387,15.822h-.855v-1.7a.5.5,0,0,0-1.009,0v1.7H8.669A1.012,1.012,0,0,0,7.66,16.831v12.2A1.009,1.009,0,0,0,8.669,30.04h.855v1.7a.5.5,0,0,0,1.009,0v-1.7h.855A1.009,1.009,0,0,0,12.4,29.032v-12.2a1.012,1.012,0,0,0-1.009-1.009Zm6.6-6.254h-.851v-1.7a.5.5,0,0,0-1.009,0v1.7h-.853a1.012,1.012,0,0,0-1.009,1.009v12.2a1.009,1.009,0,0,0,1.009,1.009h.853v1.7a.5.5,0,0,0,1.009,0v-1.7h.851A1.005,1.005,0,0,0,19,22.777v-12.2a1.009,1.009,0,0,0-1.009-1.009ZM24.6,13.084h-.853v-1.7a.5.5,0,0,0-1.009,0v1.7h-.851a1.009,1.009,0,0,0-1.009,1.009v12.2A1.009,1.009,0,0,0,21.883,27.3h.851V29a.5.5,0,1,0,1.009,0V27.3H24.6a1.012,1.012,0,0,0,1.009-1.009v-12.2A1.012,1.012,0,0,0,24.6,13.084ZM31.2,6.2h-.855V4.5a.5.5,0,1,0-1.009,0V6.2h-.855a1.009,1.009,0,0,0-1.009,1.009v12.2a1.012,1.012,0,0,0,1.009,1.009h.855v1.7a.5.5,0,0,0,1.009,0v-1.7H31.2a1.012,1.012,0,0,0,1.009-1.009V7.213A1.009,1.009,0,0,0,31.2,6.2Z"
          transform="translate(-7.66 -4)"
          fill="#fff"
        />
      </svg>
    </>
  );
}

export function ScrollLeft() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="5.823"
      height="10.184"
      viewBox="0 0 5.823 10.184"
    >
      <path
        id="Icon_ionic-ios-arrow-forward"
        data-name="Icon ionic-ios-arrow-forward"
        d="M4.068,5.094.214,8.945a.725.725,0,0,0,0,1.028.734.734,0,0,0,1.031,0L5.611,5.61a.726.726,0,0,0,.021-1L1.248.212A.728.728,0,0,0,.217,1.24Z"
        fill="#848e9c"
      />
    </svg>
  );
}


export function YoutubeIcon() {
  return (
    <svg width="14" height="14" viewBox="0 0 33 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M31.8659 4.40293C31.6805 3.72823 31.3268 3.11352 30.8391 2.6182C30.3515 2.12289 29.7462 1.76373 29.0819 1.57543C26.5885 0.896992 16.5539 0.896992 16.5539 0.896992C16.5539 0.896992 6.54455 0.88345 4.02588 1.57543C3.36156 1.76373 2.75631 2.12289 2.26861 2.6182C1.78092 3.11352 1.42728 3.72823 1.24188 4.40293C0.769242 6.99549 0.536229 9.62725 0.545881 12.2639C0.538053 14.8905 0.771059 17.5122 1.24188 20.095C1.42728 20.7697 1.78092 21.3844 2.26861 21.8797C2.75631 22.375 3.36156 22.7342 4.02588 22.9225C6.51655 23.6023 16.5539 23.6023 16.5539 23.6023C16.5539 23.6023 26.5619 23.6023 29.0819 22.9225C29.7462 22.7342 30.3515 22.375 30.8391 21.8797C31.3268 21.3844 31.6805 20.7697 31.8659 20.095C32.3269 17.5113 32.5501 14.8896 32.5326 12.2639C32.5519 9.62818 32.3287 6.99643 31.8659 4.40293ZM13.3512 17.1267V7.38616L21.7032 12.2639L13.3512 17.1267Z" fill="white" fill-opacity="0.9" />
    </svg>

  );
}

export function MoonIcon() {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" data-locator-target="vscode://file/${projectPath}${filePath}:${line}:${column}">
      <mask id="path-1-inside-1_158_52312" fill="white">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.7596 0.743164C3.15377 1.19669 1.78276 2.39171 1.17307 4.08098C0.168832 6.86342 1.61035 9.93313 4.3928 10.9374C7.08598 11.9094 10.0483 10.59 11.1462 7.98123C10.1071 8.27472 8.96956 8.25769 7.87639 7.86315C5.09395 6.85891 3.65243 3.78921 4.65666 1.00676C4.68887 0.917505 4.72322 0.829626 4.7596 0.743164Z" />
      </mask>
      <path d="M1.17307 4.08098L-0.100904 3.62118L1.17307 4.08098ZM4.7596 0.743164L6.00796 1.26854L7.0995 -1.32506L4.39149 -0.560256L4.7596 0.743164ZM4.3928 10.9374L4.8526 9.66339H4.85259L4.3928 10.9374ZM11.1462 7.98123L12.3946 8.50662L13.4861 5.91301L10.7781 6.67781L11.1462 7.98123ZM7.87639 7.86315L8.33619 6.58918L8.33619 6.58918L7.87639 7.86315ZM4.65666 1.00676L3.38269 0.546964L3.38269 0.546965L4.65666 1.00676ZM2.44704 4.54078C2.9022 3.27966 3.92413 2.38651 5.12772 2.04658L4.39149 -0.560256C2.38341 0.00686955 0.663316 1.50375 -0.100904 3.62118L2.44704 4.54078ZM4.85259 9.66339C2.77375 8.9131 1.69674 6.61963 2.44704 4.54078L-0.100904 3.62118C-1.35908 7.10722 0.446962 10.9532 3.933 12.2113L4.85259 9.66339ZM9.89788 7.45585C9.07802 9.40392 6.86436 10.3895 4.8526 9.66339L3.933 12.2113C7.3076 13.4293 11.0186 11.7762 12.3946 8.50662L9.89788 7.45585ZM10.7781 6.67781C10.0022 6.89695 9.15454 6.88453 8.33619 6.58918L7.41659 9.13712C8.78457 9.63084 10.2119 9.65248 11.5143 9.28466L10.7781 6.67781ZM8.33619 6.58918C6.25734 5.83888 5.18034 3.54541 5.93063 1.46656L3.38269 0.546965C2.12452 4.033 3.93056 7.87894 7.41659 9.13712L8.33619 6.58918ZM5.93063 1.46656C5.95487 1.3994 5.98067 1.33338 6.00796 1.26854L3.51125 0.217784C3.46576 0.325869 3.42288 0.435615 3.38269 0.546964L5.93063 1.46656Z" fill="#D1DBDF" mask="url(#path-1-inside-1_158_52312)" />
    </svg>
  )
}
export function SunIcon() {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" data-locator-target="vscode://file/${projectPath}${filePath}:${line}:${column}">
      <circle cx="5.91193" cy="6.01837" r="2.06791" stroke="#02171D" stroke-width="1.43218" />
      <path d="M6.82129 10.1357L6.84222 11.8165L5.17835 11.8372L5.15742 10.1565L6.82129 10.1357Z" fill="#02171D" />
      <path d="M6.82129 0.162109L6.84222 1.84283L5.17835 1.86356L5.15742 0.182835L6.82129 0.162109Z" fill="#02171D" />
      <path d="M1.86377 6.82129L0.183045 6.84222L0.16232 5.17835L1.84304 5.15742L1.86377 6.82129Z" fill="#02171D" />
      <path d="M11.8374 6.82129L10.1567 6.84222L10.136 5.17835L11.8167 5.15742L11.8374 6.82129Z" fill="#02171D" />
      <path d="M2.49463 3.65625L1.29137 2.4826L2.45325 1.29141L3.65651 2.46506L2.49463 3.65625Z" fill="#02171D" />
      <path d="M9.54639 10.708L8.34313 9.53436L9.50501 8.34317L10.7083 9.51682L9.54639 10.708Z" fill="#02171D" />
      <path d="M8.34326 2.49414L9.51691 1.29089L10.7081 2.45276L9.53445 3.65602L8.34326 2.49414Z" fill="#02171D" />
      <path d="M1.29199 9.5459L2.46564 8.34264L3.65683 9.50452L2.48318 10.7078L1.29199 9.5459Z" fill="#02171D" />
    </svg>
  )
}

export function DarkGIcon() {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.7002 26.7087L24.7002 10.9906L27.7729 10.9906L27.7729 26.7087L24.7002 26.7087Z" fill="#02171D" />
      <path d="M1.77246 23.636L27.7724 23.636V26.7087L1.77246 26.7087L1.77246 23.636Z" fill="#02171D" />
      <path d="M4.84473 10.9902L4.84473 26.7084L1.772 26.7084L1.772 10.9902L4.84473 10.9902Z" fill="#02171D" />
      <path d="M2.77711 4.01807L28.5998 4.01807L26.8862 7.09079H1.06348L2.77711 4.01807Z" fill="#02171D" />
      <path d="M10.3416 10.9902L27.7734 10.9902L26.0597 14.063H8.62793L10.3416 10.9902Z" fill="#02171D" />
      <rect x="19.0273" y="17.9631" width="3.1909" height="3.07272" fill="#02171D" />
    </svg>

  )
}
export function LightGIcon() {
  return (
    <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.7261 27.3801L24.7261 11.662L27.7988 11.662L27.7988 27.3801L24.7261 27.3801Z" fill="#D1DADD" />
      <path d="M1.79907 24.3073L27.799 24.3073V27.38L1.79907 27.38L1.79907 24.3073Z" fill="#D1DADD" />
      <path d="M4.87158 11.6617L4.87158 27.3799L1.79886 27.3799L1.79886 11.6617L4.87158 11.6617Z" fill="#D1DADD" />
      <path d="M2.80323 4.68945L28.6259 4.68945L26.9123 7.76218H1.0896L2.80323 4.68945Z" fill="#D1DADD" />
      <path d="M10.3679 11.6617L27.7997 11.6617L26.0861 14.7345H8.6543L10.3679 11.6617Z" fill="#D1DADD" />
      <rect x="19.054" y="18.6345" width="3.1909" height="3.07272" fill="#D1DADD" />
    </svg>

  )
}