import { createSlice } from "@reduxjs/toolkit";
import {
  getAnnouncement,
  getNotifications,
  getNotificationsCount,
  getUserNotification,
} from "../../services/notificationService";
export const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    loading: false,
    list: [],
    total: 0,
    listUnreadCount: 0,
    notiList: [],
    userNotificationList: [],
    userNotificationListTotal: 0,
    userNotificationUnreadCount: 0,
    selectedTab: "deposit",
    error: false,
    readNotiId: {},
    announceTotal: 0,
    announceList: [],
    notificationCount: {},
  },
  reducers: {
    setSelectedNotificationTab: (state, action) => {
      state.selectedTab = action.payload;
    },

    appendNewMessage: (state, action) => {
      state.notiList = [...state.notiList, action.payload];
      state.list = [action.payload, ...state.list];
    },

    insertNotificationList: (state, action) => {
      state.notiList = [action.payload, ...state.notiList];
      let aboutCheck = state.notiList[0].about;
      if (
        aboutCheck === "cancelled" ||
        aboutCheck === "unresolved" ||
        aboutCheck === "resolved" ||
        (state.notiList[0]?.notifiable_type === "Appeal" &&
          state.notiList[0]?.event !== "admin_message" &&
          state.notiList[0]?.event !== "admin_action")
      ) {
      } else {
        state.list = [action.payload, ...state.list];
      }
    },

    readNotificationIds: (state, action) => {
      state.readNotiId = action.payload;
    },
    appendCopyBySocket: (state, action) => {
      // state.userNotificationList = [action.payload, ...state?.userNotificationList];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotifications.pending, (state) => {
        state.error = false;
        state.list = [];
        state.total = 0;
        state.loading = true;
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload.list;
        state.total = action.payload.total;
        state.listUnreadCount = action.payload?.list?.unread_count || 0;
      })
      .addCase(getNotifications.rejected, (state) => {
        state.error = true;
        state.loading = false;

      })
      .addCase(getUserNotification.pending, (state, { meta }) => {
        state.userNotificationList = [];
        state.loading = true;
      })
      .addCase(getUserNotification.fulfilled, (state, action) => {
        state.userNotificationList = action.payload.list;
        state.loading = false;
        state.userNotificationListTotal = action.payload.total;
        state.userNotificationUnreadCount =
          action.payload?.list[0]?.unread_count || 0;
      })
      .addCase(getUserNotification.rejected, (state) => {
        state.userNotificationList = [];
        state.loading = false;

      })
      .addCase(getAnnouncement.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAnnouncement.fulfilled, (state, action) => {
        state.announceList = action.payload?.data;
        state.announceTotal = action?.payload?.headers?.total || 0;
        state.loading = false;
      })
      .addCase(getAnnouncement.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getNotificationsCount.pending, (state) => {
        state.notificationCount = {};
        state.loading = true;
      })
      .addCase(getNotificationsCount.fulfilled, (state, action) => {
        state.notificationCount = action.payload.data;
        state.loading = false;
      })
      .addCase(getNotificationsCount.rejected, (state) => {
        state.loading = false;
      });
  },
});
export default notificationSlice.reducer;
export const {
  insertNotificationList,
  appendNewMessage,
  readNotificationIds,
  setSelectedNotificationTab,
  appendCopyBySocket
} = notificationSlice.actions;
