import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import * as config from "../../utils/apiVersion";
import {
  resetFormData,
  showAlert,
  startLoading,
  stopLoading,
} from "../feature";
import { errorToastSelector, successToastSelector } from "../selector";
import { buildQueryString } from "../../helpers";
import { toast } from "react-toastify";

export const launchpadCoinListings = createAsyncThunk(
  "launchpad/launchpadCoinListings",
  async (payload, { dispatch }) => {
    let url;
    if (payload) {
      url = `public/coin_listings/listing?${buildQueryString(payload)}`;
    } else {
      url = `public/coin_listings/listing`;
    }
    try {
      dispatch(startLoading());
      const res = await API.get(config.peatioWithHeader)(url);
      dispatch(stopLoading());
      return { data: res.data, total: res.headers.total, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const getBlockchainNetworks = createAsyncThunk(
  "launchpad/getBlockchainNetworks",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `public/blockchain/networks`;
      const res = await API.get(config.peatioWithHeader)(url);
      dispatch(stopLoading());
      return { data: res.data, total: res.headers.total, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const getTokenNames = createAsyncThunk(
  "launchpad/getTokenNames",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `listing/coin_listings/launchpad/currency_ids`;
      const res = await API.get(config.peatioWithHeader)(url);
      dispatch(stopLoading());
      return { data: res.data, total: res.headers.total, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const selectedLaunchpadCoinListings = createAsyncThunk(
  "launchpad/selectedLaunchpadCoinListings",
  async (payload, { dispatch }) => {
    let url;
    if (payload.reload) {
      url = `listing/coin_listings/${payload.id}?${buildQueryString(payload)}`;
    } else {
      url = `listing/coin_listings/${payload.id}`;
    }
    try {
      dispatch(startLoading());
      const res = await API.get(config.peatio)(url);
      dispatch(stopLoading());
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      if (e?.code === 422) {
        setTimeout(() => {
          window.location.href = `${window.location.origin}/listing`;
        }, 1000);
      }
      return false;
    }
  }
);

export const getLaunchPadHistory = createAsyncThunk(
  "launchpad/getLaunchPadHistory",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `listing/coin_listings/launchpad/launchpad_history`;
      const res = await API.get(config.peatioWithHeader)(url);
      dispatch(stopLoading());
      return { data: res.data, total: res.headers.total, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const getLaunchPadBidHistory = createAsyncThunk(
  "launchpad/getLaunchPadBidHistory",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `listing/coin_listings/launchpad/bid_history?${buildQueryString(
        payload
      )}`;
      const res = await API.get(config.peatioWithHeader)(url);
      dispatch(stopLoading());
      return { data: res.data, total: res.headers.total, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const coinListingStake = createAsyncThunk(
  "launchpad/coinListingStakeData",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `public/coin_listings/stake/stake_data`;
      const res = await API.get(config.peatio)(url);
      dispatch(stopLoading());
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const postCommitMntFormData = createAsyncThunk(
  "launchpad/postCommitMntFormData",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/listing/coin_bids`;
      const res = await API.post(config.peatio)(url, payload);
      if (res) {
        dispatch(successToastSelector({ message: "Commited  successfully " }));
      }
      dispatch(stopLoading());
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      if (e?.code === 422) {
        setTimeout(() => {
          // window.location.href = `${window.location.origin}/listing`;
        }, 1000);
      }
      return { status: false };
    }
  }
);
export const postApplicationFormData = createAsyncThunk(
  "launchpad/postApplicationFormData",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/listing/coin_listings`;
      const res = await API.post(config.peatio)(url, payload?.data);
      dispatch(resetFormData());
      dispatch(
        successToastSelector({ message: "Form submitted successfully" })
      );
      dispatch(stopLoading());
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);
export const putApplicationFormData = createAsyncThunk(
  "launchpad/putApplicationFormData",
  async ({ data, id }, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/listing/coin_listings/${id}`;
      const res = await API.put(config.peatio)(url, data);
      dispatch(stopLoading());
      return { status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);
// listing/coin_listings/launchpad/listing
export const getLaunchPadTransBidHistory = createAsyncThunk(
  "launchpad/getLaunchPadTransBidHistory",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `listing/coin_listings/launchpad/listing`;
      const res = await API.get(config.peatioWithHeader)(url);
      dispatch(stopLoading());
      return { data: res.data, total: res.headers.total, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);




export const postListingFormData = createAsyncThunk(
  "launchpad/postListingFormData",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/public/listing_forms`;
      const res = await API.post(config.peatio)(url, payload);
      dispatch(resetFormData());
      dispatch(
        successToastSelector({ message: "Form submitted successfully" })
      );
      dispatch(stopLoading());
      return {  status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return {  status: false };
    }
  }
);

// export const getDemoSession = createAsyncThunk(
//     "demoSite/getDemoSite",
//     async (payload, { dispatch }) => {
//       try {
//         dispatch(startLoading());
//         let url = `/api/v2/barong/identity/users/demo_account/session?${buildQueryString(payload)}`;
//         const res = await API.post(config.demoSite)(url);
//         dispatch(stopLoading());
//         return { status: true };
//       } catch (e) {
//         dispatch(stopLoading());
//         dispatch(errorToastSelector(e));
//         return false;
//       }
//     }
//   );
