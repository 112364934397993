import { createSlice } from "@reduxjs/toolkit";
import {
  getParentUid,
  getredeemReward,
  getreferredMembers,
  getrewardData,
  getrewardHistory,
  getrewardSettings,
  rebatePercentage,
} from "../../services";

export const referralSlices = createSlice({
  name: "referral",
  initialState: {
    error: false,
    rewardData: {},
    rewardHistory: [],
    rewardHistoryTotal: 0,
    referredMembers: [],
    referredMembersTotal: 0,
    rewardSettings: [],
    parentid: "",
    convertAmount: "",
    redeemTab : "own",
    referralTab:  "rewards"
  },
  reducers: {

    setredeemModaltab: (state, action) => {
      state.redeemTab = action?.payload;
    },

    setreferralTab: (state, action) => {
      state.referralTab = action?.payload;
    },

    setConvertedAmount: (state, action) => {
      state.convertAmount = "";
    }

  },
  extraReducers: (builder) => {
    builder
      //getrewardData

      .addCase(getrewardData.pending, (state) => {})
      .addCase(getrewardData.fulfilled, (state, action) => {
        state.rewardData = action.payload.data;
        state.convertAmount = ""
      })
      .addCase(getrewardData.rejected, (state) => {})

      //getrewardHistory

      .addCase(getrewardHistory.pending, (state) => {})
      .addCase(getrewardHistory.fulfilled, (state, action) => {
        state.rewardHistory = action.payload.list;
        state.rewardHistoryTotal = action.payload.total;
      })
      .addCase(getrewardHistory.rejected, (state) => {})

      //parentid
      .addCase(getParentUid.pending, (state) => {})
      .addCase(getParentUid.fulfilled, (state, action) => {
        state.parentid = action.payload.data
      })
      .addCase(getParentUid.rejected, (state) => {})


      //parentid
      .addCase(rebatePercentage.pending, (state) => {
        state.convertAmount = ""
      })
      .addCase(rebatePercentage.fulfilled, (state, action) => {
        state.convertAmount = action.payload.data?.converted_amount
      })
      .addCase(rebatePercentage.rejected, (state) => {})


      //referred_members
      .addCase(getreferredMembers.pending, (state) => {})
      .addCase(getreferredMembers.fulfilled, (state, action) => {
        state.referredMembers = action.payload.list;
        state.referredMembersTotal = action.payload.total
      })
      .addCase(getreferredMembers.rejected, (state) => {})

      // reward_settings
      .addCase(getrewardSettings.pending, (state) => {})
      .addCase(getrewardSettings.fulfilled, (state, action) => {
        state.rewardSettings = action.payload.data
      })
      .addCase(getrewardSettings.rejected, (state) => {});
  },
});
export default referralSlices.reducer;
export const {setredeemModaltab, setreferralTab, setConvertedAmount} = referralSlices.actions;
