import { API_HOST_BASE_URL,REACT_APP_WSS_BASE_URL,APP_ENVIRONMENT,TRANSAKSETTING_API_KEY,CAPTCHA_SITE_KEY,CLOUDCAPTCHA_SITE_KEY,ALIASURL,JUMIOURL,REFID } from "../utils/constant";
export const defaultConfig = {
  title: "Gmes Exchange",
  baseUrl: API_HOST_BASE_URL,
  api: {
    authUrl: `${API_HOST_BASE_URL}/api/v2/barong`,
    tradeUrl: `${API_HOST_BASE_URL}/api/v2/peatio`,
    futureUrl: `${API_HOST_BASE_URL}/api/v2/future`,
    p2pUrl: `${API_HOST_BASE_URL}/api/v2/p2p`,
    invetsUrl: `${API_HOST_BASE_URL}/api/v2/invest`,
    applogicUrl: `${API_HOST_BASE_URL}/api/v2/applogic`,
    rangerUrl: `${REACT_APP_WSS_BASE_URL}/api/v2/ranger`,
    aliasUrl: ALIASURL,
    jumioUrl: JUMIOURL,
    demoUrl: API_HOST_BASE_URL,
  },
  transakSetting:{
    apiKey: TRANSAKSETTING_API_KEY,
    environment: APP_ENVIRONMENT,
  },
  captcha: {
    captchaType: "recaptcha",
    siteKey: CAPTCHA_SITE_KEY,
  },
  cloudCaptcha: {
    captchaType: "turnstile",
    siteKey: CLOUDCAPTCHA_SITE_KEY,
  },
  minutesUntilAutoLogout: "60",
  withCredentials: true,
  refid: REFID,
  defaultStorageLimit: 50,
  tablePageLimit: 50,
  defaultMarket: "ethbtc",
  gaTrackerKey: "changeme",
  infuraUrl: "changeme",
  removealphax: "true",
  chainid: 1,
  vi_public_key: "changeme",
  sp_public_key: "changeme",
  usdt_value: 1,
};

export const Frontend = {
  config: defaultConfig,
};

window.env = defaultConfig;
Frontend.config = { ...window.env };

Frontend.config.captcha = Frontend.config.captcha || defaultConfig.captcha;
Frontend.config.cloudCaptcha =
  Frontend.config.cloudCaptcha || defaultConfig.cloudCaptcha; 

export const baseUrl = () => Frontend.config.baseUrl;
export const demoUrl = () => Frontend.config.api.demoUrl;
export const applogicUrl = () => Frontend.config.api.applogicUrl;
export const authUrl = () => Frontend.config.api.authUrl;
export const tradeUrl = () => Frontend.config.api.tradeUrl;
export const futureUrl = () => Frontend.config.api.futureUrl;
export const invetsUrl = () => Frontend.config.api.invetsUrl;
export const withCredentials = () => Frontend.config.withCredentials;
export const p2pUrl = () => Frontend.config.api.p2pUrl;
export const rangerUrl = () => Frontend.config.api.rangerUrl;
export const siteKey = () => Frontend.config.cloudCaptcha.siteKey;
export const captchaType = () => Frontend.config.captcha.captchaType;
export const cloudCaptchaSiteKey = () => Frontend.config.cloudCaptcha.siteKey;
export const cloudCaptchaCaptchaType = () =>
  Frontend.config.cloudCaptcha.captchaType;
export const usdtValue = () => Frontend.config.usdt_value;
export const transakSetting = () => Frontend.config.transakSetting;
