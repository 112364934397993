export function Homeicon() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.98109 15.065L1.19267 16.6127L1.19267 9.9779L2.98109 9.9779L2.98109 15.065Z" fill="#D1DADD" stroke="#D1DADD" stroke-width="0.0835874" />
      <path d="M2.98109 6.28082L1.19267 7.82848L1.19267 1.19372L2.98109 1.19372L2.98109 6.28082Z" fill="#D1DADD" stroke="#D1DADD" stroke-width="0.0835874" />
      <rect x="2.05693" y="9.97783" width="6.18041" height="1.78841" fill="#D1DADD" stroke="#D1DADD" stroke-width="0.0835874" />
      <rect x="2.05693" y="1.19414" width="6.18041" height="1.78841" fill="#D1DADD" stroke="#D1DADD" stroke-width="0.0835874" />
      <path d="M11.8385 15.065L10.0501 16.6127L10.0501 9.9779L11.8385 9.9779L11.8385 15.065Z" fill="#D1DADD" stroke="#D1DADD" stroke-width="0.0835874" />
      <path d="M11.8385 6.28082L10.0501 7.82848L10.0501 1.19372L11.8385 1.19372L11.8385 6.28082Z" fill="#D1DADD" stroke="#D1DADD" stroke-width="0.0835874" />
      <rect x="10.9144" y="9.97783" width="6.18041" height="1.78841" fill="#D1DADD" stroke="#D1DADD" stroke-width="0.0835874" />
      <rect x="15.2344" y="14.8738" width="1.86041" height="1.78841" fill="#D1DADD" stroke="#D1DADD" stroke-width="0.0835874" />
      <rect x="15.2344" y="6.01787" width="1.86041" height="1.78841" fill="#D1DADD" stroke="#D1DADD" stroke-width="0.0835874" />
      <rect x="6.37846" y="14.8738" width="1.86041" height="1.78841" fill="#D1DADD" stroke="#D1DADD" stroke-width="0.0835874" />
      <rect x="6.37846" y="6.01787" width="1.86041" height="1.78841" fill="#D1DADD" stroke="#D1DADD" stroke-width="0.0835874" />
      <rect x="10.9144" y="1.19414" width="6.18041" height="1.78841" fill="#D1DADD" stroke="#D1DADD" stroke-width="0.0835874" />
    </svg>
  );
}
export function TableIcons() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="7.673" height="10.089" viewBox="0 0 7.673 10.089">
      <g id="Group_150067" data-name="Group 150067" transform="translate(-430.205 -642.456)">
        <path id="Icon_ionic-ios-arrow-back" data-name="Icon ionic-ios-arrow-back" d="M3.064,3.837l-2.9,2.9a.548.548,0,0,0,.777.774L4.227,4.226a.547.547,0,0,0,.016-.756L.94.16A.548.548,0,0,0,.163.934Z" transform="translate(430.205 646.842) rotate(-90)" fill="#392765" />
        <path id="Icon_ionic-ios-arrow-back-2" data-name="Icon ionic-ios-arrow-back" d="M3.064,3.835.161.934A.548.548,0,0,1,.938.16L4.227,3.447a.547.547,0,0,1,.016.756L.94,7.513a.548.548,0,0,1-.777-.774Z" transform="translate(437.878 648.158) rotate(90)" fill="#392765" />
      </g>
    </svg>
  );
}
export function SwapIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11.813" height="13.092" viewBox="0 0 11.813 13.092">
      <g id="Group_150035" data-name="Group 150035" transform="translate(14.662 -1.85) rotate(90)">
        <path
          id="Path_102618"
          data-name="Path 102618"
          d="M14.152,8.756a.6.6,0,0,0-.64.64,1.884,1.884,0,0,1-1.919,1.919H4.175l.831-.831a.633.633,0,0,0-.9-.9L2.192,11.506c-.064.064-.128.128-.128.192a.544.544,0,0,0,0,.512c0,.064.064.128.128.192l1.919,1.919a.618.618,0,0,0,.9,0,.618.618,0,0,0,0-.9l-.831-.831h7.419a3.167,3.167,0,0,0,3.2-3.2A.6.6,0,0,0,14.152,8.756ZM14.728,5.3c0-.064-.064-.128-.128-.192L12.681,3.192a.633.633,0,0,0-.9.9l.831.831H5.2A3.167,3.167,0,0,0,2,8.117a.6.6,0,0,0,.64.64.6.6,0,0,0,.64-.64A1.884,1.884,0,0,1,5.2,6.2h7.419l-.831.831a.633.633,0,0,0,.9.9L14.6,6.006c.064-.064.128-.128.128-.192a.385.385,0,0,0,.064-.256.385.385,0,0,0-.064-.256Z"
          transform="translate(0)"
          fill="#f6fdff"
          stroke="#f6feff"
          stroke-width="0.3"
        />
      </g>
    </svg>
  );
}
export function Gridtrading() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="-2 0.2 20.225 15.483">
      <g id="Group_154281" data-name="Group 154281" transform="translate(-6 -6)">
        <path
          id="Path_106755"
          data-name="Path 106755"
          d="M12.791,15.055a.755.755,0,0,0-.755-.755H6.755A.755.755,0,0,0,6,15.055v5.282a.755.755,0,0,0,.755.755h5.282a.755.755,0,0,0,.755-.755Zm8.3,0a.755.755,0,0,0-.755-.755H15.055a.755.755,0,0,0-.755.755v5.282a.755.755,0,0,0,.755.755h5.282a.755.755,0,0,0,.755-.755Zm-9.809.755v3.773H7.509V15.809Zm8.3,0v3.773H15.809V15.809Zm1.509-9.055A.755.755,0,0,0,20.337,6H15.055a.755.755,0,0,0-.755.755v5.282a.755.755,0,0,0,.755.755h5.282a.755.755,0,0,0,.755-.755Zm-8.3,0A.755.755,0,0,0,12.037,6H6.755A.755.755,0,0,0,6,6.755v5.282a.755.755,0,0,0,.755.755h5.282a.755.755,0,0,0,.755-.755Zm6.791.755v3.773H15.809V7.509Zm-8.3,0v3.773H7.509V7.509Z"
          fill="#fff"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}
export function Copytrading() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="Group_150942" data-name="Group 150942" transform="translate(-68 -147)">
        <g id="Rectangle_10934" data-name="Rectangle 10934" transform="translate(68 147)" fill="#1ea0ab" stroke="#707070" stroke-width="1" opacity="0">
          <rect width="24" height="24" stroke="none" />
          <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
        </g>
        <g id="Group_154282" data-name="Group 154282" transform="translate(68.328 147.501)">
          <path
            id="Path_106756"
            data-name="Path 106756"
            d="M15.437,13.358V5.026a1.793,1.793,0,0,0-1.791-1.791H5.314A1.793,1.793,0,0,0,3.523,5.026v8.332a1.793,1.793,0,0,0,1.791,1.791h8.332A1.793,1.793,0,0,0,15.437,13.358Zm-10.72,0V5.026a.6.6,0,0,1,.6-.6h8.332a.6.6,0,0,1,.6.6v8.332a.6.6,0,0,1-.6.6H5.314A.6.6,0,0,1,4.717,13.358ZM18.03,7.849H16.579a.6.6,0,0,0,0,1.194H18.03a.6.6,0,0,1,.6.6v8.332a.6.6,0,0,1-.6.6H9.7a.6.6,0,0,1-.6-.6v-1.65a.6.6,0,1,0-1.194,0v1.65A1.793,1.793,0,0,0,9.7,19.762H18.03a1.793,1.793,0,0,0,1.791-1.791V9.639A1.793,1.793,0,0,0,18.03,7.849Z"
            transform="translate(0 0)"
            fill="#fff"
            stroke="#fff"
            stroke-width="0.2"
          />
        </g>
      </g>
    </svg>
  );
}

export function Portfolio() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.8474 17.4241L0.863413 17.4241L0.863413 15.5521L16.8474 15.5521L16.8474 17.4241Z" fill="#D1DADD" />
      <path d="M0.864258 17.4241L0.864257 8.92807L2.73626 7.66807L2.73626 17.4241L0.864258 17.4241Z" fill="#D1DADD" />
      <path d="M10.3525 11.0823L9.34066 9.38772L12.0744 4.63736L13.0862 6.33193L10.3525 11.0823Z" fill="#D1DADD" />
      <path d="M6.66274 14.0652L5.65093 12.3706L8.38466 7.62023L9.39647 9.3148L6.66274 14.0652Z" fill="#D1DADD" />
      <path d="M8.95147 1.72803H14.6395L13.5955 3.60003H7.90747L8.95147 1.72803Z" fill="#D1DADD" />
      <path d="M17.4851 6.65398L14.6411 1.72802L13.5419 3.56815L16.3859 8.49411L17.4851 6.65398Z" fill="#D1DADD" />
    </svg>
  );
}
export function P2p() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <g id="Group_150939" data-name="Group 150939" transform="translate(-68 -147)">
        <g id="Rectangle_10934" data-name="Rectangle 10934" transform="translate(68 147)" fill="#1ea0ab" stroke="#707070" stroke-width="1" opacity="0">
          <rect width="24" height="24" stroke="none" />
          <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
        </g>
        <g id="Group_150181" data-name="Group 150181" transform="translate(70.596 151.074)">
          <g id="Group_150940" data-name="Group 150940" transform="translate(0 0)">
            <path
              id="Path_103650"
              data-name="Path 103650"
              d="M12.556,4.358a1.7,1.7,0,0,1-3.223.834,2.312,2.312,0,0,1-.194-1.1,3.209,3.209,0,0,1,.473-2.349,1.678,1.678,0,0,1,2.626.194,4.273,4.273,0,0,1,.318,2.416.605.605,0,0,0,1.21,0,5.266,5.266,0,0,0-.482-3.027A2.9,2.9,0,0,0,9.052.622a3.045,3.045,0,0,0-1.106,2.06A12.01,12.01,0,0,0,7.928,4.02a3.75,3.75,0,0,0,.341,1.745,2.9,2.9,0,0,0,5.5-1.407.605.605,0,0,0-1.21,0"
              transform="translate(1.239 0)"
              fill="#fff"
              stroke="#fff"
              stroke-width="0.5"
            />
            <path
              id="Path_103651"
              data-name="Path 103651"
              d="M6.78,2.851c0,.054,0,.108-.007.161-.006.118,0,.021,0,0a2.1,2.1,0,0,1-.059.252c-.015.049-.032.1-.052.145.009-.023.052-.1,0,0s-.1.175-.155.259c-.081.123.054-.035-.014.016a1.068,1.068,0,0,0-.084.093c-.023.024-.047.047-.072.071s-.143.119-.031.034A1.791,1.791,0,0,1,6.1,4.01c-.029.017-.06.031-.089.047-.062.029-.061.029,0,0l-.072.027a2.258,2.258,0,0,1-.249.069c-.032.007-.148.021-.02.008-.044,0-.089.008-.133.009-.09,0-.178,0-.268-.007s.138.032-.021,0c-.051-.012-.1-.022-.152-.036S5,4.093,4.95,4.077c-.029-.009-.1-.051,0,0C4.879,4.04,4.8,4,4.732,3.958c-.036-.022-.068-.049-.1-.071-.077-.047.094.094-.015-.015-.071-.071-.14-.142-.209-.215.075.079.02.028,0,0-.028-.042-.057-.083-.082-.126s-.035-.059-.05-.088S4.2,3.273,4.25,3.4a1.766,1.766,0,0,1-.079-.244c-.009-.034-.015-.067-.023-.1-.012-.069-.013-.068,0,.006l-.007-.08a2.107,2.107,0,0,1,0-.242c0-.024.029-.192,0-.05.007-.034.013-.068.02-.1.019-.084.046-.165.073-.247.05-.153-.06.1.02-.043.025-.044.046-.089.073-.133s.045-.071.068-.105.091-.11.012-.019c.056-.062.111-.125.172-.182,0,0,.123-.109.053-.05s.06-.042.061-.043a2.027,2.027,0,0,1,.264-.148c-.021.01-.111.039,0,0,.049-.016.1-.034.147-.047l.1-.025s.167-.03.074-.016.077,0,.077,0c.045,0,.09,0,.134,0s.089,0,.134.007c.142,0-.077-.024.047.007.1.025.2.051.3.083.143.046-.05-.043.02.008.045.034.108.054.156.084l.086.053c.028.019.054.038.082.059-.057-.044-.059-.044-.006,0l.177.177c.112.112-.058-.1.03.036.028.042.057.083.082.126s.049.089.073.133c.062.11-.023-.088.02.043.032.1.058.2.083.3.007.027.013.116,0-.006.006.072.01.142.012.214a.6.6,0,1,0,1.21,0A2.572,2.572,0,0,0,6.309.467a2.533,2.533,0,0,0-2.778.741,2.566,2.566,0,0,0-.253,2.935A2.534,2.534,0,0,0,7.99,2.851a.61.61,0,0,0-.6-.6.617.617,0,0,0-.6.6"
              transform="translate(0.459 0.05)"
              fill="#fff"
              stroke="#fff"
              stroke-width="0.5"
            />
            <path
              id="Path_103652"
              data-name="Path 103652"
              d="M8.163,7.613a5.281,5.281,0,0,0-7.4,1.411,4.973,4.973,0,0,0-.736,1.763A1.382,1.382,0,0,0,1.106,12.46a10.516,10.516,0,0,0,1.9.029h2.14a.606.606,0,0,0,0-1.211H1.66c-.1,0-.3.036-.393-.025-.246-.163.173-.959.274-1.158A4.081,4.081,0,0,1,3.727,8.116a4.107,4.107,0,0,1,3.825.542c.637.45,1.242-.6.611-1.045"
              transform="translate(0 1.044)"
              fill="#fff"
              stroke="#fff"
              stroke-width="0.5"
            />
            <path
              id="Path_103653"
              data-name="Path 103653"
              d="M11.356,7.32a6.855,6.855,0,0,0-6.579,5.155A1.712,1.712,0,0,0,5.169,14.4a1.537,1.537,0,0,0,.962.3c.579.015,1.161,0,1.741,0h8.44c.767,0,1.55-.223,1.73-1.1a4.282,4.282,0,0,0-.549-2.374,6.632,6.632,0,0,0-1.466-2.042,6.9,6.9,0,0,0-4.67-1.867.605.605,0,0,0,0,1.21,5.631,5.631,0,0,1,5.45,4.431c.135.633-.191.53-.653.53h-9.2a3.2,3.2,0,0,1-.929,0c-.443-.175.135-1.418.279-1.726A5.583,5.583,0,0,1,11.356,8.53a.605.605,0,0,0,0-1.21"
              transform="translate(0.729 1.146)"
              fill="#fff"
              stroke="#fff"
              stroke-width="0.5"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
export function ProfileIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15.504" height="12.552" viewBox="0 0 22.504 17.552">
      <g id="Group_152169" data-name="Group 152169" transform="translate(0.25 -57.35)">
        <path
          id="Path_105702"
          data-name="Path 105702"
          d="M68.274,65.943A4.224,4.224,0,0,1,64.1,61.778a4.172,4.172,0,1,1,4.174,4.165Zm0-7.355a3.189,3.189,0,0,0-3.185,3.185,3.229,3.229,0,0,0,3.185,3.185,3.185,3.185,0,0,0,0-6.37Z"
          transform="translate(-61.345)"
          fill="#897EA3"
          stroke="#897EA3"
          stroke-width="0.5"
        />
        <path
          id="Path_105703"
          data-name="Path 105703"
          d="M13.4,98.1H.494A.494.494,0,0,1,0,97.6v-1.84a6.924,6.924,0,0,1,11.843-4.913,5.4,5.4,0,0,1,.43.469,6.626,6.626,0,0,1,1.586,3.886v.034c0,.069.009.142.013.219.009.107.017.211.017.305V97.6A.487.487,0,0,1,13.4,98.1ZM.984,97.111H12.909V95.765c0-.069-.009-.142-.013-.219-.009-.1-.017-.2-.017-.288a5.642,5.642,0,0,0-1.358-3.31,5,5,0,0,0-.37-.4A5.942,5.942,0,0,0,.984,95.765Zm15.475-8.245a3.383,3.383,0,1,1,3.383-3.383,3.387,3.387,0,0,1-3.383,3.383Zm0-5.782a2.4,2.4,0,1,0,2.4,2.4A2.4,2.4,0,0,0,16.459,83.084Z"
          transform="translate(0 -23.447)"
          fill="#897EA3"
          stroke="#897EA3"
          stroke-width="0.5"
        />
        <path
          id="Path_105704"
          data-name="Path 105704"
          d="M275.246,232.584H267.1a.489.489,0,0,1-.49-.456,5.821,5.821,0,0,0-1.367-3.331.486.486,0,0,1-.064-.529,5.594,5.594,0,0,1,5.008-3.168,5.533,5.533,0,0,1,5.549,5.549v1.444A.484.484,0,0,1,275.246,232.584Zm-7.7-.984h7.209v-.954a4.55,4.55,0,0,0-4.565-4.565,4.6,4.6,0,0,0-3.98,2.343A6.758,6.758,0,0,1,267.543,231.6Z"
          transform="translate(-253.732 -160.3)"
          fill="#897EA3"
          stroke="#897EA3"
          stroke-width="0.5"
        />
      </g>
    </svg>
  );
}
export function Editblue() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12.665" height="12.665" viewBox="0 0 12.665 12.665">
      <defs>
        <clipPath id="clip-path">
          <path id="Path_105690" data-name="Path 105690" d="M0-682.665H12.665V-670H0Z" transform="translate(0 682.665)" fill="#392765" />
        </clipPath>
      </defs>
      <g id="Group_152089" data-name="Group 152089" transform="translate(0 -0.002)">
        <g id="Group_152088" data-name="Group 152088" transform="translate(0 0.002)" clip-path="url(#clip-path)">
          <path
            id="Path_105687"
            data-name="Path 105687"
            d="M-340.234,0h-5.442a.989.989,0,0,0-.989.989V8.905a.989.989,0,0,0,.989.989h7.916a.989.989,0,0,0,.989-.989V3.463"
            transform="translate(347.037 2.399)"
            fill="none"
            stroke="#392765"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
          />
          <path id="Path_105688" data-name="Path 105688" d="M-388.633-81.691l-5.6,5.6-1.749.35.35-1.749,5.6-5.6a.5.5,0,0,1,.7,0l.7.7A.5.5,0,0,1-388.633-81.691Z" transform="translate(400.782 83.606)" fill="none" stroke="#392765" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
          <path id="Path_105689" data-name="Path 105689" d="M0,0,1.4,1.4" transform="translate(10.05 1.216)" fill="none" stroke="#392765" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
        </g>
      </g>
    </svg>
  );
}
export function InfoIcon() {
  return (
    <svg id="Group_152074" data-name="Group 152074" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
      <path
        id="Path_105682"
        data-name="Path 105682"
        d="M199.983,133.444a.584.584,0,0,1-.352-.079.365.365,0,0,1-.1-.3,1.56,1.56,0,0,1,.033-.273,3,3,0,0,1,.071-.305l.322-1.107a1.676,1.676,0,0,0,.065-.335c0-.123.016-.207.016-.256a.717.717,0,0,0-.254-.57,1.067,1.067,0,0,0-.72-.218,1.869,1.869,0,0,0-.545.09q-.289.09-.608.215l-.093.36c.063-.022.139-.046.226-.074a.927.927,0,0,1,.256-.038.524.524,0,0,1,.346.085.4.4,0,0,1,.09.3,1.294,1.294,0,0,1-.03.273q-.029.143-.074.3l-.325,1.113a3.178,3.178,0,0,0-.063.314,1.948,1.948,0,0,0-.019.273.712.712,0,0,0,.273.565,1.091,1.091,0,0,0,.731.224,1.683,1.683,0,0,0,.545-.079q.232-.079.619-.226l.087-.344a1.5,1.5,0,0,1-.215.071A1.035,1.035,0,0,1,199.983,133.444Zm.341-5.25a.83.83,0,0,0-1.091,0,.652.652,0,0,0,0,.987.818.818,0,0,0,1.091,0,.655.655,0,0,0,0-.987Z"
        transform="translate(-193.182 -124.99)"
        fill="#392765"
      />
      <path id="Path_105683" data-name="Path 105683" d="M6,0a6,6,0,1,0,6,6A6,6,0,0,0,6,0ZM6,11.455A5.455,5.455,0,1,1,11.455,6,5.455,5.455,0,0,1,6,11.455Z" fill="#392765" />
    </svg>
  );
}
export function Swapicon({ fill }) {
  return (
    <svg fill={fill} xmlns="http://www.w3.org/2000/svg" width="20.225" height="13.483" viewBox="0 0 20.225 13.483">
      <path
        id="Path_49487"
        data-name="Path 49487"
        d="M2.88,90.39h9.763a.843.843,0,0,0,0-1.685H2.88l1.932-1.932A.843.843,0,0,0,3.62,85.581L.249,88.951c-.02.02-.038.04-.056.062l-.022.03c-.009.012-.018.024-.027.036s-.015.024-.022.037-.014.022-.02.034-.012.025-.018.038-.012.024-.017.036-.009.025-.014.038-.01.026-.014.04-.007.025-.01.038-.007.028-.01.042,0,.03-.007.045,0,.024-.005.037a.848.848,0,0,0,0,.166c0,.012,0,.025.005.037s0,.03.007.045.007.028.01.042.006.026.01.038.009.027.014.04.009.025.014.038.011.024.017.036.012.026.018.038.013.023.02.034.014.025.022.037.018.024.027.036l.022.03c.017.021.036.042.055.061h0L3.62,93.514a.843.843,0,1,0,1.192-1.192Zm17.157,4.748.023-.03c.009-.012.018-.024.027-.036s.015-.024.022-.037.014-.022.02-.034.012-.026.018-.038.012-.024.017-.036.009-.025.014-.038.01-.026.014-.04.007-.026.01-.038.007-.028.01-.042,0-.03.007-.045,0-.024.005-.037a.848.848,0,0,0,0-.166c0-.012,0-.025-.005-.037s0-.03-.007-.045-.007-.028-.01-.042-.006-.026-.01-.038-.009-.027-.014-.04-.009-.025-.014-.038-.011-.024-.017-.036-.012-.026-.018-.038-.013-.023-.02-.034-.014-.025-.022-.037-.018-.024-.027-.036l-.023-.03q-.026-.032-.055-.061h0L16.61,90.637a.843.843,0,0,0-1.192,1.192l1.932,1.932H7.587a.843.843,0,0,0,0,1.685H17.35l-1.932,1.932A.843.843,0,0,0,16.61,98.57L19.981,95.2h0Q20.01,95.169,20.036,95.138Z"
        transform="translate(-0.003 -85.334)"
      />
    </svg>
  );
}

export function AdvanceTrading() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.1677 4.78814L12.1677 12.4921L10.2957 12.4921L10.2957 3.74414L12.1677 4.78814Z" fill="#D1DADD" />
      <path d="M16.7756 2.70025L16.7756 12.4922L14.9036 12.4922L14.9036 1.65625L16.7756 2.70025Z" fill="#D1DADD" />
      <path d="M7.55957 6.73199L7.55957 12.528L5.68757 12.528L5.68757 5.68799L7.55957 6.73199Z" fill="#D1DADD" />
      <path d="M16.8477 17.4243L0.863657 17.4243L0.863657 15.5523L16.8477 15.5523L16.8477 17.4243Z" fill="#D1DADD" />
      <path d="M0.864258 17.4238L0.864257 8.92783L2.73626 7.66783L2.73626 17.4238L0.864258 17.4238Z" fill="#D1DADD" />
    </svg>
  );
}

export function Earn({ fill }) {
  return (
    <svg fill={fill} xmlns="http://www.w3.org/2000/svg" width="19.762" height="19.762" viewBox="0 0 19.762 19.762">
      <path
        id="Layer_2"
        data-name="Layer 2"
        d="M11.131,1.25a9.881,9.881,0,1,0,9.881,9.881A9.881,9.881,0,0,0,11.131,1.25ZM7.169,7.169a1.59,1.59,0,0,1,2.27,0,1.609,1.609,0,1,1-2.27,2.27,1.59,1.59,0,0,1,0-2.27Zm1.838,8.07a.919.919,0,1,1-1.434-1.149l5.671-7.068a.919.919,0,1,1,1.434,1.149Zm6.076-.147a1.59,1.59,0,0,1-2.27,0,1.609,1.609,0,0,1,2.27-2.27,1.59,1.59,0,0,1,.009,2.27Z"
        transform="translate(-1.25 -1.25)"
      />
    </svg>
  );
}

export function ReferralIcon({ fill }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={fill}>
      <g id="Rectangle_10934" data-name="Rectangle 10934" fill="#000" stroke="#707070" strokeWidth="1" opacity="0">
        <rect width="24" height="24" stroke="none" />
        <rect x="0.5" y="0.5" width="23" height="23" fill="#000" />
      </g>
    </svg>
  );
}

export function Arrow() {
  return (
    <svg width="12.818" height="11.99" viewBox="0 0 12.818 11.99">
      <g id="Icon_feather-arrow-up" data-name="Icon feather-arrow-up" transform="translate(1.414 1)">
        <path id="Path_3" data-name="Path 3" d="M18,7.5v9.99" transform="translate(-13.005 -7.5)" fill="none" stroke="#014cec" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        <path id="Path_4" data-name="Path 4" d="M7.5,7.5l4.995,4.995L17.49,7.5" transform="translate(-7.5 -2.505)" fill="none" stroke="#014cec" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
      </g>
    </svg>
  );
}
export function ArrowUp() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12.818" height="11.99" viewBox="0 0 12.818 11.99">
      <g id="Icon_feather-arrow-up" data-name="Icon feather-arrow-up" transform="translate(-6.086 -6.5)">
        <path id="Path_3" data-name="Path 3" d="M18,17.49V7.5" transform="translate(-5.505 0)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        <path id="Path_4" data-name="Path 4" d="M7.5,12.495,12.495,7.5l4.995,4.995" transform="translate(0 0)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
      </g>
    </svg>
  );
}
export function PortfolioIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16.054" height="16.054" viewBox="0 0 16.054 16.054">
      <g id="Icon_feather-arrow-down-circle" data-name="Icon feather-arrow-down-circle" transform="translate(0.5 0.5)">
        <path id="Path_50105" data-name="Path 50105" d="M18.054,10.527a7.527,7.527,0,1,0-7.527,7.527A7.527,7.527,0,0,0,18.054,10.527Z" transform="translate(-3 -3)" fill="none" stroke="#80838e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
        <path id="Path_50106" data-name="Path 50106" d="M12,21.011,15.011,18l3.011,3.011" transform="translate(-7.484 -13.484)" fill="none" stroke="#80838e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
        <path id="Path_50107" data-name="Path 50107" d="M18,18.021V12" transform="translate(-10.473 -7.484)" fill="none" stroke="#80838e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
      </g>
    </svg>
  );
}
export function PortfoliowidIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16.054" height="16.054" viewBox="0 0 16.054 16.054">
      <g id="Icon_feather-arrow-down-circle" data-name="Icon feather-arrow-down-circle" transform="translate(0.5 0.5)">
        <path id="Path_50105" data-name="Path 50105" d="M18.054,10.527A7.527,7.527,0,1,1,10.527,3,7.527,7.527,0,0,1,18.054,10.527Z" transform="translate(-3 -3)" fill="none" stroke="#80838e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
        <path id="Path_50106" data-name="Path 50106" d="M12,18l3.011,3.011L18.021,18" transform="translate(-7.484 -10.474)" fill="none" stroke="#80838e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
        <path id="Path_50107" data-name="Path 50107" d="M18,12v6.021" transform="translate(-10.473 -7.484)" fill="none" stroke="#80838e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
      </g>
    </svg>
  );
}

export const DashToggleFirst = () => {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.10803 0.353516H1.9659C1.10483 0.353516 0.404297 1.05405 0.404297 1.91512V7.05725C0.404297 7.91832 1.10483 8.61885 1.9659 8.61885H7.10803C7.9691 8.61885 8.66963 7.91832 8.66963 7.05725V1.91512C8.66963 1.05405 7.9691 0.353516 7.10803 0.353516ZM17.1359 0.353516H11.9937C11.1327 0.353516 10.4321 1.05405 10.4321 1.91512V7.05725C10.4321 7.91832 11.1327 8.61885 11.9937 8.61885H17.1359C17.9969 8.61885 18.6975 7.91832 18.6975 7.05725V1.91512C18.6975 1.05405 17.9969 0.353516 17.1359 0.353516ZM7.10803 10.3814H1.9659C1.10483 10.3814 0.404297 11.0819 0.404297 11.943V17.0851C0.404297 17.9462 1.10483 18.6467 1.9659 18.6467H7.10803C7.9691 18.6467 8.66963 17.9462 8.66963 17.0851V11.943C8.66963 11.0819 7.9691 10.3814 7.10803 10.3814ZM17.1359 10.3814H11.9937C11.1327 10.3814 10.4321 11.0819 10.4321 11.943V17.0851C10.4321 17.9462 11.1327 18.6467 11.9937 18.6467H17.1359C17.9969 18.6467 18.6975 17.9462 18.6975 17.0851V11.943C18.6975 11.0819 17.9969 10.3814 17.1359 10.3814Z"
        fill="#02171D"
      />
    </svg>
  );
};
export const DashToggleSecond = () => {
  return (
    <svg width="25" height="19" viewBox="0 0 25 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.788 18.3247H7.53687C6.86714 18.3247 6.32422 17.7818 6.32422 17.112C6.32422 16.4423 6.86714 15.8994 7.53687 15.8994L23.7876 15.8994C24.4575 15.8994 25.0004 16.4423 25.0004 17.112C25.0004 17.7818 24.4577 18.3247 23.788 18.3247ZM23.788 10.7129H7.53687C6.86714 10.7129 6.32422 10.17 6.32422 9.50023C6.32422 8.8305 6.86714 8.28758 7.53687 8.28758H23.7876C24.4575 8.28758 25.0004 8.8305 25.0004 9.50023C25.0004 10.17 24.4577 10.7129 23.788 10.7129ZM23.788 3.10108H7.53687C6.86714 3.10108 6.32422 2.55816 6.32422 1.88843C6.32422 1.2187 6.86714 0.675781 7.53687 0.675781H23.7876C24.4575 0.675781 25.0004 1.2187 25.0004 1.88843C25.0004 2.55816 24.4577 3.10108 23.788 3.10108Z"
        fill="#02171D"
      />
      <ellipse cx="2.32585" cy="1.9821" rx="1.62859" ry="1.62859" fill="#02171D" />
      <ellipse cx="2.32585" cy="9.49968" rx="1.62859" ry="1.62859" fill="#02171D" />
      <ellipse cx="2.32585" cy="17.0182" rx="1.62859" ry="1.62859" fill="#02171D" />
    </svg>
  );
};
export const NoRecodImage = () => {
  return (
    <svg className="ant-empty-img-simple noRecordImg" width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(0 1)" fill="none" fillRule="evenodd">
        <ellipse className="ant-empty-img-simple-ellipse" cx="32" cy="33" rx="32" ry="7"></ellipse>
        <g className="ant-empty-img-simple-g" fillRule="nonzero">
          <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
          <path
            d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
            className="ant-empty-img-simple-path"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export function ListedCoinIcon({ fill }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={fill}>
      <g id="Listed_Coin" data-name="Listed Coin" transform="translate(-68 -147)">
        <g id="Rectangle_10934" data-name="Rectangle 10934" transform="translate(68 147)" fill="#fff" stroke="#707070" strokeWidth="1" opacity="0">
          <rect width="24" height="24" stroke="none" />
          <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
        </g>
        <g id="Group_149772" data-name="Group 149772" transform="translate(1.645 0.091)">
          <g id="Group_149771" data-name="Group 149771" transform="translate(68 148.554)">
            <ellipse id="Ellipse_9660" data-name="Ellipse 9660" cx="6.726" cy="7.949" rx="6.726" ry="7.949" transform="translate(3.669 3.164)" fill="#fff" />
            <path
              id="Path_102396"
              data-name="Path 102396"
              d="M10.355,0A10.355,10.355,0,1,0,20.711,10.355,10.353,10.353,0,0,0,10.355,0Zm6.5,9.182-3.226,3.042.985,3.53c.2.713-.152.985-.792.6l-3.466-2.09-3.47,2.085c-.635.382-1.008.11-.828-.612L7.009,11.9,3.884,9.145c-.557-.492-.405-.888.336-.888h3.8l1.8-4.2c.295-.681.764-.681,1.049,0l1.763,4.193h3.848C17.227,8.261,17.392,8.675,16.854,9.182Z"
              transform="translate(0 0)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
export function ReferralIconNew({ fill }) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.0004 4.54549L18.0004 2.08813L14.2564 5.82278L12.0244 4.24813L12.0244 6.52629L14.2564 8.20813L18.0004 4.54549Z" fill="#02171D" />
      <path d="M13.1152 17.8599L13.1152 15.8112L8.15105 13.0323L8.15105 15.081L13.1152 17.8599Z" fill="#02171D" />
      <path d="M0 17.8599L6.26845e-07 15.8112L4.96418 13.0323L4.96418 15.081L0 17.8599Z" fill="#02171D" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.51655 5.9246C5.47419 5.9246 4.62919 6.7696 4.62919 7.81196C4.62919 8.85432 5.47419 9.69932 6.51655 9.69932C7.55891 9.69932 8.40391 8.85432 8.40391 7.81196C8.40391 6.7696 7.55891 5.9246 6.51655 5.9246ZM3.00391 7.81196C3.00391 5.87198 4.57657 4.29932 6.51655 4.29932C8.45653 4.29932 10.0292 5.87198 10.0292 7.81196C10.0292 9.75194 8.45653 11.3246 6.51655 11.3246C4.57657 11.3246 3.00391 9.75194 3.00391 7.81196Z"
        fill="#02171D"
      />
    </svg>
  );
}
export function HistoryIconNew({ fill }) {
  return (
    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.2953 8.65022L10.2953 6.19287L6.55134 9.92752L4.31934 8.35287L4.31934 10.631L6.55134 12.3129L10.2953 8.65022Z" fill="#329879" />
      <path d="M12.0264 5.4043L12.0264 3.35565L7.06219 0.576743L7.06219 2.62539L12.0264 5.4043Z" fill="#329879" />
      <path d="M2.08691 5.4043L2.08691 3.35565L7.0511 0.576744L7.9093 1.86789L7.0511 2.6254L2.08691 5.4043Z" fill="#329879" />
      <path d="M13.5938 9.79297L13.5937 5.40097L11.7217 5.40097L11.7218 9.79297L13.5938 9.79297Z" fill="#329879" />
      <path d="M0.490234 9.79297L0.490236 5.40097L2.36223 5.40097L2.36223 9.79297L0.490234 9.79297Z" fill="#329879" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.44185 15.5967C9.76164 14.4228 11.8213 12.2256 11.7219 9.43311L13.5939 9.50511C13.7348 13.4646 10.766 16.0532 7.06595 17.3616L6.44185 15.5967Z" fill="#329879" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.68436 15.5967C4.36458 14.4228 2.26259 12.2256 2.36193 9.43311L0.453914 9.50511C0.313058 13.4646 3.36025 16.0532 7.06026 17.3616L7.68436 15.5967Z" fill="#329879" />
    </svg>

  );
}
export function WalletIconNew({ fill }) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.0479 16.2721L15.0479 6.6961L16.9199 6.6961L16.9199 16.2721L15.0479 16.2721Z" fill="#329879" />
      <path d="M1.08008 14.4L16.9201 14.4V16.272L1.08008 16.272L1.08008 14.4Z" fill="#329879" />
      <path d="M2.95117 6.6958L2.95117 16.2718L1.07917 16.2718L1.07917 6.6958L2.95117 6.6958Z" fill="#329879" />
      <path d="M1.69146 2.44812L17.4235 2.44812L16.3795 4.32012H0.647461L1.69146 2.44812Z" fill="#329879" />
      <path d="M6.29986 6.6958L16.9199 6.6958L15.8759 8.5678H5.25586L6.29986 6.6958Z" fill="#329879" />
      <rect x="11.5918" y="10.9441" width="1.944" height="1.872" fill="#329879" />
    </svg>


  );
}
export function MarketIconNew({ fill }) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.3271 2.28296L6.67349 2.28296L6.67349 3.23932e-05L11.3271 3.26633e-05L11.3271 2.28296Z" fill="#329879" />
      <path d="M11.3271 18L6.67349 18L6.67349 15.7171L11.3271 15.7171L11.3271 18Z" fill="#329879" />
      <path d="M15.7168 11.239L15.7168 6.58536L17.9997 6.58536L17.9997 11.239L15.7168 11.239Z" fill="#329879" />
      <path d="M0 11.239L9.84466e-07 6.58536L2.28293 6.58536L2.28293 11.239L0 11.239Z" fill="#329879" />
      <path d="M15.3945 5.89551L12.1039 2.60487L13.7182 0.990601L17.0088 4.28124L15.3945 5.89551Z" fill="#329879" />
      <path d="M4.28125 17.0093L0.990615 13.7186L2.60489 12.1044L5.89552 15.395L4.28125 17.0093Z" fill="#329879" />
      <path d="M12.166 15.333L15.4567 12.0424L17.0709 13.6566L13.7803 16.9473L12.166 15.333Z" fill="#329879" />
      <path d="M1.05273 4.21924L4.34337 0.928605L5.95764 2.54288L2.66701 5.83351L1.05273 4.21924Z" fill="#329879" />
      <path d="M6.67285 6.58545H11.3265V11.2391H6.67285V6.58545Z" fill="#329879" />
    </svg>

  );
}
export function TransactionsIcon({ fill }) {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20.000000pt" height="30.000000pt" viewBox="0 0 30.000000 30.000000" preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,30.000000) scale(0.100000,-0.100000)" fill={fill} stroke="none">
        <path
          d="M42 267 c-28 -30 -28 -68 1 -95 30 -28 68 -28 95 1 28 30 28 68 -1
95 -30 28 -68 28 -95 -1z m58 -47 c0 -22 -4 -40 -10 -40 -5 0 -10 18 -10 40 0
22 5 40 10 40 6 0 10 -18 10 -40z"
        />
        <path
          d="M195 240 c-17 -19 -17 -21 0 -40 22 -24 35 -26 35 -5 0 10 11 15 35
15 19 0 35 5 35 10 0 6 -16 10 -35 10 -24 0 -35 5 -35 15 0 21 -13 19 -35 -5z"
        />
        <path
          d="M162 68 l3 -63 65 0 65 0 3 63 3 62 -71 0 -71 0 3 -62z m93 32 c-3
-5 -15 -10 -25 -10 -10 0 -22 5 -25 10 -4 6 7 10 25 10 18 0 29 -4 25 -10z"
        />
        <path
          d="M90 95 c0 -10 -11 -15 -35 -15 -19 0 -35 -4 -35 -10 0 -5 16 -10 35
-10 24 0 35 -5 35 -15 0 -21 13 -19 35 5 17 19 17 21 0 40 -22 24 -35 26 -35
5z"
        />
      </g>
    </svg>
  );
}
export function CoinListingIcon({ fill }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={fill}>
      <g id="Group_146733" data-name="Group 146733" transform="translate(-1 -1)">
        <path
          id="Path_50095"
          data-name="Path 50095"
          d="M18.523,5.381c0,2.5-3.767,4.381-8.762,4.381S1,7.878,1,5.381,4.767,1,9.762,1,18.523,2.884,18.523,5.381ZM11.514,18.523c-3.724,0-6.9-.964-8.762-2.585.053,2.48,3.794,4.337,8.762,4.337s8.683-1.849,8.762-4.3C18.444,17.559,15.343,18.523,11.514,18.523Zm-1.752-3.5a16.419,16.419,0,0,1-6.58-1.227c1.121,1.77,4.328,2.979,8.332,2.979,4.994,0,8.762-1.884,8.762-4.381a2.628,2.628,0,0,0-.5-1.507C18.471,13.389,14.694,15.018,9.762,15.018Zm.876-3.5H8.885C5.451,11.382,2.656,10.445,1,8.982c.079,2.436,3.82,4.284,8.762,4.284s8.683-1.849,8.762-4.3C16.832,10.427,14.037,11.365,10.638,11.514Z"
        />
      </g>
    </svg>
  );
}

export function ApplicationsIcon({ fill }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17.155" height="20.855" viewBox="0 0 17.155 20.855">
      <g id="Group_149717" data-name="Group 149717" transform="translate(-44)">
        <path
          id="Path_102394"
          data-name="Path 102394"
          d="M56.764,80H44.788a.789.789,0,0,0-.788.788V96.7a.789.789,0,0,0,.788.788H56.764a.789.789,0,0,0,.788-.788V80.788A.789.789,0,0,0,56.764,80ZM54.091,91.1H47.7a.673.673,0,0,1,0-1.346h6.391a.673.673,0,0,1,0,1.346Zm0-2.691H47.7a.673.673,0,0,1,0-1.346h6.391a.673.673,0,0,1,0,1.346Zm0-2.691H47.7a.673.673,0,1,1,0-1.346h6.391a.673.673,0,1,1,0,1.346Z"
          transform="translate(0 -76.636)"
          fill={fill}
        />
        <path id="Path_102395" data-name="Path 102395" d="M112.588,2.018v14.8a2.09,2.09,0,0,1-2.258,2.018V4.152A2.136,2.136,0,0,0,108.2,2.018H97.69A2.083,2.083,0,0,1,99.828,0H110.45A2.083,2.083,0,0,1,112.588,2.018Z" transform="translate(-51.432)" fill={fill} />
      </g>
    </svg>
  );
}

export function FavoritesIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11.737" height="11.234" viewBox="0 0 11.737 11.234">
      <path
        id="Icon_awesome-star"
        data-name="Icon awesome-star"
        d="M6.68.39l-1.433,2.9-3.205.467a.7.7,0,0,0-.388,1.2l2.319,2.26-.548,3.192a.7.7,0,0,0,1.018.739L7.31,9.644l2.867,1.507a.7.7,0,0,0,1.018-.739l-.548-3.192,2.319-2.26a.7.7,0,0,0-.388-1.2L9.372,3.295,7.94.39A.7.7,0,0,0,6.68.39Z"
        transform="translate(-1.441 0.001)"
        fill="#848e9c"
      />
    </svg>
  );
}

export function Rewardicon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32">
      <defs>
        <linearGradient id="linear-gradient" x1="0.036" x2="0.984" y2="0.098" gradientUnits="objectBoundingBox">
          <stop offset="0" stop-color="#2a688b" />
          <stop offset="1" stop-color="#1ea0ab" />
        </linearGradient>
      </defs>
      <g id="Group_171150" data-name="Group 171150" transform="translate(-36 -161)">
        <rect id="Rectangle_10934" data-name="Rectangle 10934" width="32" height="32" rx="8" transform="translate(36 161)" fill="url(#linear-gradient)" />
        <g id="Group_171125" data-name="Group 171125" transform="translate(3 3)">
          <g id="Icon_feather-gift" data-name="Icon feather-gift" transform="translate(36.101 162)">
            <path id="Path_111627" data-name="Path 111627" d="M21.83,18v9.922H6V18" transform="translate(-1.016 -5.123)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
            <path id="Path_111628" data-name="Path 111628" d="M3,10.5H22.8v4.961H3Z" transform="translate(0 -2.554)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
            <path id="Path_111629" data-name="Path 111629" d="M18,25.383V10.5" transform="translate(-5.101 -2.584)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
            <path id="Path_111630" data-name="Path 111630" d="M14.445,7.961H9.98A2.48,2.48,0,0,1,9.98,3C13.453,3,14.445,7.961,14.445,7.961Z" transform="translate(-1.534)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
            <path id="Path_111631" data-name="Path 111631" d="M18,7.961h4.465a2.48,2.48,0,0,0,0-4.961C18.992,3,18,7.961,18,7.961Z" transform="translate(-5.123)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
          </g>
          <rect id="Rectangle_59500" data-name="Rectangle 59500" width="24" height="24" transform="translate(37 162)" fill="none" />
        </g>
      </g>
    </svg>
  );
}

export function CashIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32">
      <defs>
        <linearGradient id="linear-gradient" x1="0.036" x2="0.984" y2="0.098" gradientUnits="objectBoundingBox">
          <stop offset="0" stop-color="#2a688b" />
          <stop offset="1" stop-color="#1ea0ab" />
        </linearGradient>
        <clipPath id="clip-path">
          <rect id="Rectangle_59506" data-name="Rectangle 59506" width="18" height="19" transform="translate(-0.302 0.032)" fill="#fff" />
        </clipPath>
      </defs>
      <g id="Group_171151" data-name="Group 171151" transform="translate(-36 -161)">
        <rect id="Rectangle_10934" data-name="Rectangle 10934" width="32" height="32" rx="8" transform="translate(36 161)" fill="url(#linear-gradient)" />
        <g id="Group_171125" data-name="Group 171125" transform="translate(3 3)">
          <rect id="Rectangle_59500" data-name="Rectangle 59500" width="24" height="24" transform="translate(37 162)" fill="none" />
          <g id="Mask_Group_150224" data-name="Mask Group 150224" transform="translate(40.302 164.968)" clip-path="url(#clip-path)">
            <path
              id="deposit"
              d="M149.015,77.433h-.405a1.623,1.623,0,0,0-1.656-1.374c-.819,0-1.137.716-1.364,1.374h-1.954a.964.964,0,0,1,1.027-.763.305.305,0,0,0,0-.611,1.577,1.577,0,0,0-1.65,1.374H142.6a.305.305,0,1,0,0,.611h.411a1.577,1.577,0,0,0,1.65,1.374c.819,0,1.137-.716,1.364-1.374h1.96a1.05,1.05,0,0,1-1.035.763.305.305,0,0,0,0,.611,1.623,1.623,0,0,0,1.656-1.374h.406a.305.305,0,0,0,0-.611Zm-2.061-.763a1.036,1.036,0,0,1,1.034.763H146.24C146.423,76.953,146.607,76.67,146.954,76.67Zm-2.29,2.137a.968.968,0,0,1-1.025-.763h1.738C145.194,78.524,145.011,78.807,144.664,78.807Zm7.862-1.07V70.105A1.472,1.472,0,0,0,151,68.579H140.618c-1.208,0-1.527.8-1.527,1.221v7.938H137.26V81.4h17.1V77.738Zm-10.4-8.548h7.365a2.752,2.752,0,0,0,2.425,2.425v9.174h-2.3a4.885,4.885,0,1,0-7.609,0h-2.3V71.314A2.46,2.46,0,0,0,142.126,69.189Zm.7,11.6a4.274,4.274,0,1,1,5.968,0Zm9.09-10.683V71a2.138,2.138,0,0,1-1.808-1.808H151A.871.871,0,0,1,151.915,70.105Zm-11.3-.916h.891A1.865,1.865,0,0,1,139.7,70.7V69.8C139.705,69.7,139.764,69.189,140.618,69.189Zm-2.747,9.159h1.22v2.442h-1.22Zm15.875,2.442h-1.221V78.348h1.221Zm-5.775,3.055h-.915V82.319a.305.305,0,0,0-.305-.305h-2.444a.305.305,0,0,0-.305.305v1.527h-.915a.305.305,0,0,0-.216.521l2.443,2.443a.305.305,0,0,0,.432,0l2.443-2.443a.305.305,0,0,0-.216-.521Zm-2.443,2.316-1.706-1.706h.483a.305.305,0,0,0,.305-.305V82.624h1.833v1.527a.305.305,0,0,0,.305.305h.484Z"
              transform="translate(-137.111 -68.206)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export function MembersIcon() {
  return (
    <svg id="Group_171152" data-name="Group 171152" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32">
      <defs>
        <linearGradient id="linear-gradient" x1="0.036" x2="0.984" y2="0.098" gradientUnits="objectBoundingBox">
          <stop offset="0" stop-color="#2a688b" />
          <stop offset="1" stop-color="#1ea0ab" />
        </linearGradient>
      </defs>
      <rect id="Rectangle_10934" data-name="Rectangle 10934" width="32" height="32" rx="8" fill="url(#linear-gradient)" />
      <g id="Group_171125" data-name="Group 171125" transform="translate(4 4)">
        <rect id="Rectangle_59500" data-name="Rectangle 59500" width="24" height="24" fill="none" />
        <path
          id="Icon_material-people-outline"
          data-name="Icon material-people-outline"
          d="M15.75,14.855a11.02,11.02,0,0,0-4.137.919,10.844,10.844,0,0,0-4.137-.919c-1.995,0-5.976.993-5.976,2.988v2.528H21.725V17.842C21.725,15.847,17.744,14.855,15.75,14.855Zm-3.677,4.137H2.879V17.842c0-.5,2.353-1.609,4.6-1.609s4.6,1.112,4.6,1.609Zm8.274,0H13.451V17.842a1.673,1.673,0,0,0-.478-1.122,8.869,8.869,0,0,1,2.776-.487c2.243,0,4.6,1.112,4.6,1.609ZM7.476,13.935a3.218,3.218,0,1,0-3.218-3.218A3.221,3.221,0,0,0,7.476,13.935Zm0-5.056a1.839,1.839,0,1,1-1.839,1.839A1.844,1.844,0,0,1,7.476,8.879Zm8.274,5.056a3.218,3.218,0,1,0-3.218-3.218A3.221,3.221,0,0,0,15.75,13.935Zm0-5.056a1.839,1.839,0,1,1-1.839,1.839A1.844,1.844,0,0,1,15.75,8.879Z"
          transform="translate(0.5 -1.5)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export function Arrowright() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="7.109" height="12.508" viewBox="0 0 7.109 12.508">
      <g id="Group_151875" data-name="Group 151875" transform="translate(0.25 0.302)">
        <path id="Path_105636" data-name="Path 105636" d="M60.494,6.379l-5.354,5.352a.6.6,0,1,1-.851-.849l4.929-4.927L54.29,1.027a.6.6,0,0,1,.851-.85L60.5,5.529A.607.607,0,0,1,60.494,6.379Z" transform="translate(-54.113 -0.001)" fill="#1ea0ab" stroke="#1ea0ab" stroke-width="0.5" />
      </g>
    </svg>
  );
}
export function StepsIcon() {
  return (
    <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.705078" width="50" height="50" rx="4" fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.7051 36C31.7802 36 36.7051 31.0751 36.7051 25C36.7051 18.9249 31.7802 14 25.7051 14C19.6299 14 14.7051 18.9249 14.7051 25C14.7051 31.0751 19.6299 36 25.7051 36ZM30.9053 18.4C30.9053 17.9444 30.5359 17.575 30.0803 17.575C29.6247 17.575 29.2553 17.9444 29.2553 18.4V19.5255C26.7915 17.8165 23.4073 18.0786 21.2258 20.3118C18.7648 22.8312 18.7648 26.908 21.2258 29.4274C23.6974 31.9575 27.7128 31.9575 30.1843 29.4274C31.618 27.9597 32.2135 25.966 31.9811 24.066C31.9258 23.6137 31.5144 23.292 31.0621 23.3473C30.6098 23.4026 30.288 23.8141 30.3433 24.2663C30.5183 25.6971 30.0685 27.1847 29.004 28.2744C27.1798 30.1419 24.2304 30.1419 22.4061 28.2744C20.5714 26.3961 20.5714 23.3431 22.4061 21.4648C23.8615 19.975 26.0329 19.6737 27.7779 20.561H27.1635C26.7079 20.561 26.3385 20.9303 26.3385 21.386C26.3385 21.8416 26.7079 22.211 27.1635 22.211H30.0803C30.5359 22.211 30.9053 21.8416 30.9053 21.386V18.4Z"
        fill="#329879"
      />
    </svg>
  );
}
export function RefreshIcon() {
  return (
    <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.7051 22C17.7802 22 22.7051 17.0751 22.7051 11C22.7051 4.92487 17.7802 0 11.7051 0C5.62995 0 0.705078 4.92487 0.705078 11C0.705078 17.0751 5.62995 22 11.7051 22ZM16.9053 4.4C16.9053 3.94437 16.5359 3.575 16.0803 3.575C15.6247 3.575 15.2553 3.94437 15.2553 4.4V5.52552C12.7915 3.81651 9.40732 4.07861 7.22583 6.31182C4.76483 8.83117 4.76483 12.908 7.22583 15.4274C9.69739 17.9575 13.7128 17.9575 16.1843 15.4274C17.618 13.9597 18.2135 11.966 17.9811 10.066C17.9258 9.61374 17.5144 9.29195 17.0621 9.34727C16.6098 9.40258 16.288 9.81406 16.3433 10.2663C16.5183 11.6971 16.0685 13.1847 15.004 14.2744C13.1798 16.1419 10.2304 16.1419 8.40615 14.2744C6.57139 12.3961 6.57139 9.34306 8.40615 7.4648C9.86146 5.97498 12.0329 5.67371 13.7779 6.56097H13.1635C12.7079 6.56097 12.3385 6.93034 12.3385 7.38597C12.3385 7.84161 12.7079 8.21097 13.1635 8.21097H16.0803C16.5359 8.21097 16.9053 7.84161 16.9053 7.38597V4.4Z"
        fill="#30F1B6"
      />
    </svg>
  );
}
export function TwwiterIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17">
      <path
        id="Path_107980"
        data-name="Path 107980"
        d="M27.644,14.557a4.274,4.274,0,0,0,1.8-2.375,8,8,0,0,1-2.605,1.042,3.979,3.979,0,0,0-4.927-.838,4.375,4.375,0,0,0-2.068,4.755,11.484,11.484,0,0,1-8.456-4.486,4.424,4.424,0,0,0,1.27,5.733,3.951,3.951,0,0,1-1.858-.538V17.9a4.253,4.253,0,0,0,3.292,4.21,3.935,3.935,0,0,1-1.853.074,4.139,4.139,0,0,0,3.833,2.982A8.008,8.008,0,0,1,10,26.947a11.337,11.337,0,0,0,14.569-1.625,12.428,12.428,0,0,0,3.4-8.663q0-.279-.012-.555A8.561,8.561,0,0,0,30,13.88,7.92,7.92,0,0,1,27.644,14.557Z"
        transform="translate(-10 -11.876)"
        fill="#fff"
      />
    </svg>
  );
}

export function LinkedinIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <path
        id="Path_107981"
        data-name="Path 107981"
        d="M81,27.376h0v-6.6c0-3.229-.7-5.717-4.471-5.717A3.919,3.919,0,0,0,73,17h-.052V15.358h-3.58V27.375h3.727V21.425c0-1.567.3-3.082,2.237-3.082,1.912,0,1.94,1.788,1.94,3.182v5.851ZM63.3,15.359h3.732V27.376H63.3Zm1.864-5.983a2.163,2.163,0,1,0,1.528.634A2.162,2.162,0,0,0,65.161,9.376Z"
        transform="translate(-63 -9.376)"
        fill="#fff"
      />
    </svg>
  );
}

export function InstaIcon() {
  return (
    <svg id="Group_17346" data-name="Group 17346" xmlns="http://www.w3.org/2000/svg" width="18.748" height="18.75" viewBox="0 0 18.748 18.75">
      <path
        id="Path_14"
        data-name="Path 14"
        d="M6.25,9.375A3.125,3.125,0,1,1,9.375,12.5,3.125,3.125,0,0,1,6.25,9.375m-1.689,0A4.814,4.814,0,1,0,9.374,4.561,4.814,4.814,0,0,0,4.56,9.375m8.692-5a1.125,1.125,0,1,0,1.125-1.125h0A1.126,1.126,0,0,0,13.253,4.37M5.588,17.005a5.181,5.181,0,0,1-1.741-.322,2.914,2.914,0,0,1-1.078-.7,2.892,2.892,0,0,1-.7-1.078,5.176,5.176,0,0,1-.323-1.741c-.045-.988-.055-1.285-.055-3.788s.01-2.8.055-3.788a5.216,5.216,0,0,1,.322-1.741,2.913,2.913,0,0,1,.7-1.078,2.889,2.889,0,0,1,1.078-.7,5.176,5.176,0,0,1,1.742-.323c.988-.045,1.285-.055,3.787-.055s2.8.01,3.788.055a5.215,5.215,0,0,1,1.741.322,2.9,2.9,0,0,1,1.078.7,2.9,2.9,0,0,1,.7,1.078,5.177,5.177,0,0,1,.322,1.741c.045.989.055,1.285.055,3.788s-.009,2.8-.055,3.788a5.2,5.2,0,0,1-.322,1.741,3.1,3.1,0,0,1-1.78,1.779,5.176,5.176,0,0,1-1.741.322c-.988.045-1.285.055-3.788.055s-2.8-.009-3.787-.055M5.51.057A6.875,6.875,0,0,0,3.234.492,4.6,4.6,0,0,0,1.573,1.573,4.58,4.58,0,0,0,.492,3.234,6.876,6.876,0,0,0,.057,5.51C.011,6.509,0,6.829,0,9.375S.011,12.24.057,13.24a6.876,6.876,0,0,0,.435,2.275,4.583,4.583,0,0,0,1.081,1.661,4.609,4.609,0,0,0,1.661,1.081,6.88,6.88,0,0,0,2.275.435c1,.045,1.319.057,3.865.057s2.865-.011,3.865-.057a6.876,6.876,0,0,0,2.275-.435,4.793,4.793,0,0,0,2.742-2.742,6.856,6.856,0,0,0,.435-2.275c.045-1,.055-1.319.055-3.865s-.011-2.865-.055-3.865a6.874,6.874,0,0,0-.435-2.275,4.61,4.61,0,0,0-1.081-1.661A4.587,4.587,0,0,0,15.516.492,6.865,6.865,0,0,0,13.24.057C12.241.011,11.921,0,9.375,0S6.509.011,5.509.057"
        fill="#fff"
      />
      <path
        id="Path_15"
        data-name="Path 15"
        d="M6.25,9.375A3.125,3.125,0,1,1,9.375,12.5,3.125,3.125,0,0,1,6.25,9.375m-1.689,0A4.814,4.814,0,1,0,9.374,4.561,4.814,4.814,0,0,0,4.56,9.375m8.692-5a1.125,1.125,0,1,0,1.125-1.125h0A1.126,1.126,0,0,0,13.253,4.37M5.588,17.005a5.181,5.181,0,0,1-1.741-.322,2.914,2.914,0,0,1-1.078-.7,2.892,2.892,0,0,1-.7-1.078,5.176,5.176,0,0,1-.323-1.741c-.045-.988-.055-1.285-.055-3.788s.01-2.8.055-3.788a5.216,5.216,0,0,1,.322-1.741,2.913,2.913,0,0,1,.7-1.078,2.889,2.889,0,0,1,1.078-.7,5.176,5.176,0,0,1,1.742-.323c.988-.045,1.285-.055,3.787-.055s2.8.01,3.788.055a5.215,5.215,0,0,1,1.741.322,2.9,2.9,0,0,1,1.078.7,2.9,2.9,0,0,1,.7,1.078,5.177,5.177,0,0,1,.322,1.741c.045.989.055,1.285.055,3.788s-.009,2.8-.055,3.788a5.2,5.2,0,0,1-.322,1.741,3.1,3.1,0,0,1-1.78,1.779,5.176,5.176,0,0,1-1.741.322c-.988.045-1.285.055-3.788.055s-2.8-.009-3.787-.055M5.51.057A6.875,6.875,0,0,0,3.234.492,4.6,4.6,0,0,0,1.573,1.573,4.58,4.58,0,0,0,.492,3.234,6.876,6.876,0,0,0,.057,5.51C.011,6.509,0,6.829,0,9.375S.011,12.24.057,13.24a6.876,6.876,0,0,0,.435,2.275,4.583,4.583,0,0,0,1.081,1.661,4.609,4.609,0,0,0,1.661,1.081,6.88,6.88,0,0,0,2.275.435c1,.045,1.319.057,3.865.057s2.865-.011,3.865-.057a6.876,6.876,0,0,0,2.275-.435,4.793,4.793,0,0,0,2.742-2.742,6.856,6.856,0,0,0,.435-2.275c.045-1,.055-1.319.055-3.865s-.011-2.865-.055-3.865a6.874,6.874,0,0,0-.435-2.275,4.61,4.61,0,0,0-1.081-1.661A4.587,4.587,0,0,0,15.516.492,6.865,6.865,0,0,0,13.24.057C12.241.011,11.921,0,9.375,0S6.509.011,5.509.057"
        fill="#fff"
      />
    </svg>
  );
}

export function DescodeIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="17.74" viewBox="0 0 24 17.74">
      <path
        id="Path_107979"
        data-name="Path 107979"
        d="M184.674,13.343a11.5,11.5,0,0,0-5.5-1.965.517.517,0,0,0-.515.306,3.233,3.233,0,0,0-.159.434,12.809,12.809,0,0,1,4.587,1.647.522.522,0,1,1-.55.887,13.339,13.339,0,0,0-13.068,0,.522.522,0,0,1-.55-.887A12.8,12.8,0,0,1,173.5,12.12a3.956,3.956,0,0,0-.157-.436.514.514,0,0,0-.517-.306,11.529,11.529,0,0,0-5.531,1.987c-1.1,1.017-3.3,6.96-3.3,12.1a.522.522,0,0,0,.069.259c1.517,2.666,5.656,3.363,6.6,3.393h.017a.522.522,0,0,0,.422-.214l.953-1.312a10.135,10.135,0,0,1-3.964-1.861.522.522,0,0,1,.691-.783c.031.028,2.452,2.083,7.213,2.083s7.19-2.062,7.215-2.083a.521.521,0,0,1,.689.783,10.135,10.135,0,0,1-3.964,1.861l.953,1.313a.522.522,0,0,0,.422.214h.017c.943-.031,5.082-.728,6.6-3.393a.522.522,0,0,0,.069-.259C188,20.326,185.8,14.383,184.674,13.343ZM172.609,23.376a1.969,1.969,0,0,1-1.826-2.087,1.842,1.842,0,1,1,3.652,0A1.969,1.969,0,0,1,172.609,23.376Zm6.782,0a1.969,1.969,0,0,1-1.826-2.087,1.842,1.842,0,1,1,3.652,0A1.969,1.969,0,0,1,179.391,23.376Z"
        transform="translate(-164 -11.376)"
        fill="#fff"
      />
    </svg>
  );
}

export function CheckIcon() {
  return (
    <svg id="Group_171317" data-name="Group 171317" xmlns="http://www.w3.org/2000/svg" width="119.596" height="119.596" viewBox="0 0 119.596 119.596">
      <circle id="Ellipse_9721" data-name="Ellipse 9721" cx="59.798" cy="59.798" r="59.798" transform="translate(0 0)" fill="#32ba7c" />
      <path id="Path_111651" data-name="Path 111651" d="M188.8,202.932l30.748,30.748a59.759,59.759,0,0,0,44.33-57.723v-1.7L239.732,152Z" transform="translate(-144.282 -116.159)" fill="#0aa06e" />
      <path id="Path_111652" data-name="Path 111652" d="M126.417,225.726a7.063,7.063,0,0,1,0,9.809l-5.47,5.47a7.063,7.063,0,0,1-9.809,0L87.181,216.86a7.063,7.063,0,0,1,0-9.809l5.47-5.47a7.063,7.063,0,0,1,9.809,0Z" transform="translate(-65.11 -152.535)" fill="#fff" />
      <path id="Path_111653" data-name="Path 111653" d="M207.881,127.181a7.063,7.063,0,0,1,9.809,0l5.47,5.47a7.063,7.063,0,0,1,0,9.809l-41.5,41.311a7.063,7.063,0,0,1-9.809,0l-5.47-5.47a7.063,7.063,0,0,1,0-9.809l41.5-41.311Z" transform="translate(-125.635 -95.678)" fill="#fff" />
    </svg>
  );
}

export function EditIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8.232" height="8.192" viewBox="0 0 8.232 8.192">
      <g id="Group_171334" data-name="Group 171334" transform="translate(-0.001 -1.289)">
        <path id="Path_111656" data-name="Path 111656" d="M5.12,2.656,6.794,4.33,2.555,8.569.882,6.895Zm2.945-.4-.747-.747a.741.741,0,0,0-1.047,0l-.715.715L7.23,3.9l.834-.834a.571.571,0,0,0,0-.809Zm-8.06,7a.191.191,0,0,0,.231.227L2.1,9.023.428,7.348Z" transform="translate(0 0)" fill="#392765" />
      </g>
    </svg>
  );
}

export function CopyPasteIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13.78" height="15.956" viewBox="0 0 13.78 15.956">
      <path
        id="Icon_material-content-copy"
        data-name="Icon material-content-copy"
        d="M13.154,1.5h-8.7A1.455,1.455,0,0,0,3,2.951V13.1H4.451V2.951h8.7ZM15.33,4.4H7.352A1.455,1.455,0,0,0,5.9,5.852V16.006a1.455,1.455,0,0,0,1.451,1.451H15.33a1.455,1.455,0,0,0,1.451-1.451V5.852A1.455,1.455,0,0,0,15.33,4.4Zm0,11.6H7.352V5.852H15.33Z"
        transform="translate(-3 -1.5)"
        fill="#000"
      />
    </svg>
  );
}

export function DropArrowicon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="9.974" height="5.703" viewBox="0 0 9.974 5.703">
      <path id="Icon_ionic-ios-arrow-down" data-name="Icon ionic-ios-arrow-down" d="M11.176,15.23l3.771-3.774a.71.71,0,0,1,1.007,0,.719.719,0,0,1,0,1.01l-4.273,4.276a.711.711,0,0,1-.983.021L6.4,12.468A.713.713,0,1,1,7.4,11.459Z" transform="translate(-6.188 -11.246)" />
    </svg>
  );
}
