import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import * as config from "../../utils/apiVersion";
import { startLoading, stopLoading } from "../feature";
import { errorToastSelector, successToastSelector } from "../selector";
import { buildQueryString } from "../../helpers";
import { getBalance } from "./commonServices";

export const getWithdrawHistory = createAsyncThunk(
  "transHistories/getWithdrawHistory",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `account/withdraws?${buildQueryString(payload)}`;
      let res = await API.get(config.peatioWithHeader)(url);
      dispatch(stopLoading());
      return { list: res.data, total: res.headers.total, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getDepositHistory = createAsyncThunk(
  "transHistories/getDepositHistory",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `account/deposits?${buildQueryString(payload)}`;
      let res = await API.get(config.peatioWithHeader)(url);
      dispatch(stopLoading());
      return { list: res.data, total: res.headers.total, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getMarketList = createAsyncThunk(
  "transHistories/getMarketList",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `market/list`;
      let res = await API.get(config.peatio)(url, payload);
      dispatch(stopLoading());
      return { list: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const downloadData = createAsyncThunk(
  "transHistories/downloadData",
  async (payload, { rejectWithValue, dispatch }) => {
    let tabName = payload.tab
    if (tabName === "send" || tabName === "receive" || tabName === "transfer" || tabName === "convert" && tabName === "wallet") {
      delete payload.type;
      delete payload.tab
    }
    else {
      delete payload.tab
    }
    try {
      dispatch(startLoading());
      let url = `/account/csv`;
      let res = await API.post(config.peatio)(url, payload);
      dispatch(stopLoading());
      return { status: true, link: res };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getUserTrades = createAsyncThunk(
  "transHistories/getUserTrades",
  async ({ params }, { dispatch }) => {
    try {
      // dispatch(startLoading());
      let url = `/market/trades?${buildQueryString(params)}`;
      const { data, headers } = await API.get(config.peatioWithHeader)(url);
      dispatch(stopLoading());
      return { list: data, total: headers.total };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const getTransHisTrades = createAsyncThunk(
  "transHistories/getTrades",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      let url;
      dispatch(startLoading(true));
      if (payload?.id) {
        url = `public/markets/${payload?.id}/trades`;
      } else {
        url = `/market/trades?${buildQueryString(payload)}`;
      }
      let res = await API.get(config.peatioWithHeader)(url);
      dispatch(stopLoading(false));
      return {
        list: res?.data,
        total: res?.headers?.total > 0 ? res?.headers?.total : 0,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const getConvertHistory = createAsyncThunk(
  "transHistories/getConvertHistory",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `account/adjustments?${buildQueryString(payload)}`;
      let res = await API.get(config.peatioWithHeader)(url);
      dispatch(stopLoading());
      return { list: res.data, total: res.headers.total, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const createConvert = createAsyncThunk(
  "transHistories/createConvert",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `account/adjustments`;
      const res = await API.post(config.peatio)(url, payload);
      dispatch(successToastSelector({ message: "Convert successfully" }));
      await dispatch(getBalance());
      await dispatch(getConvertHistory({ limit: 10 }));
      dispatch(stopLoading());
      return { list: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getBonus = createAsyncThunk(
  "transHistories/getBonus",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `account/bonus`;
      let res = await API.get(config.peatio)(url);
      dispatch(stopLoading());
      return { data: res };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getTransactionHistoryCsv = createAsyncThunk(
  "transHistories/getTransactionHistoryCsv",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/account/csv?${buildQueryString(payload)}`;
      let res = await API.post(config.peatioWithHeader)(url);
      dispatch(stopLoading());
      return { list: res.data, total: res.headers.total, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);