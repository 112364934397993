import { createSlice } from "@reduxjs/toolkit";
import {
  getAutoGridData,
  getFavMarketList,
  getGridBotSettings,
  getGridDetailOrderHistory,
  getGridDetailPendingOrder,
  getGridInfo,
  getGridSettingDetail,
  getGridSettings,
  getMarketGraph,
  getMarketTickers,
  getMarkets,
  getOrderBook,
  getOrderHistory,
  getProgressBarData,
  getSaveChart,
  getSingleMarketTickers,
  getSingleMarketTickersData,
  getTrades,
  getTradingRules,
  submitOrders,
} from "../../services";
import { klineArrayToObject } from "../../../helpers/ranger";
import {
  getCloneData,
  insertOrUpdate,
  insertOrUpdateHistory,
} from "../../../helpers";

const initialState = {
  orderBookList: [],
  saveChartData: [],
  chartLoader: false,
  tradeList: [],
  tradesCount: 0,
  marketList: [],
  currentMarket: {},
  singleMarketTickers: {},
  singleMarketTickersData: {},
  marketTickers: {},
  currentMarketId: "",
  baseCurrency: {},
  success: false,
  error: false,
  loading: false,
  kline: {
    data: [],
    last: undefined,
    loading: false,
    marketId: undefined,
    period: undefined,
  },
  klinePeriod: "1m",
  askCurrentValue: undefined,
  bidCurrentValue: "",
  orderHistory: [],
  allOrders: [],
  allOrdersCount: 0,
  tradingRules: [],
  marketGraph: [],
  favMarketList: [],
  tradeType: "spot",
  gridSettingsList: [],
  gridSettingsListTotal: 0,
  autoGridData: [],
  autoGridParam: undefined,
  selectedGridPane: "Running",
  gridSettingDetail: {},
  gridDetailsLoading: false,
  gridSettingPendingOrders: [],
  gridSettingOrdersHistory: [],
  filter: {},
  gridLoading: "",
  gridBotSetting: {},
  gridInfo: {},
  currTab: "All Orders",
  orderBookPercentage: [],
  isVisible: true,
  openOrderTabs: "active",
  isAllPairChecked: true,
  selectedPrecision: {},
  submitOrdersLoader: false,
  orderHistoryLoading: false
};

export const exchangeSlice = createSlice({
  name: "exchangeSlice",
  initialState: initialState,
  reducers: {
    setCurrentMarket: (state, action) => {
      state.currentMarket = action.payload.currentMarket;
      state.currentMarketId = action.payload.currentMarketId;
    },
    setBaseCurrency: (state, action) => {
      state.baseCurrency = action.payload.baseCurrency;
    },
    setMarketTickers: (state, action) => {
      state.marketTickers = action.payload.marketTickers;
    },
    setSingleTickerData: (state, action) => {
      state.singleMarketTickersData = action.payload.singleTickerData;
    },
    setOpenOrderTabs: (state, action) => {
      state.openOrderTabs = action.payload;
    },
    setOrderBookPercentage: (state, action) => {
      state.orderBookPercentage = action.payload.orderBookPercentage;
    },

    setOrderBook: (state, action) => {
      state.orderBookList = action.payload.orderBook;
    },

    setTrades: (state, action) => {
      let trades = getCloneData(state.tradeList);
      if (trades) {
        trades.splice(0, 1);
        trades.push(action?.payload?.trade);
        state.tradeList = trades;
      }
    },
    resetCurrentMarketId: (state, action) => {
      state.currentMarketId = "";
    },
    setKlinePeriod: (state, action) => {
      state.klinePeriod = action.payload;
    },
    setKlineData: (state, action) => {
      state.kline.data = [];
      state.kline.last = klineArrayToObject(action.payload.last);
      state.kline.marketId = action.payload.marketId;
      state.kline.period = action.payload.period;
    },
    resetKlineData: (state, action) => {
      state.kline.data = [];
      state.kline.last = undefined;
      state.kline.marketId = undefined;
      state.kline.period = undefined;
    },
    setAskVal: (state, action) => {
      state.askCurrentValue = action?.payload;
    },
    setBidVal: (state, action) => {
      state.bidCurrentValue = action?.payload;
    },
    resetBidVal: (state, action) => {
      state.bidCurrentValue = "";
    },
    setSuccess: (state, action) => {
      state.success = action?.payload;
    },
    setExchangeState: (state, action) => {
      state[action?.payload?.key] = action?.payload?.data;
    },
    updateOpenOrder: (state, action) => {
      let openOrderList = getCloneData(state.orderHistory);
      let newData = insertOrUpdate(openOrderList, action.payload);
      state.orderHistory = newData;
    },
    updateAllOrder: (state, action) => {
      let allOrderList = getCloneData(state.allOrders);
      const arr = insertOrUpdateHistory(allOrderList, action.payload);
      state.allOrders = arr;
    },
    setActiveTradeType: (state, action) => {
      state.tradeType = action.payload;
    },
    copyAutogridData: (state, action) => {
      state.autoGridParam = action?.payload;
    },
    setGridPane: (state, action) => {
      state.selectedGridPane = action?.payload;
    },
    setFilter: (state, action) => {
      state.filter = action?.payload;
    },
    setCurrentTabForLocal: (state, action) => {
      state.currTab = action?.payload;
    },
    toggleIsVisible: (state) => {
      state.isVisible = !state.isVisible;
    },
    setIsAllPairChecked: (state, action) => {
      state.isAllPairChecked = action?.payload;
    },
    setSelectedPrecisionOrderBook: (state, action) => {
      state.selectedPrecision = action?.payload;
    },
    resetMarkets: (state) => {
      state.marketList = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrderBook.pending, (state) => {
        state.error = false;
        state.orderBookList = [];
      })
      .addCase(getOrderBook.fulfilled, (state, action) => {
        state.loading = false;
        state.orderBookList = action.payload.list;
      })
      .addCase(getOrderBook.rejected, (state, action) => {
        state.error = true;
      })

      // getSaveChart

      .addCase(getSaveChart.pending, (state) => {
        state.error = false;
        state.chartLoader = false;
        state.saveChartData = [];
      })
      .addCase(getSaveChart.fulfilled, (state, action) => {
        state.loading = false;
        state.chartLoader = true;
        state.saveChartData = action?.payload?.list;
      })
      .addCase(getSaveChart.rejected, (state, action) => {
        (state.chartLoader = false), (state.error = true);
      })

      .addCase(getTrades.pending, (state) => {
        state.error = false;
        state.tradeList = [];
      })
      .addCase(getTrades.fulfilled, (state, action) => {
        state.loading = false;
        state.tradeList = action?.payload?.list;
        state.tradesCount = action?.payload?.total;
      })
      .addCase(getTrades.rejected, (state, action) => {
        state.error = true;
      })
      .addCase(getMarkets.pending, (state) => {
        state.error = false;
      })
      .addCase(getMarkets.fulfilled, (state, action) => {
        state.loading = false;
        state.marketList = action?.payload?.list;
      })
      .addCase(getMarkets.rejected, (state, action) => {
        state.error = true;
      })
      .addCase(getMarketTickers.pending, (state) => {
        state.error = false;
      })
      .addCase(getMarketTickers.fulfilled, (state, action) => {
        state.loading = false;
        state.marketTickers = action.payload.list;
      })
      .addCase(getMarketTickers.rejected, (state, action) => {
        state.error = true;
      })
      .addCase(getSingleMarketTickers.pending, (state) => {
        state.error = false;
      })
      .addCase(getSingleMarketTickers.fulfilled, (state, action) => {
        state.loading = false;
        state.singleMarketTickers = action.payload?.list?.ticker;
      })
      .addCase(getSingleMarketTickers.rejected, (state, action) => {
        state.error = true;
      })

      .addCase(getSingleMarketTickersData.pending, (state) => {
        state.error = false;
      })
      .addCase(getSingleMarketTickersData.fulfilled, (state, action) => {
        state.loading = false;
        state.singleMarketTickersData = action.payload?.list?.ticker;
      })
      .addCase(getSingleMarketTickersData.rejected, (state, action) => {
        state.error = true;
      })

      .addCase(getOrderHistory.pending, (state) => {
        state.error = false;
        // state.orderHistory = [];
        state.allOrders = [];
        state.orderHistoryLoading = true;
      })
      .addCase(getOrderHistory.fulfilled, (state, action) => {
        state.orderHistoryLoading = false;
        state.total = action.payload.total;
        if (action.payload.callFrom === "setting") {
          state.allOrders = action?.payload?.list;
          state.allOrdersCount = action?.payload?.total ?? 0;
        } else {
          if (action?.payload?.state === "wait") {
            state.orderHistory = action?.payload?.list;
          } else {
            state.allOrders = action?.payload?.list;
            state.allOrdersCount = action?.payload?.total ?? 0;
          }
        }
      })
      .addCase(getOrderHistory.rejected, (state, action) => {
        state.error = true;
        state.orderHistoryLoading = false;
      })

      .addCase(getTradingRules.pending, (state) => {
        state.error = false;
      })
      .addCase(getTradingRules.fulfilled, (state, action) => {
        state.loading = false;
        state.tradingRules = action?.payload?.list;
      })
      .addCase(getTradingRules.rejected, (state, action) => {
        state.error = true;
      })
      .addCase(getMarketGraph.pending, (state) => {
        state.error = false;
      })
      .addCase(getMarketGraph.fulfilled, (state, action) => {
        state.loading = false;
        state.graphData = action.payload.list;
      })
      .addCase(getMarketGraph.rejected, (state, action) => {
        state.error = true;
      })
      .addCase(getFavMarketList.pending, (state) => {
        state.error = false;
      })
      .addCase(getFavMarketList.fulfilled, (state, action) => {
        state.loading = false;
        state.favMarketList = action.payload.list;
      })
      .addCase(getFavMarketList.rejected, (state, action) => {
        state.error = true;
      })
      .addCase(getGridSettings.pending, (state, action) => {
        state.loading = true;
        state.gridSettingsList = [];
        state.gridLoading = "";
      })

      .addCase(getGridSettings.fulfilled, (state, action) => {
        state.loading = false;
        state.gridSettingsList = action.payload.list;
        state.gridSettingsListTotal = action.payload.total;
        state.gridLoading = "loaded";
      })

      .addCase(getGridSettings.rejected, (state, action) => {
        state.loading = false;
        state.error = false;
        state.gridSettingsList = [];
        state.gridLoading = "";
      })

      .addCase(getAutoGridData.pending, (state, action) => {
        state.loading = true;
        state.autoGridData = [];
      })

      .addCase(getAutoGridData.fulfilled, (state, action) => {
        state.loading = false;
        state.autoGridData = action.payload.list;
      })

      .addCase(getAutoGridData.rejected, (state, action) => {
        state.loading = false;
        state.error = false;
        state.autoGridData = [];
      })

      .addCase(getGridSettingDetail.pending, (state, action) => {
        state.loading = true;
        state.gridDetailsLoading = true;
      })

      .addCase(getGridSettingDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.gridDetailsLoading = false;
        state.gridSettingDetail =
          action.payload.res !== null ? action.payload.res : {};
      })

      .addCase(getGridSettingDetail.rejected, (state, action) => {
        state.loading = false;
        state.error = false;
        state.gridDetailsLoading = false;
        state.gridSettingDetail = {};
      })

      .addCase(getGridDetailPendingOrder.pending, (state, action) => {
        state.loading = true;
        state.gridSettingPendingOrders = [];
      })

      .addCase(getGridDetailPendingOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.gridSettingPendingOrders = action.payload.list;
      })

      .addCase(getGridDetailPendingOrder.rejected, (state, action) => {
        state.loading = false;
        state.error = false;
        state.gridSettingPendingOrders = [];
      })

      .addCase(getGridDetailOrderHistory.pending, (state, action) => {
        state.loading = true;
        state.gridSettingOrdersHistory = {};
      })

      .addCase(getGridDetailOrderHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.gridSettingOrdersHistory = action.payload.data;
      })

      .addCase(getGridDetailOrderHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = false;
        state.gridSettingOrdersHistory = [];
      })
      .addCase(getGridBotSettings.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(getGridBotSettings.fulfilled, (state, action) => {
        state.loading = false;
        state.gridBotSetting = action.payload.data;
      })

      .addCase(getGridBotSettings.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(getGridInfo.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(getGridInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.gridInfo = action.payload.data;
      })

      .addCase(getGridInfo.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(getProgressBarData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getProgressBarData.fulfilled, (state, action) => {
        state.loading = false;
        state.orderBookPercentage = action.payload.data;
      })
      .addCase(getProgressBarData.rejected, (state, action) => {
        state.loading = false;
      })

      // submitOrders

      .addCase(submitOrders.pending, (state) => {
        state.submitOrdersLoader = true;
      })
      .addCase(submitOrders.fulfilled, (state, action) => {
        state.submitOrdersLoader = false;
      })
      .addCase(submitOrders.rejected, (state) => {
        state.submitOrdersLoader = false;
      });
  },
});

export const {
  setCurrentMarket,
  setFilter,
  setCurrentTabForLocal,
  setBaseCurrency,
  setMarketTickers,
  setOrderBook,
  setSingleTickerData,
  setTrades,
  resetCurrentMarketId,
  setKlineData,
  setKlinePeriod,
  setAskVal,
  setBidVal,
  resetBidVal,
  resetKlineData,
  setSuccess,
  setExchangeState,
  updateOpenOrder,
  updateAllOrder,
  setActiveTradeType,
  copyAutogridData,
  setGridPane,
  setOrderBookPercentage,
  toggleIsVisible,
  setOpenOrderTabs,
  setIsAllPairChecked,
  setSelectedPrecisionOrderBook,
  resetMarkets,
} = exchangeSlice.actions;
export default exchangeSlice.reducer;
