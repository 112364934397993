import React, { useState } from "react";
import style from "./style.module.scss";
import { Row, Col } from "antd";
import { StoreImages } from "../../Storeimgaes/StoreImages";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { getBalanceHistory, getCopyCurrBalance, getGridCurrBalance, getSingleCurrBalance } from "../../../redux/services";
import { CheckPrecision, convertToThousandDigit, dateToUtc, exponentialToDecimalConvert, truncateData2, uppercase } from "../../../helpers";
import { useTranslation } from "react-i18next";
import { NoRecord } from "../../NoRecord";
import CustomPagination from "../../customHooks/customPagination";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
const initData = {
  page: 1,
  limit: 10,
};
const PrimaryBalanceTab = () => {
  const { t } = useTranslation();
  const { flow } = StoreImages;
  const { id } = useParams();
  const dispatch = useDispatch();
  const { singleCurrBal, singleCurrGridBal, singleCurrCopyBal } = useSelector((state) => state.commonApiData);
  const { balanceHistoryTotal, balanceHistory } = useSelector(
    (state) => state.commonApiData
  );
  const { currenciesList } = useSelector((state) => state?.commonApiData);
  const [showEstBal, setShowEstBal] = useState(true);
  const [isEyeOpen, setIsEyeOpen] = useState(true);
  const [currentBalance, setCurrentBalance] = useState(0);
  const [totalUsdtVal, setTotalUsdtVal] = useState(0);
  const [usdtBal, setUsdtVal] = useState(0);
  const [paginationState, setPaginationState] = useState({ ...initData });
  const { page, limit } = paginationState;

  const apiCall = (data) => {
    dispatch(
      getBalanceHistory({
        limit: limit,
        page: page,
        currency_id: id,
        ...data,
      })
    );
  };

  useEffect(() => {
    apiCall({});
    dispatch(getSingleCurrBalance({
      currency: id
    }));
    dispatch(getGridCurrBalance({
      currency: id
    }));
    dispatch(getCopyCurrBalance({
      currency: id
    }));
  }, []);

  const solveBal = (bal, currency) => {
    return exponentialToDecimalConvert(
      truncateData2(Number(bal), CheckPrecision(currenciesList, currency))
    );
  };

  const filterBalance = (id) => {
    let totalInOrder = (Number(singleCurrBal?.locked) + Number(singleCurrBal?.escrow_balance)) || 0;
    let totalSpot = Number(singleCurrBal?.balance) || 0;
    let totalP2P = Number(singleCurrBal?.p2p_balance) || 0;
    let gridBalance = Number(singleCurrGridBal?.balance) || 0;
    let copyBalance = Number(singleCurrCopyBal?.balance) || 0;
    let total = solveBal(totalSpot + totalP2P + totalInOrder + gridBalance + copyBalance, id);
    const usdtValue = Number(singleCurrBal?.currency_usdt ?? 0);
    let totalInUsd = Number(total * usdtValue).toFixed(2);
    setCurrentBalance(total);
    setTotalUsdtVal(totalInUsd)
    setUsdtVal(usdtValue);
    return total;
  };

  useEffect(() => {
    filterBalance(id);
  }, [singleCurrBal, singleCurrGridBal, singleCurrCopyBal]);

  const balance = Number(currentBalance?.balance ?? 0);
  const locked = Number(currentBalance?.locked ?? 0);
  const usdt_value = Number(currentBalance?.currency_usdt ?? 0);
  const currency = currentBalance?.currency ?? "";
  const btnStatus = localStorage.getItem("setBal");

  const balanceString = `${(balance + locked)
    .toFixed(2)
    .replace(/\.0+$/, "")} ${uppercase(currency)} ($${(
      (balance + locked) *
      usdt_value
    )
      .toFixed(2)
      .replace(/\.0+$/, "")})`;

  const renderNode = (
    reference_type,
    currUpercase,
    fiatcurrUpercase,
    type,
    pair,
    reference_kind
  ) => {
    switch (reference_type) {
      case "transakpayment":
        return (
          <>
            <span>
              {type === "SELL"
                ? `${t("primary.sell")} ${currUpercase} ${t(
                  "primary.to"
                )} ${fiatcurrUpercase}`
                : `${t("primary.buy")} ${currUpercase} ${t(
                  "primary.with"
                )} ${fiatcurrUpercase}`}
            </span>
            {t("primary.using")} {currUpercase} {t("primary.wallet")}
          </>
        );

      case "deposit":
        return (
          <>
            <span>{`Deposit ${currUpercase}`}</span>
            {t("primary.from")} {currUpercase} {t("primary.deposit")}
          </>
        );

      case "withdraw":
        return (
          <>
            <span>{`${t("primary.withdraw")} ${currUpercase}`}</span>
            {t("primary.totext")} {currUpercase} {t("primary.address")}
          </>
        );

      case "adjustment":
        return (
          <>
            <span>{`${t("primary.deposit")} ${currUpercase}`}</span>
            {t("primary.totext")} {currUpercase}
          </>
        );

      case "account":
        switch (reference_kind) {
          case "transfer_to_gridbot":
            return (
              <>
                <span>{t("primary.gridbot")}</span>
                {t("primary.from")} {currUpercase} {t("primary.wallet")}
              </>
            );

          case "transfer_from_gridbot":
            return (
              <>
                <span>{t("primary.gridbotto")}</span>
                {t("primary.totext")} {currUpercase} {t("primary.wallet")}
              </>
            );

          case "transfer_to_copybot":
            return (
              <>
                <span>{t("primary.copybot")}</span>
                {t("primary.from")} {currUpercase} {t("primary.wallet")}
              </>
            );
          case "transfer_from_copybot":
            return (
              <>
                <span>{t("primary.copybotto")}</span>
                {t("primary.totext")} {currUpercase} {t("primary.wallet")}
              </>
            );
          default:
            return (
              <>
                <span>{`${t("primary.transferfrom")} ${currUpercase}
                            `}</span>
                {t("primary.using")} {currUpercase} {t("primary.wallet")}
              </>
            );
        }

      case "redeemreward":
        return (
          <>
            <span>{`${t("primary.redeem")} ${currUpercase}
            `}</span>
            {t("primary.in")} {currUpercase} {t("primary.wallet")}
          </>
        );

      case "transfer_to_gridbot":
        return (
          <>
            <span>{t("primary.gridbot")}</span>
            {t("primary.from")} {currUpercase} {t("primary.wallet")}
          </>
        );

      case "transfer_from_gridbot":
        return (
          <>
            <span>{t("primary.gridbotto")}</span>
            {t("primary.totext")} {currUpercase} {t("primary.wallet")}
          </>
        );

      case "transfer_to_copybot":
        return (
          <>
            <span>{t("primary.copybot")}</span>
            {t("primary.from")} {currUpercase} {t("primary.wallet")}
          </>
        );

      case "transfer_from_copybot":
        return (
          <>
            <span>{t("primary.copybotto")}</span>
            {t("primary.totext")} {currUpercase} {t("primary.wallet")}
          </>
        );

      case "spot_from_p2p":
        return (
          <>
            <span>{t("primary.p2p")}</span>
            {t("primary.totext")} {currUpercase} {t("primary.wallet")}
          </>
        );

      case "spot_to_p2p":
        return (
          <>
            <span>{t("primary.p2pto")}</span>
            {t("primary.totext")} {currUpercase} {t("primary.wallet")}
          </>
        );

      default:
        if (reference_type.includes("trade")) {
          return (
            <>
              <span>{`${t("primary.trade")} ${currUpercase}`}</span>
              {t("primary.usingpair")} {currUpercase}
            </>
          );
        } else if (reference_type.includes("order")) {
          return (
            <>
              <span>{t("primary.order")}</span>
              {t("primary.usingpair")} {uppercase(pair)}
            </>
          );
        } else {
          return (
            <>
              <span>{uppercase(reference_type)}</span>
            </>
          );
        }
        break;
    }
  };

  useEffect(() => {
    if (btnStatus === null || btnStatus === "true") {
      setShowEstBal(true);
    } else {
      setShowEstBal(false);
    }
  }, [btnStatus]);

  const toggleEye = () => {
    setIsEyeOpen((prevIsEyeOpen) => !prevIsEyeOpen);
  };

  return (
    <>
      <div className={`${style.cointdetailPage_tabs_balance} ${style.mt} `}>
        <div className="totalWithEyeIcon">
          <h4>
            {t("portfolio.balance")}: <span>
              {showEstBal ? `${currentBalance ? convertToThousandDigit(currentBalance) : 0} ${uppercase(id)} ($${convertToThousandDigit(totalUsdtVal)})` : "*****" + " " + uppercase(id)}
            </span>
          </h4>
          {currentBalance > 0 && <span className={`${style.openeye} overviewPageIcon`} onClick={toggleEye}>
            {showEstBal ? (
              <EyeOutlined
                onClick={() => {
                  localStorage.setItem("setBal", false);
                }}
              />
            ) : (
              <EyeInvisibleOutlined
                onClick={() => {
                  localStorage.setItem("setBal", true);
                }}
              />
            )}
          </span>}
        </div>
        <div className={style.scrolledEleMob}>
          {balanceHistory?.length > 0 ? (
            balanceHistory?.map((item, index) => {
              let currUpercase = uppercase(item?.currency);
              let fiatcurrUpercase = uppercase(item?.fait_currency);
              let isSwapP2PBal = item.reference_kind === "swap_p2p_balance";
              let prevBal = Number(item.previous_balance);
              let newBal = Number(item?.new_balance);
              let prevSubNewUsdt = (prevBal - newBal) * usdtBal;
              let newSubPrevUsdt = (newBal - prevBal) * usdtBal;
              let pair = item?.reference_type.split(":")[2];
              return (
                <Row>
                  <Col span={24}>
                    <div key={index} className={style.primarybal}>
                      <div className={style.primarybal_left}>
                        <p>
                          {dateToUtc(item?.created_at, "MMM")}{" "}
                          <span>{dateToUtc(item?.created_at, "DD")}</span>
                        </p>
                        <img src={flow} alt="icon" />
                        <p>
                          {item?.reference_type
                            ? renderNode(
                              item?.reference_type,
                              currUpercase,
                              fiatcurrUpercase,
                              item?.transak_type,
                              pair,
                              item?.reference_kind
                            )
                            : null}
                        </p>
                      </div>
                      <div
                        className={`primarybal_right ${style.primarybal_right}`}
                      >
                        <p className={style.baseblue}>
                          {item?.reference_type === "account" && isSwapP2PBal
                            ? convertToThousandDigit(truncateData2(
                              item?.previous_balance - item?.new_balance,
                              item.precision)
                            )
                            : convertToThousandDigit(truncateData2(
                              item?.new_balance - item?.previous_balance,
                              item.precision
                            ))}{" "}
                          {currUpercase}
                        </p>
                        <p>
                          $
                          {item?.reference_type === "account" && isSwapP2PBal
                            ? convertToThousandDigit(truncateData2(prevSubNewUsdt, 2))
                            : convertToThousandDigit(truncateData2(newSubPrevUsdt, 2))}
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              );
            })
          ) : (
            <div className="empty_data">
              <NoRecord />
            </div>
          )}
        </div>
        <CustomPagination
          pageNo={page}
          limit={limit}
          total={balanceHistoryTotal}
          onChange={(pageNo, pageSize) => {
            apiCall({ page: pageNo, limit: pageSize });
            setPaginationState({
              ...paginationState,
              page: pageNo,
              limit: pageSize,
            });
          }}
        />
      </div>
    </>
  );
};

export default PrimaryBalanceTab;
