import { createSlice } from "@reduxjs/toolkit"; 
import { getTheme } from "../../../helpers";

export const themeSlice = createSlice({

  name: "alert",
  initialState: {
    themeMode: getTheme() === "theme-light" ? "theme-light" : "theme-dark",
  },
  reducers: {
    setTheme: (state, action) => { 
      state.themeMode = action.payload;
    },
    setLanguageCurr: (state, action) => {
      state.language = action.payload;
    },
  },
});
export default themeSlice.reducer;
export const { setTheme, setLanguageCurr } = themeSlice.actions;
