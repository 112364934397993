import { createSlice } from "@reduxjs/toolkit";
import {
  fetchPublicSubscriber,
  generateQrCode,
  getAnnouncementBanner,
  getAvailableSwapPairs,
  getBalance,
  getBalanceHistory,
  getCopyBalance,
  getCopyCurrBalance,
  getCurrencies,
  getCurrenciesTicker,
  getCurrencyDetails,
  getFeeRebateSetting,
  getGainersLosers,
  getGraphData,
  getGridBalance,
  getGridCurrBalance,
  getHideZero,
  getPnlTimePeriod,
  getPortfolioPrice,
  getPublicSetting,
  getSelectedFiatCurr,
  getSingleCurrBalance,
  getSingleCurrencyDetails,
  getSwapSetting,
  getTotalUsdtBal,
  getUserRebertData,
  getUserSettings,
  getUserSettingsPetio,
  initContact,
} from "../../services";
export const commonSlice = createSlice({
  name: "commonSlice",
  initialState: {
    balanceList: [],
    limitedBalanceList: [],
    currenciesList: [],
    limitedCurrenciesList: [],
    currenciesListWithGraphData: [],
    error: false,
    currencyDetails: [],
    balanceHistory: [],
    balanceHistoryTotal: 0,
    singleCurrency: {},
    getCode: "",
    clickViewFrom: "",
    currentMarketId: "",
    gainersLosers: [],
    currencyGraphData: [],
    portfolioPrices: {},
    phoneSubmited: false,
    timeFrame: 'hr',
    setting: {},
    settingPetio: {},
    withdrawType: "",
    gridBalanceList: [],
    copyBalanceList: [],
    total_usdt_bal: "",
    hideZero: false,
    currenciesTicker: {},
    singleCurrBal: [],
    singleCurrGridBal: [],
    singleCurrCopyBal: [],
    fiatCurrency: "",
    availableSwapPairsList: [],
    swapSettings: {},
    publicSettings: {},
    feeRebateSetting: [],
    userRebateData : {},
    announcementList:[],
    pnlTimePeriod: "",
    loading:false,
    loadingBalances:false,
    currencyLoading: false,
    subscriber: {},
    contactLoading: false,
    reqFromBussinessPartners: false
  },
  reducers: {
    viewForTrans: (state, action) => {
      state.clickViewFrom = action?.payload?.clickViewFrom;
      state.currentMarketId = action?.payload?.marketId;
    },
    phoneSubmited: (state, action) => {
      state.phoneSubmited = action.payload;
    },
    graphTimeFrame: (state, action) => {
      state.timeFrame = action.payload;
    },
    setWithdrawType: (state, action) => {
      state.withdrawType = action.payload;
    },
    setCurrenciesTickers: (state, action) => {
      state.currenciesTicker = action.payload.currenciesTicker;
    },
    setRequestFromBussPartners: (state, action) => {
      state.reqFromBussinessPartners = action.payload.reqFromBussinessPartners;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBalance.pending, (state) => {
        state.error = false;
        state.loadingBalances = true;
        state.balanceList = [];
        state.limitedBalanceList = [];
      })
      .addCase(getBalance.fulfilled, (state, action) => {
        state.loadingBalances = false;
        if(action?.payload?.limited){
          state.limitedBalanceList = action.payload.list;
        }else{
          state.balanceList = action.payload.list;
        }
      })
      .addCase(getBalance.rejected, (state, action) => {
        state.error = true;
        state.loadingBalances = false;
      })
      .addCase(getCurrencies.pending, (state) => {
        state.error = false;
        state.currencyLoading = true;

      })
      .addCase(getCurrencies.fulfilled, (state, action) => {
        state.currencyLoading = false;
        if(action.payload.graph_req){
          state.currenciesListWithGraphData = action.payload.list;
        }else if(action?.payload?.limited){
          state.limitedCurrenciesList = action.payload.list;
        }else{
          state.currenciesList = action.payload.list;
        }
      })
      .addCase(getCurrencies.rejected, (state, action) => {
        state.currencyLoading = false; 
        state.error = true;
      })
      .addCase(getCurrencyDetails.pending, (state) => {
        state.error = false;
      })
      .addCase(getCurrencyDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.currencyDetails = action.payload.list;
      })
      .addCase(getCurrencyDetails.rejected, (state) => {
        state.error = true;
      })

      .addCase(getGraphData.pending, (state) => { })
      .addCase(getGraphData.fulfilled, (state, action) => {
        state.currencyGraphData = action.payload.list;
      })
      .addCase(getGraphData.rejected, (state) => { })

      .addCase(getSingleCurrencyDetails.pending, (state) => {
        state.error = false;
      })
      .addCase(getSingleCurrencyDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.singleCurrency = action.payload;
      })
      .addCase(getSingleCurrencyDetails.rejected, (state) => {
        state.error = true;
      })

      .addCase(getBalanceHistory.pending, (state) => {
        state.error = false;
      })
      .addCase(getBalanceHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.balanceHistory = action.payload.data;
        state.balanceHistoryTotal = action.payload.total || 0;
      })
      .addCase(getBalanceHistory.rejected, (state) => {
        state.error = true;
      })

      .addCase(generateQrCode.pending, (state) => {
        state.error = false;
      })
      .addCase(generateQrCode.fulfilled, (state, action) => {
        state.loading = false;
        state.getCode = action.payload.qrCode;
      })
      .addCase(generateQrCode.rejected, (state) => {
        state.error = true;
      })
      .addCase(fetchPublicSubscriber.pending, (state) => {
        state.error = false;
      })
      .addCase(fetchPublicSubscriber.fulfilled, (state, action) => {
        state.loading = false;
        state.subscriber = action.payload;
      })
      .addCase(fetchPublicSubscriber.rejected, (state) => {
        state.error = true;
      })

      .addCase(getGainersLosers.pending, (state) => { })
      .addCase(getGainersLosers.fulfilled, (state, action) => {
        state.gainersLosers = action.payload.data;
      })
      .addCase(getGainersLosers.rejected, (state) => { })

      .addCase(getPortfolioPrice.pending, (state) => { })
      .addCase(getPortfolioPrice.fulfilled, (state, action) => {
        state.portfolioPrices = action.payload;
      })
      .addCase(getPortfolioPrice.rejected, (state) => { })
      .addCase(getUserSettings.pending, (state) => { })
      .addCase(getUserSettings.fulfilled, (state, action) => {
        state.setting = action.payload;
      })
      .addCase(getUserSettings.rejected, (state) => { })

      .addCase(getUserSettingsPetio.pending, (state) => { })
      .addCase(getUserSettingsPetio.fulfilled, (state, action) => {
        state.settingPetio = action.payload;
      })
      .addCase(getUserSettingsPetio.rejected, (state) => { })

      .addCase(getGridBalance.pending, (state) => {
        state.error = false;
      })
      .addCase(getGridBalance.fulfilled, (state, action) => {
        state.loading = false;
        state.gridBalanceList = action.payload.list;
      })
      .addCase(getGridBalance.rejected, (state, action) => {
        state.error = true;
      })
      .addCase(getCopyBalance.pending, (state) => {
        state.error = false;
      })
      .addCase(getCopyBalance.fulfilled, (state, action) => {
        state.loading = false;
        state.copyBalanceList = action.payload.list;
      })
      .addCase(getCopyBalance.rejected, (state, action) => {
        state.error = true;
      })
      .addCase(getTotalUsdtBal.pending, (state) => {
        state.error = false;
      })
      .addCase(getTotalUsdtBal.fulfilled, (state, action) => {
        state.loading = false;
        state.total_usdt_bal = action.payload.data;
      })
      .addCase(getTotalUsdtBal.rejected, (state) => {
        state.error = true;
      }).addCase(getHideZero.pending, (state) => {
        state.error = false;
      })
      .addCase(getHideZero.fulfilled, (state, action) => {
        state.loading = false;
        state.hideZero = action.payload.data;
      })
      .addCase(getHideZero.rejected, (state, action) => {
        state.error = true;
      })
      .addCase(getCurrenciesTicker.pending, (state) => {
        state.error = false;
      })
      .addCase(getCurrenciesTicker.fulfilled, (state, action) => {
        state.loading = false;
        state.currenciesTicker = action.payload.data;
      })
      .addCase(getCurrenciesTicker.rejected, (state, action) => {
        state.error = true;
      })

      .addCase(getSingleCurrBalance.pending, (state) => {
        state.singleCurrBal = []
        state.error = false;
      })
      .addCase(getSingleCurrBalance.fulfilled, (state, action) => {
        state.loading = false;
        state.singleCurrBal = action.payload.list;
      })
      .addCase(getSingleCurrBalance.rejected, (state, action) => {
        state.error = true;
      })

      .addCase(getGridCurrBalance.pending, (state) => {
        state.singleCurrGridBal = [];
        state.error = false;
      })
      .addCase(getGridCurrBalance.fulfilled, (state, action) => {
        state.loading = false;
        state.singleCurrGridBal = action.payload.list;
      })
      .addCase(getGridCurrBalance.rejected, (state, action) => {
        state.error = true;
      })

      .addCase(getCopyCurrBalance.pending, (state) => {
        state.singleCurrCopyBal = [];
        state.error = false;
      })
      .addCase(getCopyCurrBalance.fulfilled, (state, action) => {
        state.loading = false;
        state.singleCurrCopyBal = action.payload.list;
      })
      .addCase(getCopyCurrBalance.rejected, (state, action) => {
        state.error = true;
      })

      .addCase(getSelectedFiatCurr.pending, (state) => {
        state.error = false;
      })
      .addCase(getSelectedFiatCurr.fulfilled, (state, action) => {
        state.fiatCurrency = action.payload;
      })
      .addCase(getSelectedFiatCurr.rejected, (state) => {
        state.error = true;
      })


      .addCase(getAvailableSwapPairs.pending, (state) => {
        state.error = false;
      })
      .addCase(getAvailableSwapPairs.fulfilled, (state, action) => {
        state.availableSwapPairsList = action.payload;
      })
      .addCase(getAvailableSwapPairs.rejected, (state) => {
        state.error = true;
      })

      .addCase(getSwapSetting.pending, (state) => {
        state.error = false;
        
      })
      .addCase(getSwapSetting.fulfilled, (state, action) => {
        state.swapSettings = action.payload.data === null ? {} : action.payload.data; 
      })
      .addCase(getSwapSetting.rejected, (state, action) => {
        state.error = true;
      })
      
      .addCase(getPublicSetting.pending, (state) => {
        state.error = false;
        
      })
      .addCase(getPublicSetting.fulfilled, (state, action) => {
        state.publicSettings = action.payload.data;
      })
      .addCase(getPublicSetting.rejected, (state, action) => {
        state.error = true;
      })
      .addCase(getFeeRebateSetting.pending, (state) => {
        state.error = false;
        
      })
      .addCase(getFeeRebateSetting.fulfilled, (state, action) => {
        state.feeRebateSetting = action.payload.data;
      })
      .addCase(getFeeRebateSetting.rejected, (state, action) => {
        state.error = true;
      })
      .addCase(getUserRebertData.pending, (state) => {
        state.error = false;
        
      })
      .addCase(getUserRebertData.fulfilled, (state, action) => {
        state.userRebateData = action.payload.data;
      })
      .addCase(getUserRebertData.rejected, (state, action) => {
        state.error = true;
      })


      .addCase(getAnnouncementBanner.pending, (state) => {
        state.error = false;
      })
      .addCase(getAnnouncementBanner.fulfilled, (state, action) => {
        state.announcementList = action.payload.list;
      })
      .addCase(getAnnouncementBanner.rejected, (state, action) => {
        state.error = true;
      })


      .addCase(getPnlTimePeriod.pending, (state) => {
        state.error = false;
      })
      .addCase(getPnlTimePeriod.fulfilled, (state, action) => {
        state.pnlTimePeriod = action.payload.data;
      })
      .addCase(getPnlTimePeriod.rejected, (state, action) => {
        state.error = true;
      })

      .addCase(initContact.pending, (state) => {
        state.contactLoading = true;
        state.error = false;
      })
      .addCase(initContact.fulfilled, (state, action) => {
        state.contactLoading = false;
      })
      .addCase(initContact.rejected, (state, action) => {
        state.contactLoading = false;
        state.error = true;
      })
  },
});

export const { viewForTrans, graphTimeFrame, phoneSubmited, setWithdrawType, setCurrenciesTickers,setRequestFromBussPartners } = commonSlice.actions;
export default commonSlice.reducer;
