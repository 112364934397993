import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, withTranslation } from "react-i18next";
import { generateSocketURI, streamsBuilder } from "../helpers/ranger";
import { rangerUrl } from "../api";
import { announceListBySocket, appendCopyBySocket, appendNewMessage, appendNewMessageP2p, insertNotificationList, setConsensusModal, setCurrenciesTickers, updateCopyOrder, updateCopyPosition, updateLimitPrice } from "../redux/feature";
import {
  setKlineData,
  setMarketTickers,
  setOrderBook,
  setOrderBookPercentage,
  setSingleTickerData,
  setTrades,
  updateAllOrder,
  updateOpenOrder,
} from "../redux/feature/exchange/exchanges.slice";
import { isAuthenticated } from "../redux/selector";
import { message,notification } from "antd";
import { getUrlPart, uppercase } from "../helpers";
import { getBalance, getCopierDetails, getCopyUsdtBalance, getGridDetailOrderHistory, getGridDetailPendingOrder, getGridSettingDetail } from "../redux/services";
import { useLocation } from "react-router-dom";
import { getAppeals } from "../redux/services/P2P";
import { processOrderBookData } from "../helpers/orderBookProcessor";

function Ranger() {
  // const { REACT_APP_SOCKET_HOST_URL } = process.env
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAuthenticate = useSelector((state) => isAuthenticated(state));
  const currTabExchange = useSelector((state) => state.exchange.currTab);
  const singlebid = useSelector((state) => state.order.singleBid);
  const uid = useSelector((state) => state.user.data.uid);
  const rangerBaseUrl = isAuthenticate
    ? `${rangerUrl()}/private`
    : `${rangerUrl()}/public`;
  const currentMarketId = useSelector(
    (state) => state?.exchange?.currentMarketId
  );
  const selectedPrecision = useSelector(
    (state) => state?.exchange?.selectedPrecision
  );
  const currentPeriod = useSelector((state) => state?.exchange?.klinePeriod);
  const { cryptoCurrencyRadio, fiatCurrencyRadio } = useSelector(
    (state) => state.selectedData.data
  );
  const wsRef = useRef(null);  // WebSocket ko store karne ke liye
  const reconnectTimerRef = useRef(null);  // Reconnect timer ko track karne ke liye

  const streams = streamsBuilder(currentMarketId, currentPeriod, uid,selectedPrecision.value);
  const urlPart = getUrlPart(2, location.pathname);

  const handleNotification = (type, data, notificationKey) => {
    dispatch(insertNotificationList(data));
    const messageData = data?.message[1];
    message.info(t(`notifications.${notificationKey}`, {
      orderId: messageData?.order_id,
      bidId: messageData?.bid_id,
      user: messageData?.user,
      type: messageData?.type,
      eventId: messageData?.id,
      orderType: messageData?.order_type,
      currency: messageData?.currency,
      name: messageData?.name
    }));
  };

  const cleanUpSocket = () => {
    if (wsRef.current) {
      wsRef.current.onclose = null;
      wsRef.current.onerror = null;
      wsRef.current.onmessage = null;
      wsRef.current.close();
      wsRef.current = null;
    }

    if (reconnectTimerRef.current) {
      clearTimeout(reconnectTimerRef.current);
      reconnectTimerRef.current = null;
    }
  };
  const connectWebSocket = () => {
    cleanUpSocket(); 
    const ws = new WebSocket(generateSocketURI(rangerBaseUrl, streams));
    wsRef.current = ws;

    ws.onopen = () => {
    };

    ws.onmessage = function (event) {
      const json = JSON.parse(event.data);
      for (const routingKey in json) {
        switch (routingKey) {

          case "global.tickers":
            dispatch(setMarketTickers({ marketTickers: json[routingKey] }));
            break;
          case `${currentMarketId}.ticker`:
            dispatch(setSingleTickerData({ singleTickerData: json[routingKey] }));
            break; 
          case `${currentMarketId}.update-${selectedPrecision.value}`:

          if (json[routingKey] && (json[routingKey].bids || json[routingKey].asks)) {
            // Use a single function for both with a parameter to distinguish buy/sell
            // const ordHistry = {
            //   bids: processOrderBookData(json[routingKey].bids, selectedPrecision.value, false),
            //   asks: processOrderBookData(json[routingKey].asks, selectedPrecision.value, true)
            // };
            dispatch(setOrderBook({ orderBook: json[routingKey] }));
          }

            // dispatch(setOrderBook({ orderBook: json[routingKey] }));
            break;
            case `${currentMarketId}.d-v`:
              dispatch(setOrderBookPercentage({ orderBookPercentage: json[routingKey] }));
            break;
            
          case `${currentMarketId}.trades`:
            setTimeout(() => {
              dispatch(setTrades({ trade: json[routingKey]?.trades[0] }));
            }, 200);
            break;

          case `${currentMarketId}.kline-${currentPeriod}`:
            dispatch(
              setKlineData({
                data: [],
                last: json[routingKey],
                loading: false,
                marketId: currentMarketId,
                period: currentPeriod,
              })
            );
            break;

          case `order`:
            if (
              currTabExchange === "Trade History" &&
              ["wait", "pending"].includes(json[routingKey].state)
            ) {
            } else {
              if(json[routingKey]?.state === "done"){
                notification.success({
                  message: "Order Executed Sucessfully.",
                  placement: "topRight",
                });
              }
              dispatch(updateOpenOrder(json[routingKey]));
              dispatch(updateAllOrder(json[routingKey]));
              dispatch(getBalance())
            }
            break;

          case "appeal":
            if (json["appeal"]?.order_type === "Consensus") {
              dispatch(setConsensusModal(true));
            }
            handleNotification("appeal", json["appeal"], json["appeal"]?.message[0]);
            break;

          case `copy_order`:
            dispatch(updateCopyOrder(json[routingKey]));
            dispatch(getCopyUsdtBalance());
            break;

          case "advertisement":
            handleNotification("advertisement", json["advertisement"], json["advertisement"]?.message[0]);
            break;

          case "bid":
            handleNotification("bid", json["bid"], json["bid"]?.message[0]);
            break;

          case "admin_action":
            if (json["admin_action"]?.notifiable_type === "Appeal" &&
              json["admin_action"]?.about === "admin_message") {
              if (json["admin_action"]?.bid_id) {
                if (
                  !["buysellorderinfo",
                    "paymentStep",
                    "appeal",
                    "appealhistory",
                  ].includes(urlPart)
                ) {
                  message.info(t("notifications.new_p2p_admin_message"));
                }
                if (["appealhistory"].includes(urlPart)) {
                  dispatch(getAppeals({ bid_id: json["admin_action"]?.bid_id }));
                }
              }
            }
            else if ((json["admin_action"]?.notifiable_type === "Appeal" || json["admin_action"]?.notifiable_type === "Bid") &&
              json["admin_action"]?.about === "admin_action") {
              // message.info(json["admin_action"]?.message);
              message.info(t(`notifications.${json["admin_action"]?.message[0]}`, {
                orderId: json["admin_action"]?.message[1]?.order_id && json["admin_action"]?.message[1]?.order_id, bidId: json["admin_action"]?.message[1]?.bid_id && json["admin_action"]?.message[1]?.bid_id,
                user: json["admin_action"]?.message[1]?.user && json["admin_action"]?.message[1]?.user, type: json["admin_action"]?.message[1]?.type && json["admin_action"]?.message[1]?.type,
                eventId: json["admin_action"]?.message[1]?.id && json["admin_action"]?.message[1]?.id, orderType: json["admin_action"]?.message[1]?.order_type && json["admin_action"]?.message[1]?.order_type,
                currency: json["admin_action"]?.message[1]?.currency && json["admin_action"]?.message[1]?.currency, name: json["admin_action"]?.message[1]?.name && json["admin_action"]?.message[1]?.name
              }));
            }

            if (json["admin_action"]?.bid_id === singlebid?.id &&
              (json["admin_action"]?.message[0] === "admin_appeal_cancel_by_admin" ||
                json["admin_action"]?.message[0] === "admin_bid_release_crypto" ||
                json["admin_action"]?.message[0] === "admin_order_cancel")
            ) {
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            }

            dispatch(insertNotificationList(json["admin_action"]));
            break;

          case "message":
            // dispatch(appendNewMessageP2p(json["message"]));
            dispatch(appendNewMessage(json["message"]));
            message.info(t("notifications.new_p2p_message"));
            break;

          case "Announcement":
            if (json[routingKey]?.data?.notifiable_type === "Currency") {
              message.info(json["Announcement"]?.data?.message);
            }
            if (json[routingKey]?.data?.notifiable_type === "Member") {
              message.info(t("notifications.new_announcement"));
            }
            break;

          case `grid_data`:
            for (const key in json[routingKey]) {
              if (json[routingKey]?.message === "order_executed") {
                dispatch(
                  getGridDetailPendingOrder({
                    grid_setting_id: json[routingKey]?.id,
                  })
                );
                dispatch(
                  getGridDetailOrderHistory({
                    grid_setting_id: json[routingKey]?.id,
                  })
                );
                dispatch(
                  getGridSettingDetail({
                    id: json[routingKey]?.id,
                    loaderShow: false,
                  })
                );
              }
            }
            break;

          case `GridBotSetting`:
            message.info(
              t("notifications.grid", {
                id: json["GridBotSetting"]?.notifiable_id,
                market: `${uppercase(
                  json["GridBotSetting"]?.base_currency
                )}/${uppercase(json["GridBotSetting"]?.quote_currency)}`,
              })
            );
            break;

          case `watchlist`:
            message.info(
              t("notifications.copy.watchlist", {
                type:
                  json["watchlist"]?.about === "unfollow"
                    ? t("copytrading.removed_from")
                    : t("copytrading.added_to"),
              })
            );
            dispatch(appendCopyBySocket(json["watchlist"]))
            break;

          case `follower`:
            dispatch(appendCopyBySocket(json["follower"]))
            message.info(
              t("notifications.copy.follower", {
                type:
                  json["follower"]?.about === "unfollow"
                    ? t("copytrading.unfollowed")
                    : t("copytrading.followed"),
                email: json["follower"]?.metadata?.email,
              })
            );
            break;

          case `position`:
            message.info(
              t("notifications.copy.position", {
                price: json["position"]?.metadata?.price,
                action: json["position"]?.metadata?.action,
                volume: json["position"]?.metadata?.volume,
                base_unit: json["position"]?.metadata?.base_unit,
                quote_unit: json["position"]?.metadata?.quote_unit,
              })
            );

            break;

          case `profile`:
            message.info(
              json["profile"]?.about === "Pro-trader request"
                ? t("copytrading.pro_trader_request")
                : json["profile"]?.about === "pro_trader removed"
                  ? t("notifications.remove_trader", {
                    username: json["profile"]?.metadata?.username,
                  })
                  : t("notifications.update_pair", {
                    username: json["profile"]?.metadata?.username,
                  })
            );
            break;

          case `Alert`:
            message.info(
              t("notifications.price_alert", {
                current_price: json["Alert"]?.data?.metadata?.current_price
                  ? parseFloat(json["Alert"]?.data?.metadata?.current_price)
                  : "0",
                last_price: json["Alert"]?.data?.metadata?.price
                  ? parseFloat(json["Alert"]?.data?.metadata?.price)
                  : "0",
                type:
                  parseFloat(json["Alert"]?.data?.metadata?.price) >
                    parseFloat(json["Alert"]?.data?.metadata?.current_price)
                    ? t("higher")
                    : t("lower"),
              })
            );
            break;

          case `copy_position`:
            dispatch(updateCopyPosition(json[routingKey]));
            dispatch(
              getCopierDetails({
                uid: uid,
              })
            );
            break;

          case "global.currencies_prices":
            for (const key in json[routingKey]) {
              // If the value is null, replace it with an empty object
              if (json[routingKey][key] === null) {
                json[routingKey][key] = {};
              }
            }

            dispatch(setCurrenciesTickers({ currenciesTicker: json[routingKey] }));
            break;

          case "chat":
            if (
              !["buysellorderinfo",
                "paymentStep",
                "appeal",
                "appealhistory",
              ].includes(urlPart)
            ) {

              message.info(t("notifications.new_p2p_message"));
            } else {
              dispatch(appendNewMessageP2p(json["chat"]));
            }
            break;

          case `global.${cryptoCurrencyRadio}_price_${fiatCurrencyRadio}`:
            dispatch(
              updateLimitPrice(json[`global.${cryptoCurrencyRadio}_price_${fiatCurrencyRadio}`])
            );
            break;

          default:
            break;

        }
      }
    };

    ws.onclose = () => {
      reconnectWebSocket();  // Reconnect on disconnect
    };

    ws.onerror = (err) => {
      console.error("Socket error:", err);
      ws.close();  // Force close to trigger reconnect
    };
  };

  const reconnectWebSocket = () => {
    // Prevent multiple timers
    if (reconnectTimerRef.current) return;

    reconnectTimerRef.current = setTimeout(() => {
      connectWebSocket();
      reconnectTimerRef.current = null;
    }, 3000);  // Reconnect after 3 seconds
  };

  useEffect(() => {
    if (currentMarketId !== undefined) {
      connectWebSocket();
    }

    return () => {
      cleanUpSocket();  // Cleanup on dependency change or unmount
    };
  }, [currentPeriod, currentMarketId, cryptoCurrencyRadio, fiatCurrencyRadio, urlPart, singlebid, selectedPrecision]);

  return null;
}
export default withTranslation()(Ranger);
