export const p2p = {
  apiVersion: "p2p",
};

export const p2pWithHeader = {
  apiVersion: "p2p",
  withHeaders: true
};

export const peatio = {
  apiVersion: "peatio",
};

export const peatioWithHeader = {
  apiVersion: "peatio",
  withHeaders: true
};

export const barong = {
  apiVersion: "barong",
};
export const barongWithHeader = {
  apiVersion: "barong",
  withHeaders: true
};

export const grid = {
  apiVersion: "grid",
};


export const gridWithHeader = {
  apiVersion: "grid",
  withHeaders: true
};

export const copy = {
  apiVersion: "copy",
};

export const copyWithHeader = {
  apiVersion: "copy",
  withHeaders: true
};
export const getConfig = (type, withHead = false) => {
  const isFuture = type !== undefined && type === typeOfTradeObj.future;
  return isFuture
    ? withHead
      ? futureWithHeader
      : future
    : withHead
    ? peatioWithHeader
    : peatio;
};