import { Spin } from "antd";
import Lottie from "react-lottie-player";
import LoaderAnimation from "./Future_Exchange.json";

export const Loader = ({ loading }) => {
  return (
    <div className="loader-container">
      {loading && (
        <div className="loader">
          {/* <Lottie
            className="lottieAntimationdata"
            animationData={LoaderAnimation}
            loop
            play
            speed={1.5}
          /> */}
          <Spin size="large"/>
        </div>
      )}
    </div>
  );
};
