import React, { useEffect, useState } from "react";
import { Checkbox, Modal } from "antd";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useTurnstile } from "react-turnstile";
import "./ComingSoon.scss";
import comingSoonLogo from "../../Assets/Images/comingSoonLogo.png";
import InputCustom from "../../Ui/input/InputCustom";
import CommonButton from "../../Ui/button/CommonButton";
import comingSoonFooter from "../../Assets/Images/comingSoonFooter.png";
import SuccessUnion from "../../Assets/Images/SuccessUnion.png";
import CommonRenderFields from "../../Ui/commonRenderFields";
import { fieldType, loginFieldName } from "../../../staticObjects";
import { fetchPublicSubscriber } from "../../../redux/services";
import { emailRegex } from "../../../Schema/untils";

const defaultVal = {
  email: "",
  captchaResponse: "",
};
const validationSchema = yup.object().shape({
  email: yup
    .string()
    .matches(emailRegex, "Invalid email format")
    .required("Email is required"),
  captchaResponse: yup.string().required("Captcha is required"),
});

function ComingSoon() {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const errorMessage = useSelector((state) => state.alert.message);
  const successMessage = useSelector((state) => state.alert.success);
  const turnstile = useTurnstile();
  const { captchaResponse } = loginFieldName;
  const { captchaInput } = fieldType;

  const {
    control,
    formState: { errors, isValid: formIsValid },
    reset,
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultVal,
    mode: "onChange",
  });

  useEffect(() => {
    if (errorMessage?.[0]?.[0]) {
      reset((formValues) => ({
        ...formValues,
        captchaResponse: "",
      }));
      turnstile && turnstile.reset();
    }
  }, [errorMessage]);

  const onSubmit = async (data) => {

    const { email, captchaResponse } = data;
    const formFieldsData = {
      email: email,
      captcha_response: captchaResponse,
    };
    const res = await dispatch(fetchPublicSubscriber(formFieldsData));
    if (res?.payload?.status) {
      setFormData(formFieldsData);
      setIsModalOpen(true);
      reset();
      setIsChecked(false);
      turnstile.reset()
    }
  };


  const handleConfirm = () => {
    setIsModalOpen(false);
  };


  const onChange = (e) => {
    setIsChecked(e.target.checked);
  };

  return (
    <>
      <div className="comingSoonNew">
        <div className="comingSoonNew_content">
          <div className="comingSoonNew_content_data">
            <img src={comingSoonLogo} alt="Coming Soon Logo" />
            <h2>Launching Soon</h2>
            <h5>
              A faster, better and more secure crypto exchange with all the
              features you need to trade your crypto assets.
            </h5>
            <p>
              We’re currently in beta testing with access rolling out gradually.
              The full<br /> platform launches soon—be among the first to trade!
              <br />
              <br />
              Want early access? Leave your email, and we’ll send you a link to
              join the <br />beta as we expand availability.
            </p>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="comingSoonNew_content_data_btnSec">
                <InputCustom
                  basicinput={true}
                  inputPlaceHolder="Enter your email address"
                  onChange={(e) => {
                    setValue("email", e.target.value, { shouldValidate: true });
                  }}
                  value={watch("email")}
                />
                {errors.email && (
                  <span style={{ color: "red" }}>{errors.email.message}</span>
                )}

                <Checkbox onChange={onChange} checked={isChecked}>
                  I agree to receive promotional emails and updates from Gems Trade
                </Checkbox>

                <CommonRenderFields
                  errors={errors}
                  control={control}
                  name={captchaResponse}
                  type={captchaInput}
                />
                {/* {errors.captchaResponse && (
                  <p className="error-text">{errors.captchaResponse.message}</p>
                )} */}

                {/* <CommonButton
                  btn_custom
                  title="SUBMIT"
                  type="submit"
                  disabled={!formIsValid}
                /> */}
                <CommonButton
                  className="controLbtnHeight"
                  title="SUBMIT"
                  htmlType="submit"
                  disabled={!formIsValid || !isChecked}
                />
              </div>
            </form>
          </div>
        </div>
        <div className="comingSoonNew_footer">
          <img src={comingSoonFooter} alt="Footer" />
          <p>©2025 GEMS. All rights reserved</p>
        </div>
      </div>

      {/* Success Modal */}
      <Modal
        className="customModalStyle comingSoonModal"
        title={<h5>Success</h5>}
        centered
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <img src={SuccessUnion} alt="Success" />
        <h5>
          Thank you for signing up! <br /> We will keep you posted.
        </h5>
        <CommonButton btn_custom title="Confirm" onClick={handleConfirm} />
      </Modal>
    </>
  );
}

export default ComingSoon;
