export const phoneNoLastEncoded = (phone, digit, prefix) => {
  return phone ? `${prefix}${phone?.slice(-`${digit}`)}` : "";
};

// export const emailEncoded = (email, showChar, suffix) => {
//   let splited = email.split("@");
//   let firstChar = splited[0].slice(0, showChar);
//   return `${firstChar}${suffix}@${splited[1]}`;
// };
export const emailEncoded = (email, showChar, suffix) => {

  let splited = email.split("@");
  let username = splited[0];
  let domain = splited[1];

  if (username.length <= 3) {
    return `${username}@${domain}`;
  }
  let visiblePart = username.slice(0, 3);
  let maskedPart = suffix.repeat(username.length - 3);

  return `${visiblePart}${maskedPart}@${domain}`;
};

