import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import * as config from "../../utils/apiVersion";
import { startLoading, stopLoading } from "../feature";
import { errorToastSelector, successToastSelector } from "../selector";
import { buildQueryString } from "../../helpers";

export const getApiManagement = createAsyncThunk(
  "apiManagement/getApiManagement",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `resource/api_keys?${buildQueryString(payload)}`;
      let res = await API.get(config.barongWithHeader)(url);
      dispatch(stopLoading());
      return { list: res.data, total: res.headers.total, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const createNewToken = createAsyncThunk(
  "apiManagement/createNewToken",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `resource/api_keys`;
      const res = await API.post(config.barongWithHeader)(url, payload);
      dispatch(successToastSelector({ message: "Token Created Successfully." }));
      await dispatch(getApiManagement({}));
      dispatch(stopLoading());
      return { list: res?.data, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const deleteTokenApi = createAsyncThunk(
  "advertisement/deleteTokenApi",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `resource/api_keys/${payload?.kid}`;
      delete payload?.kid
      await API.delete(config.barongWithHeader)(url, payload);
      await dispatch(getApiManagement({}));
      dispatch(stopLoading());
      dispatch(successToastSelector({ message: "Token Deleted Successfully." }));
      return true;
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return false;
    }
  }
);

export const updateActiveInactive = createAsyncThunk(
  "advertisement/updateActiveInactive",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading(true));
      let url = `resource/api_keys/${payload?.kid}`;
      delete payload?.kid
      let res = await API.patch(config.barongWithHeader)(url, payload);
      await dispatch(getApiManagement({}));
      dispatch(stopLoading(false));
      return true;
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e, "error"));
      return false;
    }
  }
);