import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import "apexcharts/dist/apexcharts.css";
import moment from "moment";

const findMaxAtIndex1 = (arr) => {
  if (!Array.isArray(arr) || arr.length === 0) return null;
  return Math.max(...arr.map((subArr) => subArr[1]));
};

const TradeLineGraph = (props) => {
  const [seriesUsdt, setSeriesUsdt] = useState([]);
  const {name,graphTime:timeRange} =props
  const series = [
    {
      data: props.data.map(([timestamp, value]) => [
        timestamp - new Date().getTimezoneOffset() * 60000,
        value,
      ]),
    },
  ];
  const maxYAxis = findMaxAtIndex1(series?.[0]?.data);

  useEffect(() => {
    const generateTimestamps = () => {
      let interval = 600000; // Default 10 minutes
      let points = 7; // Default 7 points

      switch (timeRange) {
        case "day":
          interval = 3600000;
          points = 24;
          break;
        case "week":
          interval = 86400000;
          points = 7;
          break;
        case "month":
          interval = 86400000 * 3;
          points = 10;
          break;
        case "year":
          interval = 86400000 * 30;
          points = 12;
          break;
        default:
          interval = 600000;
          points = 7;
          break;
      }

      const now = Date.now();
      const generatedData = Array.from({ length: points }, (_, i) => ({
        x: now - (points - i - 1) * interval,
        y: 1,
      }));

      return [{ name: "Straight Line", data: generatedData }];
    };

    setSeriesUsdt(generateTimestamps());
  }, [timeRange]); // Recalculate when timeRange changes


  const options = {
    colors: [props?.color ?? "#f7931a"],
    chart: {
      id: "area-datetime",
      type: "area",
      zoom: {
        autoScaleYaxis: true,
      }, 
      toolbar: {
        show: false, // Corrected toolbar setting
      },
    },
    stroke: {
      curve: "smooth",
      width: 1.5,
    },
    grid: {
      xaxis: { lines: { show: false } },
      yaxis: { lines: { show: true } },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
      style: "hollow",
    },
    xaxis: {
      type: "datetime",
      tickAmount: 6,
      labels: {
        rotate: -15,
        rotateAlways: true,
        // formatter: function (val, timestamp) {
        //   return moment(new Date(timestamp)).format("DD MMM YYYY");
        // }, 
        style: {
          colors: "#ffffff", // Change x-axis label color to white
        },
      },
    },
    yaxis: {
      max: maxYAxis, // Assign computed max value
      opposite: false,
      labels: {
        show: true,
        style: {
          colors: "#ffffff", // Change y-axis label color to white
        },
      },
      axisBorder: {
        show: true,
        color: "#ffffff", // Change y-axis border color to white
      },
    },
    tooltip: {
      enabled: true,
      x: {
        format: "dd MMM yyyy",
      },
      y: {
        title: {
          formatter: () => "Price",
        },
      },
      style: {
        fontSize: "12px",
        colors: "#ffffff",
      },
      theme: "dark",
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        shadeIntensity: 0.5,
        gradientToColors: ["#329879"],
        inverseColors: false,
        opacityFrom: 0.8,
        opacityTo: 0.3,
        stops: [0, 50, 100],
      },
    },
  };

  return (
    <div id="chart">
      <div id="chart-timeline">
        <ReactApexChart
          options={options}
          series={name === "USDT" ? seriesUsdt : series}
          type="area"
          height={250}
        />
      </div>
    </div>
  );
};
export default TradeLineGraph;
