import React, { forwardRef, useState } from "react";
import { Controller } from "react-hook-form";
import { fieldType } from "../../../staticObjects";
import CheckboxCustom from "../checkbox/CheckboxCustom";
import CustomError from "../customError";
import CustomInput from "../customInput/CustomInput";
import InputCustom from "../input/InputCustom";
import DatePickerCustom from "../DatePickerCustom/DatePickerCustom";
import "./index.scss";
import { CloudflareCaptcha } from "../../CloudflareCaptcha";
import CustomTextarea from "../textArea";
import DobDatePickerCustom from "../DatePickerCustom/DobDatePickerCustom";
import moment from "moment";
import CustomSelect from "../CustomSelect/CustomSelect";
import CustomRadio from "../CustomRadio/CustomRadio";

const CommonRenderFields = forwardRef((props, refProp) => {
  const {
    name,
    placeholder,
    label,
    type,
    control,
    errors,
    checkboxTxt,
    disabledDate,
    readOnly,
    onChange,
    disabled,
    isSearch,
    dropOption,
    defaultValue,
    defaultValueDate,
    fromWhere,
    className,
    max
  } = props;
  const { passwordInput, concentInput, captchaInput, dateInput, textarea, selectInput, radioInput } =
    fieldType;

  return (
    <div className="controlHeight">
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return type === passwordInput ? (
            <InputCustom
              {...field}
              label={label}
              placeholder={placeholder}
              passwordinput={+true}
            />
          ) :
            type === "dateInputOnly" ? (
              <DobDatePickerCustom
                {...field}
                className="date_piker"
                datepickerSimple
                label={label ?? "Date of Birth * "}
                picker="date"
                disabled={disabled}
                value={moment().format("YYYY-MM-DD")}
              />
            )
              : type === captchaInput ? (
                <div className="captcha">
                  <CloudflareCaptcha {...field} />
                </div>
              ) : type === dateInput ? (
                <DatePickerCustom
                  {...field}
                  className="date_piker"
                  datepickerSimple
                  label={label ?? "Date of Birth * "}
                  picker="date"
                  disabledDate={disabledDate}
                  defaultValueDate={defaultValueDate}
                  disabled={disabled}
                  {...(fromWhere === "kyc" && {
                    value: field.value ? moment(field.value) : undefined,
                  })}
                />
              ) : type === concentInput ? (
                <CheckboxCustom {...field} checkboxTxt={checkboxTxt} />
              )
                : type === selectInput ? (
                  <CustomSelect
                    {...field}
                    label={label}
                    placeholder={placeholder}
                    isSearch={isSearch ? isSearch : false}
                    drop_data={dropOption}
                    defaultValue={defaultValue}
                    defaultValueDate={defaultValueDate}
                  />
                )
                  // : type === radioInput ? (
                  //   <CustomRadio {...field} />
                  // )
                  : type === textarea ? (
                    <CustomTextarea
                      {...field}
                      label={label}
                      placeholder={placeholder}
                      readOnly={readOnly}
                      className={className}
                    />
                  ) : (
                    <CustomInput
                      type={type}
                      onChange={onChange}
                      {...field}
                      label={label}
                      placeholder={placeholder}
                      readOnly={readOnly}
                      disable={disabled}
                      max={max}
                    />
                  );
        }}
      />
      <CustomError errors={errors} name={name} />
    </div>
  );
});

export default CommonRenderFields;
