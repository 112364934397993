import { createSlice } from "@reduxjs/toolkit"; 
import {
  getProfileData,
  getSumsubToken,
  profileUpdateKyc,
  resetProfile,
} from "../../services";

const initialState = {
  userData: {},
  kycUserData: {},
  resetData: {},
  access_token: "",
  isLoading: false,
  typeOfKyc: "", 
};

const settingSlice = createSlice({
  name: "setting",
  initialState: initialState,
  reducers: { 
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfileData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProfileData.fulfilled, (state, action) => {
        state.userData = { ...action?.payload?.data };
        state.isLoading = false;
      })
      .addCase(getProfileData.rejected, (state) => {
        state.userData = {};
        state.isLoading = false;
      })

      .addCase(profileUpdateKyc.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(profileUpdateKyc.fulfilled, (state, action) => {
        state.kycUserData = { ...action?.payload?.data };
        state.isLoading = false;
      })
      .addCase(profileUpdateKyc.rejected, (state) => {
        state.kycUserData = {};
        state.isLoading = false;
      })

      .addCase(resetProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resetProfile.fulfilled, (state, action) => {
        state.resetData = { ...action?.payload?.data };
        state.isLoading = false;
      })
      .addCase(resetProfile.rejected, (state) => {
        state.resetData = {};
        state.isLoading = false;
      })

      .addCase(getSumsubToken.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSumsubToken.fulfilled, (state, action) => {
        state.access_token = action?.payload?.data.token;
        state.isLoading = false;
      })
      .addCase(getSumsubToken.rejected, (state) => {
        state.access_token = {};
        state.isLoading = false;
      });
  },
});

export const {
  kycLocalDataAction,
  resetKycLocalDataAction,
  setLocalTypeOfKyc,
} = settingSlice.actions;

export default settingSlice.reducer;
