import { createSlice } from "@reduxjs/toolkit";
import { createNewToken, getApiManagement } from "../../services/apiManagement";

const apiManagementSlice = createSlice({
  name: "apiManagement",
  initialState: {
    apiManagementData: [],
    tokenCreatedData: {},
    error: false,
    total: 0,
    loading:false
  },
  reducers: {
    resetTokenCreatedData: (state) => {
      state.tokenCreatedData = {};
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getApiManagement.pending, (state) => {        
        state.error = false;
        state.loading = true;
        state.apiManagementData = [];
        state.total = 0
      })
      .addCase(getApiManagement.fulfilled, (state, action) => {
        state.loading = false;
        state.apiManagementData = action.payload.list;
        state.total = action.payload.total
      })
      .addCase(getApiManagement.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
      })

      .addCase(createNewToken.pending, (state) => {        
        state.error = false;
        state.loading = true;
        state.tokenCreatedData = {};
      })
      .addCase(createNewToken.fulfilled, (state, action) => {
        state.loading = false;
        state.tokenCreatedData = action.payload.list;
      })
      .addCase(createNewToken.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
      })
  },
});

export const {resetTokenCreatedData} = apiManagementSlice.actions;

export default apiManagementSlice.reducer;
