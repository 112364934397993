import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import { buildQueryString, getCsrfToken } from "../../helpers";
import * as config from "../../utils/apiVersion";
import { startLoading, stopLoading } from "../feature";
import { errorToastSelector, successToastSelector } from "../selector";

export const getUser = createAsyncThunk(
  "user/getUserData",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      // dispatch(startLoading());
      let url = "resource/users/me"; 
      
      if (getCsrfToken() === undefined) {
        dispatch(stopLoading());
        return {};
      }
      const res = await API.get(config.barong)(url);
      dispatch(stopLoading());
      return res;
    } catch (e) {
      dispatch(stopLoading());
      let errArr = [
        "authz.invalid_session",
        "authz.client_session_mismatch",
        "authz.csrf_token_mismatch",
      ];
      if (e.code === 401) {
        if (!errArr.includes(e.message[0])) {
          dispatch(errorToastSelector(e));
        }
      }
      localStorage.removeItem("csrfToken")
      return rejectWithValue(e);
    }
  }
);

export const updateUserExtraData = createAsyncThunk(
  "user/updateUserExtraData",
  async (payload, { dispatch }) => {
    try {
      // dispatch(startLoading());
      let url = `resource/users/preference`;
      const res = await API.post(config.barong)(url, payload);
      dispatch(getUser());
      // dispatch(stopLoading());
      return { status: true };
    } catch (e) {
      // dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const getMembersData = createAsyncThunk(
  "user/getMembersData",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      // dispatch(startLoading());
      let url = `/account/member/require_kyc`;
      let res = await API.get(config.peatio)(url);
      // dispatch(stopLoading());
      return res;
    } catch (e) {
      // dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);
