import { truncateData2 } from "./truncate";

export const formatNumber = (number) => {
  const formatter = new Intl.NumberFormat("en", { notation: "compact" });
  return formatter.format(number);
};

// THIS WILL ROUND OFF VALUES
export const formatNumber2 = (value,maxFractionDigits = 3) => {
  const number = Number(value);
  if (isNaN(number)) return "--"; // Handle invalid input

  const formatter = new Intl.NumberFormat("en", {
    notation: "compact",
    maximumFractionDigits: Number(maxFractionDigits), // We'll adjust it manually later
    minimumFractionDigits: 0,
  });

  let formatted = formatter.format(number);

  // Extract numeric part & unit (K, M, B)
  const match = formatted.match(/^([\d.]+)([KMB]?)$/);
  if (match) {
    let [_, num, suffix] = match;
    num = parseFloat(num).toFixed(suffix ? (Number(maxFractionDigits) ? Number(maxFractionDigits) - 1 : 0)  : Number(maxFractionDigits)); // 2 decimals for K/M/B, 3 otherwise
    return `${num}${suffix}`;
  }
  // return `${formatted}.000`; // Ensure whole numbers have .000
  return isNaN(parseFloat(formatted)) 
  ? formatted 
  : `${parseFloat(formatted).toFixed(Number(maxFractionDigits))}`;
};

// This will Truncate Values
export const formatNumber3 = (value,maxFractionDigits = 3) => {
  const number = Number(value);
  if (isNaN(number)) return "--"; // Handle invalid input

  const formatter = new Intl.NumberFormat("en", {
    notation: "compact",
    maximumFractionDigits: Number(maxFractionDigits), // We'll adjust it manually later
    minimumFractionDigits: 0,
  });

  let formatted = formatter.format(number);

  // Extract numeric part & unit (K, M, B)
  const match = formatted.match(/^([\d.]+)([KMB]?)$/);
  if (match) {
    let [_, num, suffix] = match;
    num = truncateData2(Number(num),suffix ? (Number(maxFractionDigits) ? Number(maxFractionDigits) - 1 : 0)  : Number(maxFractionDigits))
    return `${num}${suffix}`;
  }
  // return `${formatted}.000`; // Ensure whole numbers have .000
  return isNaN(parseFloat(formatted)) 
  ? formatted 
  : `${truncateData2(parseFloat(formatted),Number(maxFractionDigits))}`;
};