export const periodsMapString = { 
  1: "1m",
  5: "5m",
  15: "15m",
  30: "30m",
  60: "1h",
  120: "2h",
  240: "4h",
  360: "6h",
  720: "12h",
  1440: "1d",
  4320: "3d",
  10080: "1w",
};

export const generateSocketURI = (baseUrl, s) =>
  `${baseUrl}/?stream=${s.sort().join("&stream=")}`;

export const streamsBuilderKline = (marketId, period) => {
  return `${marketId}.kline-${period}`;
};

export const streamsBuilder = (marketId, period, uid,selectedPrecision=0) => {
  // Base streams that are always included
  const baseStreams = [
    "global.tickers",
    `${marketId}.ticker`
  ];
  
  // Streams for when marketId exists
  const marketStreams = marketId ? [
    `${marketId}.trades`,
    `${marketId}.update-${selectedPrecision}`,
    streamsBuilderKline(marketId, period)
  ] : [];
  
  // Streams for authenticated users
  const authStreams = uid ? [
    "gateway.deposit",
    "order",
    "trade",
    "message",
    "advertisement",
    "appeal",
    "bid",
    "chat",
    "admin_action",
    "watchlist",
    "follower",
    "Announcement",
    "currency",
    "profile",
    "grid_data",
    "copy_order",
    "Alert",
    "position",
    "copy_position"
  ] : [];
  
  // Add market-specific authenticated stream
  const marketAuthStream = (marketId && uid) ? [`${marketId}.d-v`] : [];
  
  return [...baseStreams, ...marketStreams, ...authStreams, ...marketAuthStream];
};

export const periodMinutesToString = (period) => periodsMapString[period];

export const klineArrayToObject = (el) => {
  const [time, open, high, low, close, volume] = el.map((e) => {
    switch (typeof e) {
      case "number":
        return e;

      case "string":
        return Number.parseFloat(e);

      default:
        throw new Error(`unexpected type ${typeof e} in kline: ${el}`);
    }
  });

  return {
    time: time * 1e3,
    open,
    high,
    isBarClosed: false,
    isLastBar: true,
    low,
    close,
    volume,
  };
};