import CryptoJS from 'crypto-js';
import { JSEncrypt } from 'jsencrypt';

export function encryptMessage(message) {
  const publicKey = `-----BEGIN PUBLIC KEY-----
    MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAyRCFENqOTrqlZYESL632
    SflATUCYKHFjdTz+/WwiHVzxOoT7BlUH0tu8t801E/5gviQbxMBRuJJFHLNsP82j
    KHij/kAqOBZpkZ+dsBErlZcx/xGc1/qZ6YHNe1TgSv0/hPDw2CtoiyLWtyeMHlCq
    nCizpt8273CgBZOE1UhHc4R6yDkbxk2jAmhvxkU1b7QTtbUSwNVHs+KjLvGWKmTp
    C2XBPoqTE3lolpOBdSoRqB9PuReStN+7dT4C5LUvqjrWEiXPrwQieQJWDlaw9WXr
    gG9yImqjHI1QDZmCfzUyBHy0Cx2Ss6aA2KU5ve6trfIGVtG/OXjk0wdAFNnzPgqn
    AwIDAQAB
    -----END PUBLIC KEY-----`;
  const aesKey = CryptoJS.lib.WordArray.random(16);
  const iv = CryptoJS.lib.WordArray.random(16);

  const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(message), aesKey, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString();

  const rsa = new JSEncrypt();
  rsa.setPublicKey(publicKey);
  const encryptedAESKey = rsa.encrypt(aesKey.toString(CryptoJS.enc.Hex));

  return { data: encryptedData, iv: iv.toString(CryptoJS.enc.Base64), key: encryptedAESKey };
}

export async function hashData(data) {
  const encoder = new TextEncoder();
  const dataBuffer = encoder.encode(data);
  const hashBuffer = await crypto.subtle.digest("SHA-256", dataBuffer);
  return Array.from(new Uint8Array(hashBuffer))
    .map(b => b.toString(16).padStart(2, "0"))
    .join("");
}
