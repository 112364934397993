import { createSlice } from "@reduxjs/toolkit";
import {
  getBonus,
  getConvertHistory,
  getDepositHistory,
  getMarketList,
  getTransactionHistoryCsv,
  getTransHisTrades,
  getUserTrades,
  getWithdrawHistory,
} from "../../services";
export const transHistoriesSlice = createSlice({
  name: "transHistories",
  initialState: {
    withdrawHistory: [],
    withdrawHistoryTotal: 0,
    depositHistory: [],
    depositHistoryTotal: 0,
    marketPairList: [],
    usertradeList: [],
    totalTrade: 0,
    tradeList: [],
    tradesCount: 0,
    convertHistory: [],
    convertTotal: 0,
    bonusDataList: [],
    userTradeLoading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWithdrawHistory.pending, (state) => { })
      .addCase(getWithdrawHistory.fulfilled, (state, action) => {
        state.withdrawHistory = action.payload.list;
        state.withdrawHistoryTotal = action.payload.total ?? 0;
      })
      .addCase(getWithdrawHistory.rejected, (state, action) => {
        state.withdrawHistory = [];
        state.withdrawHistoryTotal = 0;
      })

      .addCase(getDepositHistory.pending, (state) => { })
      .addCase(getDepositHistory.fulfilled, (state, action) => {
        state.depositHistory = action.payload.list;
        state.depositHistoryTotal = action.payload.total ?? 0;
      })
      .addCase(getDepositHistory.rejected, (state, action) => {
        state.depositHistory = [];
        state.depositHistoryTotal = 0;
      })

      .addCase(getMarketList.pending, (state) => { })
      .addCase(getMarketList.fulfilled, (state, action) => {
        state.marketPairList = action.payload.list;
      })
      .addCase(getMarketList.rejected, (state, action) => { })

      .addCase(getUserTrades.pending, (state) => { 
        state.userTradeLoading = true;
        state.usertradeList = [];
      })
      .addCase(getUserTrades.fulfilled, (state, action) => {
        state.userTradeLoading = false;
        state.usertradeList = action.payload.list;
        state.totalTrade = action.payload.total
      })
      .addCase(getUserTrades.rejected, (state, action) => {
         state.usertradeList = [];
         state.userTradeLoading = false; 
        })

      .addCase(getTransHisTrades.pending, (state) => {
        state.error = false;
        state.tradeList = [];
      })
      .addCase(getTransHisTrades.fulfilled, (state, action) => {
        state.loading = false;
        state.tradeList = action?.payload?.list;
        state.tradesCount = action?.payload?.total;
      })
      .addCase(getTransHisTrades.rejected, (state, action) => {
        state.error = true;
      })

      .addCase(getConvertHistory.pending, (state) => { })
      .addCase(getConvertHistory.fulfilled, (state, action) => {
        state.convertHistory = action.payload.list;
        state.convertTotal = action.payload.total ?? 0;
      })
      .addCase(getConvertHistory.rejected, (state, action) => { })

      .addCase(getBonus.pending, (state) => { })
      .addCase(getBonus.fulfilled, (state, action) => {
        state.bonusDataList = action.payload.data;
      })
      .addCase(getBonus.rejected, (state, action) => { })

      .addCase(getTransactionHistoryCsv.pending, (state) => { })
      .addCase(getTransactionHistoryCsv.fulfilled, (state, action) => {

      })
      .addCase(getTransactionHistoryCsv.rejected, (state, action) => {
  
      })

  },
});

export const { } = transHistoriesSlice.actions;
export default transHistoriesSlice.reducer;
