import React, { lazy } from "react";

export const Mainlayout = lazy(() =>
  import("../Components/Layout/Mainlayout/Mainlayout")
);
export const Dashboard = lazy(() => import("../Components/Pages/Dashboard"));
export const PageNotFound = lazy(() =>
  import("../Components/Pages/PageNotFound/PageNotFound")
);
export const Login = lazy(() => import("../Components/Pages/Login"));
export const ChangePassword = lazy(() => import("../Components/Pages/Login/PasswordSetting/ChangePassword"));
export const ResetPassword = lazy(() => import("../Components/Pages/Login/PasswordSetting/ResetPassword"));
export const CreateAcount = lazy(() =>
  import("../Components/Pages/SignUp/CreateAcount")
);
export const VerifyEmail = lazy(() =>
  import("../Components/Pages/SignUp/VerifyEmail")
);
export const Verifiaction = lazy(() =>
  import("../Components/Pages/SignUp/Verifiaction")
);
export const VerificationCode = lazy(() =>
  import("../Components/Pages/SignUp/VerificationCode")
);
export const Identity = lazy(() =>
  import("../Components/Pages/SignUp/Identity")
);
export const VerifyIdentity = lazy(() =>
  import("../Components/Pages/SignUp/VerifyIdentity")
);
export const Portfolio = lazy(() =>
  import("../Components/Pages/Portfolio/Portfolio")
);
export const GridTrading = lazy(() =>
  import("../Components/Pages/GridTrading/Grid/GridTradingComp.jsx")
);
export const Setting = lazy(() =>
  import("../Components/Pages/Setting/Setting")
);
export const Order = lazy(() =>
  import("../Components/Pages/ExchangePages/Order/Order")
);

export const Exchange = lazy(() =>
  import("../Components/Pages/ExchangePages/Exchange.jsx")
);

export const EmailConfirmation = lazy(() =>
  import("../Components/Pages/EmailConfirmation/index.jsx")
);

export const Notifications = lazy(() =>
  import("../Components/Pages/Notification/NotificationLayout.jsx")
);
export const CopyTrading = lazy(() =>
  import("../Components/Pages/CopyTrading/CopyTrading.jsx")
);

export const MyCopyTrading = lazy(() =>
  import("../Components/Pages/MyCopyTrades/MyCopyTrades.jsx")
);

export const Landing = lazy(() =>
  import("../Components/Pages/Landing/index.jsx")
);
export const Landing2 = lazy(() =>
  import("../Components/Pages/Landing/index2.jsx")
);
export const AdressManagement = lazy(() =>
  import("../Components/Pages/DepositWithdraw/AddressMangement.jsx")
);

export const Kyc = lazy(() =>
  import("../Components/Pages/Setting/Kyc.jsx")
)
export const Referral = lazy(() => import("../Components/Pages/Referral/Referral"));
export const ReferralViewAll = lazy(() =>
  import("../Components/Pages/Referral/ReferalTabs.jsx")
);
export const RewardViewAll = lazy(() =>
  import("../Components/Pages/Referral/RewardViewAll")
);
export const history = lazy(() =>
  import("../Components/Pages/P2P/Pages/History/Historydeposite.jsx")
);
export const SumSub = lazy(() =>
  import("../Components/Sumsub/Sumsub.jsx")
);
export const Swap = lazy(() => import("../Components/Pages/Swap/Swap.jsx"));
export const Historydeposite = lazy(() => import("../Components/Pages/P2P/Pages/History/Historydeposite.jsx"));
export const Wallet = lazy(() => import("../Components/Pages/wallet/Wallet.jsx"));

export const TradingParameter = lazy(() =>
  import("../Components/Pages/CopyTrading/CopyTradingparameter.jsx")
);

export const CopyBotSetting = lazy(() =>
  import("../Components/Pages/MyCopyTrades/CopyBotDetail.jsx")
);
export const TraderDetail = lazy(() =>
  import("../Components/Pages/TraderDetails/TraderDetail.jsx")
);
export const Announcement = lazy(() =>
  import("../Components/Pages/Announcement/Announcement.jsx")
);

export const Contact = lazy(() =>
  import("../Components/Pages/Contact/Contact.jsx")
);

export const PriceAlert = lazy(() =>
  import("../Components/Pages/PriceAlert/PriceAlert.jsx")
);

export const Privacy = lazy(() =>
  import("../Components/Pages/Privacy/Privacy.jsx")
);
export const TermCondition = lazy(() =>
  import("../Components/Pages/TermCondition/TermCondition.jsx")
);
export const RiskWarning = lazy(() =>
  import("../Components/Pages/RiskWarning/RiskWarning.jsx")
);
export const ListingForm = lazy(() =>
  import("../Components/Pages/Listingform/ListingForm.jsx")
);
export const Aboutus = lazy(() =>
  import("../Components/Pages/Aboutus/Aboutus.jsx")
);
export const Listing = lazy(() =>
  import("../Components/Pages/Listing/Listing.jsx")
);

export const LaunchpadViewAll = lazy(() =>
  import("../Components/Pages/LaunchpadViewAll/LaunchpadViewAll")
);

export const ListingApplication = lazy(() =>
  import("../Components/Pages/Listing/ListingApplicationForm/ListingApplication.jsx")
);

export const EnableUser = lazy(() =>
  import("../Components/EnableUserVerification/index.jsx")
);
export const TradingView = lazy(() =>
  import("../Components/Pages/TradingView/TradingView.jsx")
);

//start p2p

export const P2P = lazy(() =>
  import("../Components/Pages/P2P/Pages/LandingPage/Landing.jsx")
);

export const Orders = lazy(() =>
  import("../Components/Pages/P2P/Pages/Components/Orders")
);

export const Buysellorderinfo = lazy(() =>
  import("../Components/Pages/P2P/Pages/Buysellorderinfo/Buysellorderinfo.jsx")
);

export const Progressstep = lazy(() =>
  import("../Components/Pages/P2P/Pages/Components/ProgressSteps/Progressstep.jsx")
);

export const EditAdv = lazy(() =>
  import("../Components/Pages/P2P/Pages/EditAdv/EditAdv.jsx")
);

export const Paymentmethod = lazy(() =>
  import("../Components/Pages/P2P/Pages/Paymentmethod/Paymentmethod.jsx")
);

export const ProfileCompletion = lazy(() =>
  import("../Components/Pages/P2P/Pages/MarketUpdate/ProfileCompletion.jsx")
);

export const Progresslast = lazy(() =>
  import("../Components/Pages/P2P/Pages/Components/Progresslast/Progresslast.jsx")
);

export const PaymentSteps = lazy(() =>
  import("../Components/Pages/P2P/Pages/Components/paymentStatus/index.jsx")
);

export const Appeal = lazy(() =>
  import("../Components/Pages/P2P/Pages/Appeal")
);

export const AppealHistory = lazy(() =>
  import("../Components/Pages/P2P/Pages/Appeal/appealHistory")
);
export const PrivacyPolicy = lazy(() =>
  import("../Components/Pages/Privacy/Privacy.jsx")
);
export const Transactions = lazy(() =>
  import("../Components/Pages/Transactions/index.jsx")
);
export const AccountActivity = lazy(() =>
  import("../Components/Pages/Setting/AccountActivity.jsx")
);
export const DeviceManagement = lazy(() =>
  import("../Components/Pages/Setting/DeviceManagement.jsx")
);
export const GridDetail = lazy(() =>
  import("../Components/Pages/GridTrading/Details/GridDetails.jsx")
);
export const Sumsub = lazy(() =>
  import("../Components/Sumsub/Sumsub.jsx")
);
export const Market = lazy(() =>
  import("../Components/Pages/Market/Market.jsx")
);
export const ApiManagement = lazy(() =>
  import("../Components/Pages/ApiManagement/ApiManagement.jsx")
);  


//end p2p