import { createAsyncThunk } from "@reduxjs/toolkit";
import * as config from "../../utils/apiVersion";
import { API } from "../../api";
import { buildQueryString } from "../../helpers";
import { startLoading, stopLoading } from "../feature";
import { errorToastSelector } from "../selector";

export const fetchMarketGainerLooser = createAsyncThunk(
    "market/fetchMarketGainerLooser",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `/home/market/gainer_loser`;
            const { data, headers } = await API.get(config.peatioWithHeader)(url);
            dispatch(stopLoading());
            return { list: data, total: headers.total };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e, "error"));
            return rejectWithValue(e);
        }
    }
);

export const fetchMarketListData = createAsyncThunk(
    "market/fetchMarketListData",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `/home/market/list?${buildQueryString(payload)}`;
            const { data, headers } = await API.get(config.peatioWithHeader)(url);
            dispatch(stopLoading());
            return { list: data, total: headers.total };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e, "error"));
            return rejectWithValue(e);
        }
    }
);
export const fetchNewlyListedData = createAsyncThunk(
    "market/fetchNewlyListedData",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `/home/market/list?${buildQueryString(payload)}`;
            const { data, headers } = await API.get(config.peatioWithHeader)(url);
            dispatch(stopLoading());
            return { list: data, total: headers.total };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e, "error"));
            return rejectWithValue(e);
        }
    }
);

export const fetchMarketGreedData = createAsyncThunk(
    "market/fetchMarketGreedData",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `/home/market/greed`;
            const { data, headers } = await API.get(config.peatioWithHeader)(url);
            dispatch(stopLoading());
            return { list: data, total: headers.total };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e, "error"));
            return rejectWithValue(e);
        }
    }
);

export const fetchMarketGasPrice = createAsyncThunk(
    "market/fetchMarketGasPrice",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `/home/market/gas_price`;
            const { data, headers } = await API.get(config.peatioWithHeader)(url);
            dispatch(stopLoading());
            return { list: data, total: headers.total };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e, "error"));
            return rejectWithValue(e);
        }
    }
);