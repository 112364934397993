import React from "react";
import Turnstile from "react-turnstile";
import { cloudCaptchaSiteKey } from "../../api";
import { useSelector } from "react-redux";
export const CloudflareCaptcha = (props) => {
  const { themeMode } = useSelector((state) => state.theme);
  // const { REACT_APP_CAPTACH_KEY } = process.env
  return (
    <Turnstile
      theme={themeMode === "theme-light" ? "light" : "dark"}
      className={`centerCaptcha ${props.className}`}
      sitekey={cloudCaptchaSiteKey()}
      onVerify={(token) => {
        props.onChange(token);
      }}
      onLoad={(widgetId) => {
      }}
      onExpire={(expire) => {
      }}
      onError={(err) => {
      }}
    />
  );
};
