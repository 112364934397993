import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { rootName } from "../utils/constant";
import OnboardBanner from "../Components/Assets/Images/OnboardBanner.png";
import BannerThemeLight from "../Components/Assets/Images/BannerThemeLight.png";
import useCurrentWidth from "../customHooks/useCurrentWidth";
import { useSelector } from "react-redux";
import RingDarkVideo from "../Components/Assets/RingDarkVideo.mp4";
import RingLightVideo from "../Components/Assets/RingLightVideo.mp4";
import CommonBannerLayout from "../Components/CommonBannerLayout/CommonBannerLayout";

const PublicRoute = ({ isAuthenticated }) => {
  const width = useCurrentWidth();
  const { themeMode } = useSelector((state) => state.theme);
  const location = useLocation().pathname;
  return isAuthenticated ? (
    <Navigate to={`${rootName}`} />
  ) : (
    // <div
    //   className={`${
    //     location !== "/" &&
    //     location !== "/privacy" &&
    //     location !== "/contact-us" &&
    //     width > 991
    //       ? "bannerStyle"
    //       : ""
    //   }`}
    // >
    //   {location !== "/" &&
    //     location !== "/privacy" &&
    //     location !== "/contact-us" &&
    //     width > 991 && (
    //       <div className="bannerStyle_banner">
    //         <div>
    //           <video width="640" height="360" controls={false} autoPlay loop>
    //             <source src={RingDarkVideo} type="video/mp4" />
    //           </video>
    //         </div>
    //         {/* {themeMode === "theme-light" ? (
    //           <img src={BannerThemeLight} alt="Banner" />
    //         ) : (
    //           <img src={OnboardBanner} alt="Banner" />
    //         )} */}
    //       </div>
    //     )}
    //   <Outlet />
    // </div>
    <CommonBannerLayout>
       <Outlet />
    </CommonBannerLayout>
  );
};

export default PublicRoute;
